import React from 'react';
import HeaderContainer from "../../container/header/HeaderContainerOne";
import Logo from "./elements/logo";
import Navbar from "./elements/navbar/Navbar";
import HeaderConfig from "./elements/config/HeaderConfigOne";

import paragonLogo from '../../assets/img/logo/paragon-corp-white.png'

//Used

const HeaderParagon = ({isBlock}) => {
    return (
        <HeaderContainer
            logoVersion={'light'}
            transparent={isBlock ? false : true}
            sticky={true}
        >
            <div className="header-left flex-80">
                <Logo logo={paragonLogo}/>
                <Navbar className="flex-grow-0 pl--40"/>
            </div>

            <div className="header-right pr_md--30 pr_sm--30 flex-20">
                <HeaderConfig/>
            </div>
        </HeaderContainer>
    );
};

export default HeaderParagon;