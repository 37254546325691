import React from 'react';

const InnovationRedirectItem = ({className, thumb, title, float, link}) => {
    return (
        <>
            {title === "Open Research & Innovation" ? 
                <a target="_blank" rel="noopener noreferrer" href={link}>
                    <div className={`mb--50 portfolio portfolio_style--innovation ${className}`} style={float === 'right' ? {float:"right"} : {float:"left"}}>
                        <div className="thumb">
                            <img src={thumb} alt={title}/>
                        </div>
                        <div className="port-overlay-info">
                            <div className="hover-action">
                                <h3 className="post-overlay-title" style={{fontSize:"24px"}}>
                                    <a target="_blank" rel="noopener noreferrer" href={`${process.env.PUBLIC_URL + '/'}`}><strong>{title}</strong></a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </a> : 
                <a href={link}>
                    <div className={`mb--50 portfolio portfolio_style--innovation ${className}`} style={float === 'right' ? {float:"right"} : {float:"left"}}>
                        <div className="thumb">
                            <img src={thumb} alt={title}/>
                        </div>
                        <div className="port-overlay-info">
                            <div className="hover-action">
                                <h3 className="post-overlay-title" style={{fontSize:"24px"}}>
                                    <a href={`${process.env.PUBLIC_URL + '/'}`}><strong>{title}</strong></a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </a>
            }
        </>
    );
};

export default InnovationRedirectItem;
