import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import socials from "../../data/socials";

const ContactInfoOne = () => {
    return (
        <div className="bk_contact_classic bg_color--1 ptb--160 ptb-md--80 ptb-sm--80">
            <Container>
                <Row>
                    {data.map(item=>(
                        <Col key={item.id} md={6} lg={4} className="wow move-up">
                            <div className="classic-address text-center">
                                <h4 className="heading heading-h4" style={{fontFamily: "Montserrat"}}>{item.title}</h4>
                                <div className="desc mt--15">
                                    <p className="bk_pra line-height-2-22" dangerouslySetInnerHTML={{__html: item.content}}/>
                                </div>
                            </div>
                        </Col>
                    ))}
                    <Col md={6} lg={4} className="wow move-up">
                        <div className="classic-address text-center">
                            <h4 className="heading heading-h4" style={{fontFamily: "Montserrat"}}>Follow Us</h4>
                            <div className="desc mt--15">
                                <ul className="social-icon icon-solid-rounded icon-size-medium text-center move-up wow">
                                    {socials.map(social => (
                                        <li className={social.media}>
                                            <a target="_blank" rel="noopener noreferrer" key={social.id} href={social.link}>
                                                <i className={`fa fa-${social.media}`}/>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const data = [
    {
        id: 1,
        title: "Visit our office at",
        content: "Jalan Swadarma Raya Kampung Baru IV No. 1 <br/> Jakarta - 12250 Indonesia"
    },
    {
        id:2,
        title:"Message us",
        content: "customercare@paracorpgroup.com <br><strong>Phone</strong><br>08041401123<strong><br>Fax</strong><br>+62 21 585 2439"
    }
]

export default ContactInfoOne;
