import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import ParagonBanner from '../../container/contact/ParagonBanner';
import CSRDetailItem from './CSRDetailItem';
import CSRPilarList from './CSRPilarList';
import CSRGallery from './CSRGallery';

const CSRPage = () => {

    return (
        <Fragment>
            <Helmet>
                <title>CSR Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"CSR"}
                    bgImage={"bg-image-csr"}
                />
                <CSRPilarList/>
            </LayoutDefault>

            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                
                <ParagonBanner
                    sectionName="education"
                    sectionTitle="EDUCATION"/>
            </LayoutDefault>
            <CSRDetailItem
                    text="Education is the foundation for building a nation. Paragon is committed to making a better education in Indonesia by providing teacher training, scholarships, parenting, infrastructure improvements, and leadership development."
                    link="https://inspiringmovement.wardahbeauty.com/pilarpage/index/pilar-pendidikan"/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="health"
                    sectionTitle="HEALTH"/>
            </LayoutDefault>
            <CSRDetailItem
                    text="Health is the basic to build the welfare of society. Improving the health quality is one of the commitments Paragon continues to pursue with health organizations, especially those related to women and children."
                    link="https://inspiringmovement.wardahbeauty.com/pilarpage/index/pilar-kesehatan"/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="women-empowerment"
                    sectionTitle="WOMEN EMPOWERMENT"/>
            </LayoutDefault>
            <CSRDetailItem
                    text="Women are basically powerful, Paragon is committed to developing the capacity and skills of women in Indonesia to be more empowered together. One of them is by growing the spirit of womenpreneur who can support the improvement of the quality of life."
                    link="https://inspiringmovement.wardahbeauty.com/pilarpage/index/pilar-pemberdayaan-perempuan"/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="environment"
                    sectionTitle="ENVIRONMENT"/>
            </LayoutDefault>
            <CSRDetailItem
                    text="In carrying out its commitment to preserving the earth, Paragon collaborates with environmental organizations and engages the general public in the provision of clean water, waste treatment and environmental preservation."
                    link="https://inspiringmovement.wardahbeauty.com/pilarpage/index/pilar-lingkungan"/>
            <LayoutDefault className="template-color-1 template-font-2" revealFooter={false}>
                <CSRGallery/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default CSRPage;