import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Parallax, {Layer} from "react-parallax-scroll";
import { useMediaQuery } from 'react-responsive'

const ParagonBanner = ({sectionName, sectionTitle}) => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return (
        <Parallax>
            <Layer className={"checkpoint bg-banner-"+sectionName} style={{height:"45vh"}} settings={{speed: -0.2, type: 'backgroundY'}}>
                <div className="paragon-overlay"/>  
                <div id={sectionName}  className="brook-contact-form-area ptb--150 ptb-md--80 ptb-sm--60" style={{height:"45vh", display:"flex", alignItems:"center"}}>
                    <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                        <Row>
                            <Col lg={12} className="wow move-up align-items-center text-left text-white">
                                <h1 style={{color:"white", fontFamily:"Montserrat", margin:"0px"}}>{sectionTitle}</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layer>
        </Parallax>
    );
};

export default ParagonBanner;
