import React, { useRef } from 'react';

const PrivacyPolicyContentEnglish = () => {
    const mystyle = {
        color: 'blue'
    };

    const Ref1 = useRef()
    const Ref2 = useRef()
    const Ref3 = useRef()
    const Ref4 = useRef()
    const Ref5 = useRef()
    const Ref6 = useRef()
    const Ref7 = useRef()
    const Ref8 = useRef()
    const Ref9 = useRef()
    const Ref10 = useRef()
    const Ref11 = useRef()
    const Ref12 = useRef()
    const Ref13 = useRef()
    const Ref14 = useRef()
    const Ref15 = useRef()
    const Ref16 = useRef()
    const Ref17 = useRef()
    const Ref18 = useRef()
    const Ref19 = useRef()

    function handleClick1() {
        Ref1.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick2() {
        Ref2.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick3() {
        Ref3.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick4() {
        Ref4.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick5() {
        Ref5.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick6() {
        Ref6.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick7() {
        Ref7.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick8() {
        Ref8.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick9() {
        Ref9.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick10() {
        Ref10.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick11() {
        Ref11.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick12() {
        Ref12.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick13() {
        Ref13.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick14() {
        Ref14.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick15() {
        Ref15.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick16() {
        Ref16.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick17() {
        Ref17.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick18() {
        Ref18.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick19() {
        Ref19.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{ fontFamily: "Montserrat" }}>PRIVACY POLICY</h3>
            <p style={{ textAlign: "justify" }}>
                Welcome to the official Website of PT Paragon
                Technology And Innovation (hereinafter referred to
                as “We”).
                We are committed to protecting your personal
                information and your right to privacy. If you have
                any questions or concerns about this privacy notice,
                or our practices with regards to your personal
                information, please contact us at
                customercare@paracorpgroup.com
                <br />
                <br />
                When you visit our website <a href='https://paragon-innovation.com/' target="_blank" rel="noopener noreferrer" style={mystyle}>https://www.paragoninnovation.com/</a> ("(the “Website“"), and more
                generally, use any of our services (the “Services“,
                which include the Website), we appreciate that you
                are trusting us with your personal information. We
                take your privacy very seriously. In this privacy
                notice, we seek to explain to you in the clearest way
                possible what information we collect, how we use it
                and what rights you have in relation to it. We hope
                you take the time to read it carefully, because it is
                important. If any of the terms in this privacy notice
                you do not agree to, you can immediately stop using
                our Services.
                <br />
                <br />
                This privacy notice applies to all information
                collected through our Services (which, as described
                above, includes our Website), as well as, any related
                services, sales, marketing or events.
                <br />
                <br />
            </p>
            <h4 style={{ fontFamily: "Montserrat" }}>
                SCOPE
            </h4>
            <p>Last Updated: 2023, January 17th</p>
            <ul>
                <div>

                </div>
                <li onClick={handleClick1}>Information we collect directly from you</li>
                <li onClick={handleClick2}>Information about your use of our services</li>
                <li onClick={handleClick3}>Information We collect from other sources</li>
                <li onClick={handleClick4}>Information We collect automatically</li>
                <li onClick={handleClick5}>Types of Cookies we use</li>
                <li onClick={handleClick6}>Information and payment procedures</li>
                <li onClick={handleClick7}>Third Party Services</li>
                <li onClick={handleClick8}>Advertising and Analytics Services Provided by Third Parties</li>
                <li onClick={handleClick9}>How do we use your information?</li>
                <li onClick={handleClick10}>How can we share your information?</li>
                <li onClick={handleClick11}>How We Process Your Personal Data</li>
                <li onClick={handleClick12}>Your Rights</li>
                <li onClick={handleClick13}>Limit Cookies</li>
                <li onClick={handleClick14}>Children</li>
                <li onClick={handleClick15}>Links to Other Websites and Third Party Content</li>
                <li onClick={handleClick16}>How do we store your data?</li>
                <li onClick={handleClick17}>Data Retention</li>
                <li onClick={handleClick18}>Transnational Data Transfer</li>
                <li onClick={handleClick19}>Changes to Our Privacy Policy</li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                Keep in mind that you should frequently check for
                updates to the Privacy Policy. We may update this
                Privacy Policy within a certain period of time to
                implement changes related to the service or changes
                to the applicable laws and regulations.
                <br />
                <br />
            </p>
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref1}>
                Information we collect directly from you
            </h4>
            <p style={{ textAlign: "justify" }}>
                We collect information that you provide directly to
                us, such as when you register an account on our
                website, trade on our brand website, register for our
                newsletter, express interest in our vouchers, or
                interact with us on social media. The types of
                personal data we may collect directly from you
                include:
            </p>
            <br />
            <p>General Personal Information is such as:</p>
            <ul>
                <li>
                    Contact information, such as your name,
                    gender, date of birth, email address, mobile
                    number, Instagram account, and profession;
                </li>
                <li>
                    Addresses, such as street names, areas,
                    cities, provinces, and countries.
                </li>
            </ul>
            <br />
            <p>Specific Personal Information such as:</p>
            <ul>
                <li>
                    skin information, such as your skin type,
                    skin problem, and skin tone;
                </li>
                <li>
                    Product information, such as the make-up
                    products you have and how you can find out
                    about our products. In addition, we also
                    collect data about your purchases along with
                    the vouchers that you often use to buy
                    products at our official Brand Commerce;
                    and
                </li>
                <li>
                    Any other information you choose to
                    provide.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref2}>
                Information about your use of our Services
            </h4>
            <p style={{ textAlign: "justify" }}>
                We collect information about your use of the
                Service, such as: buying our products on our official
                brand trading website, or products that interest you.
                We also collect other information about your use of
                the service, such as the applications you use, the
                websites you visit, and how you interact with the
                content we offer through the service.
            </p>
            <p style={{ textAlign: "justify" }}>
                If you buy one of our products from our official
                Brand Commerce (
                <a href='http://wardahbeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}>Wardah</a>,
                <a href='https://www.makeoverforall.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Make Over</a>,
                <a href='https://www.eminacosmetics.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Emina</a>,
                <a href='https://ww.kahfeveryday.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Kahf</a>,
                <a href='https://crystallurebeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Crystallure</a>,
                <a href='https://shop.wardahbeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Instaperfect</a>,
                <a href='https://www.tavi-world.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Tavi</a>,
                <a href='https://www.laboreskinexpert.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Labore</a>,
                <a href='https://biodef.co.id/' target="_blank" rel="noopener noreferrer" style={mystyle}> Biodef</a>,
                <a href='https://www.wonderlyskin.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Wonderly</a>
                ), we collect your purchase order data,
                such as: what products were purchased, the number
                of products, the total price of the order, and the
                vouchers you used in the transaction; we may also
                collect your personal data such as your name, email
                and telephone number; and your address as the
                information required to deliver your order.
            </p>
            <p style={{ textAlign: "justify" }}>
                If you use our sharing feature to inform your friends
                about the product, we will be able to collect that
                information. In addition, we may also collect other
                information about you, such as your device or your
                use of the Service with your consent to send it to us.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref3}>
                Information We collect from other sources
            </h4>
            <p style={{ textAlign: "justify" }}>
                When you access the service through a social
                network, We collect information from the social
                network in accordance with your settings on the
                social network. If you wish us to be on social media,
                We will collect information about these
                interactions. The information We collect includes
                your name, image, social media username, and
                email address that corresponds to the social media.
                We may also collect information about you from
                third parties, including publicly available sources or
                friends who share our products on their sharing
                channels.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref4}>
                Information we collect in an automated way
            </h4>
            <p style={{ textAlign: "justify" }}>
                When you visit our site, we collect certain
                information automatically. To collect this
                information, we may use cookies and similar
                technologies. "Cookies" are text files that a website
                sends to a visitor's computer or other device
                connected to the internet to identify the visitor or to
                store information or settings in the browser.
            </p>
            <p style={{ textAlign: "justify" }}>
                Cookies are small strings of characters that are used
                by many websites to send data to your device and
                can, over time, return information to those websites.
                We may also collect information about your online
                activities from time to time
            </p>
            <ul>
                <li>Keeping you signed in in brandcommerce</li>
                <li>URL that describes a visitor to our website;</li>
                <li>The search terms used to reach our website;</li>
                <li>Details about the devices used to access
                    our website (such as: IP address, browser
                    information, device information and
                    operating system information);</li>
                <li>Details about your interactions with our
                    website (such as: date, time, length of time
                    you accessed our site, specific pages
                    accessed during your visit to our website,
                    and email marketing that you opened); and</li>
                <li>Usage information (such as: the number
                    and frequency of visitors to our website).</li>
            </ul>
            <p ref={Ref5}>JWhat types of cookies do we use?</p>
            <p>There are a number of different types of
                cookies, however, our website uses:</p>
            <p style={{ textAlign: "justify" }}><b style={{ fontSize: "15px" }}>Functionality</b> – Our Company uses these
                cookies so that we recognize you on our
                website and remember your previously
                selected preferences. These could include
                what language you prefer and location you
                are in. A mix of first-party and third-party
                cookies are used.
            </p>
            <p style={{ textAlign: "justify" }}><b style={{ fontSize: "15px" }}>Advertising</b> – Our Company uses these
                cookies to collect information about your
                visit to our website, the content you
                viewed, the links you followed and
                information about your browser, device,
                and your IP address. Our Company
                sometimes shares some limited aspects of
                this data with third parties for advertising
                purposes. We may also share online data
                collected through cookies with our
                advertising partners. This means that when
                you visit another website, you may be
                shown advertising based on your browsing
                patterns on our website
            </p>
            <p style={{ textAlign: "justify" }}>
                We may associate this information with your
                newsletter if you subscribe, the device you use to
                connect to our services, and the email or social
                media accounts you use to connect with us.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref6}>
                Payment Information
            </h4>
            <p style={{ textAlign: "justify" }}>
                If you buy our products from our official brand
                commerce site, to complete an order, you must
                make a payment through our integrated payment
                method. We work with Xendit, a payment gateway
                provider, which will collect your payment
                information according to the method you choose.
                All your data will be encrypted via the Payment
                Card Industry Data Security Standard (PCI-DSS).
            </p>
            <p style={{ textAlign: "justify" }}>
                All direct payment gateways comply with the
                standards set by the PCI-DSS administered by the
                PCI Security Standards Council, which is a joint
                effort of brands such as Visa, MasterCard,
                American Express and Discover. And Xendit has
                met this standard. The PCI-DSS requirements help
                ensure the safe handling of credit card information
                by our authorized Brand Commerce stores and their
                service providers. For more information, you may
                also want to read the Xendit Terms of Service here
                or the Privacy Statement here.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref7}>
                Third Party Services
            </h4>
            <p style={{ textAlign: "justify" }}>
                In general, the third party providers We use will
                only collect, use and disclose your information to
                the extent necessary to enable them to perform the
                services they provide to us.
            </p>
            <p style={{ textAlign: "justify" }}>
                However, certain third party service providers have
                their own privacy policies with respect to the
                information We must provide to them for
                transactions related to your purchases. For these
                providers, We recommend that you read their
                privacy policy so that you can understand the way
                in which your personal information will be handled
                by this provider.
            </p>
            <p style={{ textAlign: "justify" }}>
                We also work with other Shipping providers and
                Logistics Partners, who have their own privacy
                policies with respect to the information We need to
                make available for your shipping related
                transactions. After you leave our store Website or
                are directed to a third party Website or application,
                you are no longer governed by this Privacy Policy
                or our Website Terms of Service.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref8}>
                Advertising and Analytics Services Provided by
                Third Parties
            </h4>
            <p style={{ textAlign: "justify" }}>
                We may allow others to provide analytics services
                and serve ads on our behalf on the internet and in
                mobile applications. These entities may use cookies
                and other technologies to collect information about
                your use of the Service and other Websites and
                applications, for example: IP address, Web browser,
                mobile network information, pages vieWed, pages,
                time spent on the page or in applications, and the
                links accessed. This information can be used by us;
                and their use but not limited to analyzing and
                tracking data, but also to determine the popularity
                of certain products or content, deliver
                advertisements and content targeted to your
                interests on our Services and other Websites, and
                have a better understanding of your online activities.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref9}>
                How We Use Your Information
            </h4>
            <p style={{ textAlign: "justify" }}>
                We may use the information We collect to deliver
                the products you buy on our official brand trading
                site, improve your experience while browsing our
                Website, customize your subscription newsletters
                and also our interactions with you, and to provide,
                maintain and improve our services. We may also
                use the information We collect to:
            </p>
            <ul>
                <li>
                    Communicate with you about our Services,
                    including to notify you of products and
                    services that may be of interest to you;
                </li>
                <li>
                    Facilitating your engagement with the
                    Services, including enabling you to post
                    product comments and reviews;
                </li>
                <li>
                    Notify you of our new product launch dates,
                    new product information and brand related
                    information;
                </li>
                <li>
                    Provide promotional offers and giveaway;
                    and
                </li>
                <li>
                    Conduct or administer surveys and other
                    market research.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref10}>
                How We Can Share Your Information
            </h4>
            <p>
                We may share the information We collect with :
            </p>
            <ul>
                <li>
                    With selected partners as part of a cosponsored promotion;
                </li>
                <li>
                    To comply with a legal or regulatory
                    obligation, protect and defend our rights or
                    property, protect the safety of our customers
                    and users of our Website or the public, and
                    protect against legal liability;
                </li>
                <li>
                    In connection with, or during negotiation, a
                    merger, sale of company assets, financing,
                    or acquisition of all or part of our business
                    by another company;
                </li>
                <li>
                    betWeen and betWeen our current and
                    future affiliates, subsidiaries and other
                    companies under the same control and
                    ownership; and
                </li>
                <li>
                    When you provide product reviews or other
                    content, that content will be sorted by our
                    team and the selected product reviews will
                    be posted publicly. Other Website visitors
                    may be able to see your name or other
                    information about you that you post, such as
                    your social media accounts or username.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref11}>
                How We Process Your Personal Data
            </h4>
            <p>
                To protect your rights in the series of personal data
                processing, we pay attention to the following
                provisions for your convenience:
            </p>
            <ul>
                <li>
                    We will ask you for valid consent
                    explicitly for 1 (one) or certain purposes
                    that have been submitted by us to you;
                </li>
                <li>
                    Once you give us your consent, we will
                    inform you of the legality of processing
                    Personal Data; the purpose of processing
                    Personal Data; the type and relevance of
                    the Personal Data to be processed; the
                    retention period of documents containing
                    Personal Data; details regarding the
                    information collected; period of processing
                    of Personal Data; and your rights as the
                    subject of Personal Data;
                </li>
                <li>
                    We will process your Personal Data in a
                    limited and specific manner, legally and
                    transparently;
                </li>
                <li>
                    We will ensure the accuracy,
                    completeness, and consistency of your
                    Personal Data following applicable laws
                    and regulations;
                </li>
                <li>
                    We will provide you with access to
                    Personal Data that is processed and track
                    record of processing Personal Data
                    following the period of storage of Personal
                    Data;
                </li>
                <li>
                    In processing your Personal Data, we
                    guarantee the confidentiality of the
                    Personal Data that you submit;
                </li>
                <li>
                    We will supervise each party involved in
                    the processing of your Personal Data under
                    our control;
                </li>
                <li>
                    We will do our best and to the maximum
                    extent possible to ensure that your Personal
                    Data is protected from unauthorized
                    processing and access;
                </li>
                <li>
                    We are responsible for the processing of
                    Personal Data and demonstrate
                    accountability in fulfilling obligations to
                    implement the principles of Personal Data
                    protection;
                </li>
                <li>
                    In processing Personal Data, We will
                    develop and implement operational
                    technical steps to protect Personal Data
                    from interference with Personal Data
                    processing that is contrary to the applicable
                    laws and regulations; and determine the
                    level of security of your Personal Data by
                    taking into account the nature and risks of
                    the Personal Data that must be protected in
                    the processing of Personal Data, and
                </li>
                <li>
                    If something happens that we do not want,
                    such as a failure to protect Personal Data,
                    we will deliver it in writing to you and the
                    institution within a period of no later than
                    3 x 24 (three times twenty four) hours.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref12}>
                YOUR RIGHTS
            </h4>
            <p style={{ textAlign: "justify" }}>
                We follow the legal provisions related to Personal
                Data Protection in IndonesiaOur Company would
                like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the
                following:
            </p>
            <ol>
                <li>
                    Get information about the purpose of data
                    collection/processing and data collector, including
                    related third parties.
                </li>
                <li>
                    To renew, change and delete his personal data
                </li>
                <li>
                    To get access and copy of his personal data
                </li>
                <li>
                    To end the processing, remove and/or destroy
                </li>
                <li>
                    To withdraw the consent that has been given
                </li>
                <li>
                    To give objection of automatic data processing,
                    incl profiling
                </li>
                <li>
                    To limit the processing/profiling
                </li>
                <li>
                    To sue and to get compensation due to violation
                    of data processing
                </li>
                <li>
                    Menuntut dan mendapatkan ganti rugi atas
                    pelanggaran pengolahan data
                </li>
            </ol>
            <p style={{ textAlign: "justify" }}>
                If you make a request, we have 3x24 hour to respond
                and fulfill the request to you. If you would like to
                exercise any of these rights, please contact us at our
                email:
            </p>
            <p>Call us at:</p>
            <p>PT Paragon Technology and Innovation<br></br>Jalan Swadharma Raya Kampung Baru IV no 1, Ulujami, Pesanggahan, Jakarta Selatan - 12250</p>
            <br />
            <p>Email : customercare@paracorpgroup.com<br></br>
                Phone : 08041401123<br></br>
                WA : 087701123000<br></br></p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref13}>
                Limiting Cookies
            </h4>
            <p style={{ textAlign: "justify" }}>
                Most web browsers are set to accept cookies by
                default. You can usually choose to set your browser
                to delete or reject browser cookies. Please note that
                if you choose to delete or reject cookies this may
                affect the availability and functionality of our
                website.
            </p>
            <p style={{ textAlign: "justify" }}>
                You can customize your Web browser to accept or
                reject all cookies, and to notify you when a cookie
                is sent. Each Web browser is different in how it
                changes cookie preferences, so disabling cookies
                may affect your use of our services either partially
                or completely.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref14}>
                Children
            </h4>
            <p style={{ textAlign: "justify" }}>
                Our services are not designed for children and do
                not knowingly collect personal data from children
                (under 12 years). If you have reason to believe that
                a child has provided personal data to us, please
                contact us at customercare@paracorpgroup.com
                and We will double-check the facts and delete the
                information from our database if it is proven to be
                child data.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref15}>
                Links to Other Websites and Third Party
                Content
            </h4>
            <p style={{ textAlign: "justify" }}>
                We may provide links to third party Websites,
                services and applications, such as Instagram and
                other trading Websites, which are not operated or
                controlled by us. This Privacy Policy does not apply
                to third party services, and We cannot be
                responsible for the content, privacy policies, or
                practices of third party services. We encourage you
                to review the privacy policies of any third party
                services before providing any information to or
                through them. Your interactions with features from
                these third parties are governed by the privacy
                policies of the third party services that provide these
                features.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref16}>
                Data Storing
            </h4>
            <p style={{ textAlign: "justify" }}>
                We have implemented appropriate technical and
                organizational security measures designed to
                protect the security of any personal information we
                process. However, despite our safeguards and
                efforts to secure your information, no electronic
                transmission over the Internet or information
                storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other unauthorized third
                parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your
                information. Although we will do our best to protect
                your personal information, transmission of personal
                information to and from our Website is at your own
                risk. You should only access the Website within a
                secure environment.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref17}>
                Data Retention
            </h4>
            <p style={{ textAlign: "justify" }}>
                We will only keep your personal information for as
                long as it is necessary for the purposes set out in this
                privacy notice, unless a longer retention period is
                required or permitted by law (such as tax,
                accounting or other legal requirements). No purpose
                in this notice will require us keeping your personal
                information for longer than three (3) months past the
                termination of the user’s account.
            </p>
            <p style={{ textAlign: "justify" }}>
                When we have no ongoing legitimate business need
                to process your personal information, we will either
                delete or anonymize such information, or, if this is
                not possible (for example, because your personal
                information has been stored in backup archives),
                then we will securely store your personal
                information and isolate it from any further
                processing until deletion is possible.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref18}>
                Cross Border Data Transfer
            </h4>
            <p>
                We also operate in other country(ies).
            </p>
            <p style={{ textAlign: "justify" }}>
                Your Personal Data may be also transferred to
                other entities outside Indonesia. We shall comply
                with the laws outside Indonesia related to personal
                data protection.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref19}>
                Changes To Our Privacy Policy
            </h4>
            <p style={{ textAlign: "justify" }}>
                We may change this Privacy Policy from time to
                time. If We do, We will post the updated policy on
                our site and will indicate when the Privacy Policy
                was last revised. We will also send you an email
                notification that our Privacy Policy has recently
                changed. You should periodically review our
                current Privacy Policy to stay informed about our
                personal data practices.
            </p>
            <br />
            <br />
        </>
    )
}
export default PrivacyPolicyContentEnglish;