import React from 'react';

const TestimonialThumbItem = ({data}) => {
    return (
        <div className="client-thumb ml--20">
            <img
                src={require('../../assets/img/'+data.image)}
                alt={data.year}
            />
        </div>
    );
};

export default TestimonialThumbItem;