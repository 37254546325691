import React from 'react';
import MapItemParagon from "../../../components/map/MapItemParagon";
import { useMediaQuery } from 'react-responsive'

const MapParagon = props => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    
    return (
        <div className="google-map-area pt--100 pt-md--80 pt-sm--80 bg_color--1 section text-center">
            <div style={{textAlign:"center", marginBottom:"100px"}}>
                <h1 style={isMobile ? {color:"#215AA8", fontFamily:"Montserrat", fontSize:"32px"} :{color:"#215AA8", fontFamily:"Montserrat", fontSize:"48px"}}>We are All Over Indonesia!</h1>
            </div>

            <div className="position-relative" style={{height: '60vh'}}>
                <MapItemParagon latitude="-6.2173721" longitude="106.6217938"/>
            </div>
        </div>
    );
};

export default MapParagon;
