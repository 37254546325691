import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SlickSlider from "../../components/slick";
import BrandItem from "./ParagonBrandMobileItem"
import BrandTitle from "./ParagonBrandTitle";
import brands from "../../data/service/BrandParagon.json"

const ParagonBrandMobile = () => {

    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-right"/>
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-left"/>
            </button>
        );
    };

    const settings = {
        slidesToShow: 5,
        infinite: true,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
        dots: true,
        responsive: [
            {
                "breakpoint": 1201,
                "settings": {
                    slidesToShow: 4
                }
            },
            {
                "breakpoint": 801,
                "settings": {
                    slidesToShow: 3
                }
            },
            {
                "breakpoint": 601,
                "settings": {
                    slidesToShow: 2
                }
            },
            {
                "breakpoint": 481,
                "settings": {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div className="brook-team-area bg_color--1 ptb--70 ptb-sm--80 ptb-md--80 slick-arrow-hover">
            <Container>
                <Row>
                    <Col xs={12}>
                        <BrandTitle
                            wrapperClass={'text-center'}
                            heading={"OUR BRANDS"}
                            headingColor={"#215A88"}
                        />
                    </Col>
                </Row>
            </Container>
            <SlickSlider
                settings={settings}
                classes="brook-element-carousel slick-arrow-center slick-arrow-triangle slick-dots-bottom slick-gutter-30 mt--80"
            >
                {brands.map(member => (
                    <BrandItem key={member.id} data={member}/>
                ))}
            </SlickSlider>
        </div>
    );
};

export default ParagonBrandMobile;