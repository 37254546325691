import React, {useState} from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useMediaQuery } from 'react-responsive';
import { Row, Col} from "react-bootstrap";

const CounterItemParagon = ({setIndex, index, countPrefix, title, count, img}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    const [appear, setAppear] = useState(false);

    const visibleHandler = (isVisible) => {
        isVisible && setAppear(true);
    };

    const changeContent = () => {
        setIndex(index)
    }

    return (
        <div className="counter-style--paragon wow move-up counter-item" onMouseEnter={changeContent} onClick={changeContent}>
            {isMobile ? 
                <Row>
                    <Col xs={4}>
                        <div className="icon">
                            <img src={img} alt="icon"/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <VisibilitySensor onChange={(e) => visibleHandler(e)}>
                            <span className="count" style={{color: "#215AA8"}}>
                                {countPrefix}<CountUp start={appear ? 0 : null} end={count}/>
                            </span>
                        </VisibilitySensor>
                        <div className="content">
                            <h6 style={{color: "#215AA8"}}>{title}</h6>
                        </div>
                    </Col>
                </Row>
            :
                <>
                    <div className="icon">
                        <img src={img} alt="icon"/>
                    </div>
                    <VisibilitySensor onChange={(e) => visibleHandler(e)}>
                        <span className="count" style={{color: "#215AA8"}}>
                            {countPrefix}<CountUp start={appear ? 0 : null} end={count}/>
                        </span>
                    </VisibilitySensor>
                    <div className="content">
                        <h6 style={{color: "#215AA8"}}>{title}</h6>
                    </div>
                </>
            }
        </div>
    );
};

export default CounterItemParagon;
