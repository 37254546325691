import React from 'react';

const PrivacyPolicyContent = () => {
    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{fontFamily:"Montserrat"}}>Terms and Conditions</h3>
            <h4 style={{fontFamily:"Montserrat"}}>
                Pernyataan Perjanjian
            </h4>
            <ul>
                <li>
                    Jaringan situs-situs web (secara kolektif, “situs web PTI”) yang dioperasikan oleh perusahaan PT Paragon Technology and Innovation (“PTI”), terdiri dari berbagai situs dan halaman web. Penggunaan situs web PTI merupakan bentuk persetujuan Anda akan semua hal seperti syarat, kondisi, dan pemberitahuan di dalamnya.
                </li>
                <li>
                    Dengan mengakses atau menggunakan situs ini, Anda menyetujui ketentuan penggunaan, kondisi, dan semua hukum yang berlaku. Jika Anda tidak menyetujui ketentuan ini, maka Anda tidak dapat menggunakan situs ini.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Hukum yang Berlaku
            </h4>
            <ul>
                <li>
                    Akses dan penggunaan situs ini tunduk terhadap Syarat dan Ketentuan ini serta sesuai dengan hukum dan ketentuan yang berlaku di Republik Indonesia. Pengguna yang memilih untuk mengakses situs ini dari lokasi di luar Indonesia dan melakukannya atas inisiatif mereka sendiri bertanggung jawab untuk mematuhi hukum setempat yang berlaku. 
                </li>
                <li>
                    Hukum Negara Republik Indonesia berlaku untuk Syarat dan Ketentuan ini dan setiap perselisihan yang berkaitan dengan Syarat dan Ketentuan ini akan diajukan ke Pengadilan Negeri Jakarta Selatan.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Hak atas Kekayaaan Intelektual
            </h4>
            <ul>
                <li>
                    Setiap hak cipta dan hak kekayaan intelektual lainnya pada keseluruhan konten yang termasuk dalam situs ini, termasuk namun tidak terbatas pada teks, desain, grafis, interface, atau kode dan pemilihan dan pengaturannya merupakan milik PTI di bawah peraturan dan ketentuan yang berlaku di Indonesia.  
                </li>
                <li>
                    Semua merek dagang, merek layanan, dan nama dagang adalah merek yang terdaftar di bawah nama PTI, atau masing-masing pemilik lain yang telah diberikan hak dan lisensi oleh PTI untuk penggunaannya.
                </li>
                <li>
                    Anda melepaskan setiap hak kepemilikan atas semua komentar, saran, grafis, ide-ide (termasuk ide produk dan ide iklan), dan informasi lainnya atau materi yang Anda kirimkan ke PTI melalui situs ini, dan Anda mengakui hak tak terbatas PTI untuk menggunakannya (baik materi atau ide yang sama dengan masukan Anda) dalam media apapun, sekarang dan di masa depan, tanpa pemberitahuan, kompensasi atau kewajiban apapun kepada Anda atau kepada orang lain. Hal ini juga berarti PTI tidak memiliki kewajiban untuk menjaga kiriman Anda sebagai rahasia. 
                </li>
                <li>
                    Anda diperbolehkan untuk menelusuri situs ini dan untuk mereproduksi isinya baik dengan cara mencetak, mengunduh ke <i>hard disk</i> dan/atau mendistribusikannya kepada orang lain tetapi, sepanjang untuk kepentingan non-komersial, pemberian informasi dan hanya untuk keperluan pribadi saja. Tidak ada reproduksi dari setiap bagian dari situs yang dapat dijual atau didistribusikan untuk keuntungan komersial atau dimodifikasi atau digabungkan dengan pekerjaan lain, publikasi atau situs, baik dalam bentuk <i>hard copy</i> atau format elektronik, termasuk posting situs lain. PTI tidak memberikan lisensi atau hak untuk hal demikian.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Larangan
            </h4>
            <ul>
                <li>
                    Pelecehan dalam cara dan bentuk apapun pada situs ini atau salah satu situs web PTI dilarang keras, termasuk melalui <i>e-mail</i> dan <i>chat</i>, atau dengan penggunaan bahasa yang tidak layak atau kasar.  
                </li>
                <li>
                    Dilarang meniru seseorang, termasuk karyawan, pengawas, representatif PTI, atau anggota lain dan pengunjung di situs.
                </li>
                <li>
                    Anda tidak boleh mengunduh, mendistribusikan, atau mempublikasikan konten lewat situs yang sifatnya memfitnah, menghina, tidak senonoh, mengancam, menyerang hak privasi atau publisitas, kasar, ilegal, atau tidak pantas, atau yang mungkin merupakan atau mendorong terjadinya bentuk tindak pidana, sebagai bentuk pelanggaran hukum.
                </li>
                <li>
                    Anda tidak dapat mengunduh konten komersial di situs atau menggunakan situs untuk meminta orang lain agar bergabung atau menjadi anggota dari layanan online komersial atau organisasi lainnya.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Partisipasi Pengguna
            </h4>
            <ul>
                <li>
                    PTI tidak akan dan tidak dapat memeriksa semua informasi dan materi yang dipasang atau dibuat oleh pengguna yang mengakses situs dan tidak bertanggung jawab atas isi dari informasi dan materi ini. Anda mengakui bahwa dengan memberikan Anda kemampuan untuk melihat dan mendistribusikan konten yang diciptakan oleh pengguna situs ini, PTI hanya bertindak sebagai saluran pasif atas distribusi materi tersebut dan tidak berkewajiban dan bertanggung jawab akan setiap isi atau kegiatan di situs ini. Namun, PTI berhak untuk memblokir atau menghapus informasi atau materi yang ditentukan sebagai hal yang (a) kasar, memfitnah, atau tidak senonoh, (b) bentuk penipuan yang menyesatkan, (c) melanggar hak cipta, merek dagang atau merupakan hak kekayaan intelektual dari orang lain (d) melanggar hukum atau peraturan atau (e) melanggar kebijakan PTI.   
                </li>
                <li>
                    Informasi pribadi Anda yang dipasang atau dikirimkan akan diperlakukan oleh pihak PTI sesuai dengan Kebijakan Privasi situs web ini.
                </li>
                <li>
                    Kecuali untuk informasi pribadi yang dapat kami kumpulkan dari Anda di bawah pedoman yang ditetapkan dalam Kebijakan Privasi situs web ini, materi, informasi, atau bentuk komunikasi lain yang Anda kirim atau unduh ke situs ini akan dianggap sebagai materi non-rahasia dan bukan hak milik. PTI akan bebas untuk menyalin, mengungkapkan, mendistribusikan, menggabungkan semua bentuk komunikasi berupa data, gambar, suara, teks, dan hal-hal lain yang terkandung di dalamnya untuk tujuan komersial atau non-komersial.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Batasan
            </h4>
            <ul>
                <li>
                    Seluruh informasi yang disediakan dalam situs ini bersifat umum dan tidak dapat menjamin apapun. PTI berhak sepenuhnya mengubah judul dan/atau isi informasi dalam situs ini. PTI tidak dapat digugat maupun dituntut atas segala pernyataan, kekeliruan, ketidaktepatan, dan/atau kekurangan dalam setiap informasi yang disampaikan dalam situs ini serta tidak berjanji bahwa situs, konten, atau layanan dari situs akan bebas dari kesalahan atau gangguan.   
                </li>
                <li>
                    PTI tidak memberikan jaminan atau pernyataan tentang keakuratan atau kelengkapan konten situs ini atau Situs Eksternal lain. Informasi dalam situs ini telah dimasukkan untuk tujuan informasi umum saja. Seharusnya tidak dijadikan referensi untuk tujuan tertentu dan tidak ada pernyataan atau jaminan yang diberikan dalam hal keakuratan atau kelengkapan.
                </li>
                <li>
                    PTI tidak menjamin bahwa fungsi yang terdapat dalam situs ini tidak akan mendapat gangguan atau bebas dari kesalahan, bahwa cacat akan diperbaiki, atau bahwa situs ini atau server dapat membuat situs ini bebas dari virus atau komponen berbahaya lainnya.
                </li>
                <li>
                    PTI tidak bertanggung jawab atas kelalaian, atau berdasarkan teori lain, yang muncul dari atau berhubungan dengan penggunaan, ketidakmampuan untuk menggunakan, atau kinerja dari informasi, layanan, produk, dan bahan yang tersedia di situs ini. 
                </li>
                <li>
                    PTI maupun afiliasinya ataupun karyawan atau agensi tidak bertanggung jawab atas setiap kerugian, kerusakan atau biaya yang timbul dari akses atau penggunaan situs ini ataupun tautan yang terdapat padanya, tidak terbatas pada setiap hilangnya keuntungan, secara tidak langsung, kerugian insidental maupun konsekuensial.
                </li>
                <li>
                    Hasil yang didapatkan dari penggunaan produk yang ada di situs ini mungkin akan berbeda terhadap masing-masing pengguna. Semua pelanggan yang membeli produk melalui situs ini secara otomatis telah dianggap membaca dan menyetujui semua pernyataan penyangkalan serta bertanggung jawab penuh terhadap hasil apapun yang dialami atas penggunaan produk. Pelanggan dengan ini akan membebaskan PTI dari tuntutan apapun atas kerugian yang ditanggung akibat penyalahgunaan produk, modifikasi produk, dan/atau hasil atas penggunaan produk.
                </li>
                <li>
                    Jika ada bagian dari Syarat dan Ketentuan ini dinyatakan tidak sah atau tidak dapat dilaksanakan, maka ketentuan tersebut harus dianggap dihapus dari Syarat dan Ketentuan ini dan bagian dari Syarat dan Ketentuan lainnya akan tetap berlaku sepenuhnya.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Ganti Rugi
            </h4>
            <ul>
                <li>
                    Anda setuju untuk mengganti kerugian, membela, dan membebaskan PTI, pejabatnya, direkturnya, karyawannya, agennya, pemberi lisensinya dan pemasoknya (secara kolektif disebut "Penyedia") dari dan terhadap semua kerugian, biaya, kerusakan, dan pengeluaran, termasuk biaya pengacara yang wajar, yang berasal dari pelanggaran syarat dan kondisi atau aktivitas yang berhubungan dengan akun Internet Anda (termasuk tindakan kelalaian atau kesalahan), oleh Anda atau orang lain yang mengakses situs menggunakan akun internet Anda.   
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Modifikasi Situs Web
            </h4>
            <ul>
                <li>
                    PTI memiliki hak untuk mengganti Syarat dan Ketentuan, kondisi, dan pemberitahuan yang dimiliki situs web PTI. Anda bertanggung jawab untuk meninjau ulang secara reguler Syarat dan Ketentuan ini karena sifatnya yang mengikat.   
                </li>
                <li>
                    Beberapa ketentuan dari Syarat dan Ketentuan ini dapat digantikan oleh pemberitahuan hukum yang tegas berdasarkan ketentuan yang terletak di halaman tertentu pada situs ini.
                </li>
                <li>
                    Kami berhak untuk melakukan perubahan dan koreksi terhadap situs ini, ketika kami menganggapnya tepat dan tanpa pemberitahuan.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                PT Paragon Technology and Innovation
            </h4>
            <p>
            Jalan Swadarma Raya Kampung Baru IV No. 1 Jakarta - 12250 Indonesia
            <br/>
            <br/>
            E-mail: customercare@paracorpgroup.com
            <br/>
            <br/>
            Phone:
            <br/>
            08041401123
            <br/>
            <br/>
            Fax: +62 21 585 2439
            </p>
        </>
    )
}

export default PrivacyPolicyContent;