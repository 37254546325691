import { useQuery } from '@tanstack/react-query';
import authAxios from '../../utils/interceptor'

export const useGetDataNewsArticle = (type) => {
  return useQuery(
    ['dataNewsArticle', type],
    async () => {
      const res = await authAxios({
        method: 'GET',
        url: '/corporate-news',
        params: {
          sort: 'createdAt,DESC',
          isActive: true,
          type
        }
      });
      return res.data;
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 5 minutes
      keepPreviousData: true,
      enabled: Boolean(type)
    }
  );
};