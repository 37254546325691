import axios from 'axios';

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const requestHandler = request => {
  return request;
};

const responseHandler = response => {
  return response;
};

const errorRequestHandler = e => {
  return Promise.reject(e);
};

const errorResponseHandler = e => {
  if (e.response && e.response.status === 401) {
    window.location = '/';
  }
  return Promise.reject(e);
};

authAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorRequestHandler(error)
);

authAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorResponseHandler(error)
);

export default authAxios;