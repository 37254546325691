import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'

const ListItem = ({marker, text, content,icon}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className={`${isMobile ? null : 'list-header'} with-${marker}  ${!marker ? 'pl-0': null}`}>
            <Row>
                <Col lg={3} xs={3} style={isMobile ? {justifyContent: "center", display: "flex", marginBottom:"32px"} : null}>
                    <img src={require('../../assets/img/icons/mission/'+icon)} alt="list" style={isMobile ? {height: "fit-content"} : null}/>
                </Col>
                <Col lg={9} xs={9}>
                    <div className="title-wrap" >
                        {marker === 'default' ? (<h5 className="heading heading-h5" style={{color:"#215AA8", marginBottom:"16px"}}>{text}</h5>) : (
                            <h6 className="heading heading-h5">{text}</h6>)}
                        {content && <p className="bk_pra" style={{color:"#215AA8"}}>{content}</p>}
                    </div>

                </Col>
            </Row>
        </div>
    );
};

export default ListItem;
