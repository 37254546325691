import React from 'react';

const ScholarshipBenefitItem = ({title, desc, isFirst}) => {
    return (
        <div className="align-items-center">
            <h4 className={isFirst ? "text-left mt--0" : "text-left mt--50"} style={{color:"#215AA8", fontFamily:"Montserrat"}}>{title}</h4>
            <p className="text-left mt--0" style={{color:"#215AA8", fontFamily:"Montserrat"}}>{desc}</p>
        </div>
    )

}

export default ScholarshipBenefitItem;