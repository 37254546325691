import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const AboutCareer = () => {
    return (
        <div className="bk-about-area-paragon section-ptb-150">
            <Container>
                <Row className="about--paragon align-items-center">

                    <Col xs={12} lg={12} className="mt_md--40 mt_sm--40">
                        <div className="content pl_md--5 pl_sm--5">
                            <div className="title--paragon wow move-up text-center object-center">
                                <h2 style={{color:"#215AA8", fontSize:"32px", width:"100%", fontFamily:"Montserrat"}}>
                                    GROW AT PARAGON
                                </h2>
                            </div>
                            <div className="title--paragon wow move-up text-center object-center">
                                <h3 className="wow move-up " style={{color:"#215AA8", fontWeight:"bold", fontSize:"28px", width:"70%", fontFamily:"Montserrat"}}>
                                    We are committed to continuously growing and providing benefits through the best works of Indonesia for Indonesia.    
                                </h3>
                            </div>
                            <div className="title--paragon wow move-up text-center object-center">
                                <p className="wow move-up mt--50" style={{color:"#215AA8", fontFamily:"Montserrat"}}> 
                                    PT Paragon Technology and Innovation is built with the belief in the spirit of young people to open up to each other and to build every process. Paragon provides creative freedom by encouraging every element to give the best performance with full responsibility. Along with the soul of collaboration and exploration, Paragon is committed to continuously growing and providing benefits through the best works of Indonesia for Indonesia.
                                </p>
                            </div>
                            <div className="title--paragon wow move-up text-center object-center">
                                <p className="wow move-up mt--20" style={{color:"#215AA8", fontFamily:"Montserrat"}}>
                                    At Paragon, we have a variety of career opportunities for final-year students, fresh graduates, or even professionals. In the spirit of providing bigger benefits for the Paragonians, partners, communities, and the environment, we invite you to become part of Paragon. If you are an agile person who likes challenges, and has the intention of giving more benefits to this country, Paragon is the right place for you to grow.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};

export default AboutCareer;
