import React from 'react';
import { useMediaQuery } from 'react-responsive'

const ParagonParacoItem = ({data, classes}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className={`testimonial testimonial_style--1 ${classes && classes}`} style={{backgroundColor:"#000000 !important", height:"70vh"}}>
            <div className="content">
                <h3 className="bk_pra heading-font">
                    <img className="mr--40" src={require('../../assets/img/' + data.paraco)} alt={data.paraco_name} style={isMobile ? {height:"120px", display:"inline", fontFamily:"Montserrat"} : {height:"15vh", display:"inline", fontFamily:"Montserrat"}}/> {data.paraco_name}
                </h3>
                <p className="bk_pra heading-font mt--40" style={isMobile ? {fontSize: "calc(12px + 0.8vw)", fontFamily:"Montserrat"} : {fontSize: "calc(8px + 1vh)", fontFamily:"Montserrat"}}>
                    {data.quote}
                </p>
            </div>
        </div>
    );
};

export default ParagonParacoItem;