import React from 'react';
import {Col, Row} from "react-bootstrap";
import VirtualOfficeItem from  "./VirtualOfficeItem"

const VirtualOfficeTourSection = () => {
    
    return (
        <div className="brook-service-area bg_color--10">
            <Row className="row--0 align-items-center">
                <Col xs={12} xl={6}>
                    <VirtualOfficeItem
                        link="https://www.youtube.com/watch?v=iDYRZfJVnkw"
                        title="Virtual Head Office Tour"
                        bgClass="bg-virtual-office-tour"
                        buttonText="Experience Head Office Tour"
                        float="right"
                        copy="Feel the innovative and agile ecosystem in Indonesia’s cosmetics industry"/>
                </Col>

                <Col xs={12} xl={6}>
                    <VirtualOfficeItem
                        link="https://www.youtube.com/watch?v=20w1s_ML9fk"
                        title="Virtual Plant Tour"
                        bgClass="bg-virtual-plant-tour"
                        buttonText="Experience Plant Tour"
                        float="left"
                        copy="Explore and experience the vibes of Indonesia’s biggest cosmetics industry"/>
                </Col>
            </Row>
        </div>
        
    );
};

export default VirtualOfficeTourSection;
