import React from "react";

const ParagonBrandItem = ({ data }) => {
  return (
    <div
      style={{ backgroundColor: data.color, height: "60vh", padding: "32px" }}
    >
      <div>
        <div className="icon mb--25">
          <img
            src={require("../../assets/img/service/icon-box/" + data.icon)}
            alt={data.title}
            style={data.icon?.includes("emina") ? { marginLeft: "-24px" } : data.icon?.includes("earth") ? { marginLeft: "-32px" } : {}}
          />
        </div>
        <div className="content">
          <div className="pt--20" />
          <p className="bk_pra text-white">{data.description}</p>
          {data.website && (
            <div style={{ color: "white", paddingTop: "50px" }}>
              <a href={data.website} target="_blank" rel="noopener noreferrer">
                <span>View More</span>{" "}
                <span
                  className="btn-arrow"
                  style={{ color: "white", width: "30px" }}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParagonBrandItem;
