import React from 'react';

const ParagonBrandTitle = ({heading, headingColor, className, wrapperClass, separator, separatorColor}) => {
    return (
        <div className={`brook-section-title ${wrapperClass}`}>
            <h3 className="heading" style={{color: "#215AA8", fontFamily:"Montserrat"}}>{heading}</h3>
            {separator && (
                <>
                    <div className="bk-separator--25"/>
                    <div className={`title-separator w--80 color-${separatorColor ? separatorColor : 'blue'}`}/>
                </>
            )}
        </div>
    );
};

export default ParagonBrandTitle;