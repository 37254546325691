import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const BrandDetailItem = ({ brandWebsiteLink, brandText }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        minHeight: "45vh",
        display: "flex",
        alignItems: "center",
      }}
      className="section pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30 mt_sm--50 mb_sm--50"
    >
      <Container
        fluid={true}
        className="plr--180 plr_lg--100 plr_md--10 plr_sm--10"
      >
        <Row className="align-items-center">
          <Col lg={12}>
            <div className="breadcrumb-inner text-center align-items-center">
              <p style={{ color: "#215AA8" }}>{brandText}</p>
              {brandWebsiteLink && (
                <Row
                  className="align-items-center"
                  style={{ justifyContent: "center" }}
                >
                  <Col lg={3} sm={6}>
                    <div className="brook-btn mt--50">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={brandWebsiteLink}
                      >
                        <button
                          style={{ width: "80%", minWidth: "fit-content" }}
                          className="brook-btn bk-btn-border-paragon btn-sd-size btn-rounded"
                        >
                          Visit &amp; Shop
                        </button>
                      </a>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BrandDetailItem;
