import React, { useEffect } from "react";
import services from "../../data/service/BrandParagon.json";
import ServiceItem from "../../components/service/BrandItemParagon";
import ScrollContainer from "react-indiana-drag-scroll";

const ParagonBrand = () => {
  useEffect(() => {
    const architectureServices = document.querySelectorAll(
      ".architecture-service"
    );
    const serviceContainer = document.querySelector(
      ".architecture-service-container"
    );
    serviceContainer.style.backgroundImage = `url(${require("../../assets/img/slider/type/" +
      services[0].thumb)})`;
    architectureServices.forEach((service, indx) => {
      service.addEventListener("mouseenter", () => {
        let thumb = require("../../assets/img/slider/type/" +
          services[indx].thumb);
        serviceContainer.style.backgroundImage = `url(${thumb})`;
      });
    });
  }, []);

  return (
    <div
      className="brook-service-area architecture-service-container"
      style={{ backgroundSize: "cover" }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "black",
          opacity: "0.6",
        }}
      />
      <div style={{ overflow: "auto", whiteSpace: "nowrap", cursor: "grab" }}>
        <ScrollContainer>
          {services.map((service) => (
            <div
              key={service.id}
              style={{
                display: "inline-block",
                width: "25vw",
                whiteSpace: "normal",
              }}
            >
              <ServiceItem data={service} />
            </div>
          ))}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default ParagonBrand;
