import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import BrandList from "../../container/counter/ParagonFourBrand";
import BrandBannerItem from "../../container/contact/BrandBannerItem";
import BrandDetailItem from "../../container/contact/BrandDetailItem";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import wardah from "../../assets/img/wardah_logo_big.png";
import makeover from "../../assets/img/make_over_logo_big.png";
import emina from "../../assets/img/emina_logo_big.png";
import kahf from "../../assets/img/kahf_logo_big.png";
import beyondly from "../../assets/img/beyondly_logo_big.png";
import biodef from "../../assets/img/biodef_logo_big.png";
import crystallure from "../../assets/img/crystallure_logo_big.png";
import instaperfect from "../../assets/img/instaperfect_logo_big.png";
import labore from "../../assets/img/labore_logo_big.png";
import omg from "../../assets/img/omg_logo_big.png";
import putri from "../../assets/img/putri_logo_big.png";
import tavi from "../../assets/img/tavi_logo_big.png";
import wonderly from "../../assets/img/wonderly_logo_big.png";
import earthlovelife from "../../assets/img/earthlovelife_logo_big.png"

const AboutBrandParagon = () => {
  const brandList = [
    {
      brand: "wardah",
      logo: wardah,
      websiteLink: "https://www.wardahbeauty.com/",
      desc: "Wardah is a pioneer of halal cosmetics in Indonesia that combines natural purity with a halal, modern, and reliable process to produce high-quality halal products with international innovation standards. Through love, respect and appreciation, we are committed to getting women love themselves and making true beauty shines.",
    },
    {
      brand: "makeover",
      logo: makeover,
      websiteLink: "https://www.makeoverforall.com/",
      desc: "There is no limit in defining your beauty for Make Over, because beauty belongs to all women. Set yourself free and show the world that freedom and expression are worldwide communication. It's time to live in a world where only beauty dominates us all.",
    },
    {
      brand: "emina",
      logo: emina,
      websiteLink: "https://www.eminacosmetics.com/",
      desc: "For Emina, every day is a new opportunity to be greeted with enthusiasm and passion. Emina's range of skincare and make-up are very easy to apply for teenagers and new makeup users. Emina will help you  radiate positive energy every day with happy and healthy skin!",
    },
    {
      brand: "kahf",
      logo: kahf,
      websiteLink: "https://www.kahfeveryday.com/",
      desc: "Kahf is a halal grooming brand for men and inspired by nature inspired by nature with natural, halal, and dermatologically tested ingredients. Men self-care products which prepare each user to discover and have positive impact on their surroundings from the very start of the day.",
    },
    {
      brand: "putri",
      logo: putri,
      websiteLink: "",
      desc: "Putri is a hair care product that offers salon-quality treatment. It has been developed in consultation with leading hairdressers to meet the highest standards. Trusted and favored by salons across Indonesia since 1985 to the present day, Putri offers a range of Hair Care, Hair Treatment, and Body Care products to ensure customer satisfaction in the process of hair and body care.",
    },
    {
      brand: "crystallure",
      logo: crystallure,
      websiteLink: "https://crystallurebeauty.com/",
      desc: "Through 12 years of extensive research, Crystallure presence as The Expert in Halal Skincare with Advance Technology and Luxurious Experience to establish a Visible Translucent Youthful Glow, with Gold-peptide Crystals and Amino Ectoin as hero ingredients, make your desired healthy translucent skin come to reality with Crystallure.",
    },
    {
      brand: "instaperfect",
      logo: instaperfect,
      websiteLink: "https://myinstaperfect.com/",
      desc: "Instaperfect specializes in real skin makeup, through advanced formulations of RealSkin Filter™ that conceals flaws from first swipe while still revealing real skin at the same time. Enhanced with sophisticated innovations of makeup repertoires for your face, lips & eyes to complete your seamless glam finish look.",
    },
    {
      brand: "labore",
      logo: labore,
      websiteLink: "https://www.laboreskinexpert.com/",
      desc: "LABORÉ is an evolutionary sensitive skin care with Microbiome Technology designed by dermatologists and global experts, proven to solve tropical sensitive skin problems. LABORÉ understands that tropical skin has distinctive characteristics, therefore fixing the tropical skin barrier requires specific expertise.",
    },
    {
      brand: "biodef",
      logo: biodef,
      websiteLink: "https://biodef.co.id/",
      desc: "Biodef as the global expert in halal natural antiseptic body wash, is Indonesian progressive Mom’s advanced support system that protects her whole family with impenetrable barriers from any ever-present germs and viruses while gentle enough in maintaining triple skin barrier health. So everyday becomes new joyful experience awaits to be explored, igniting their room to grow as enablers in creating a better future generation.",
    },
    {
      brand: "tavi",
      logo: tavi,
      websiteLink: "https://www.tavi-world.com/",
      desc: "TAVI was born from a hybrid of creative minds that value exploration with thoughtfulness of scientists that always sparks curiosity. Since creativity is in our DNA, we are genuinely invested in exploring out of the box approaches to create unpredictable combo of powerful ingredients to deliver effective and gentle formulas. In addition, we respect the planet as much as we do your skin.",
    },
    {
      brand: "wonderly",
      logo: wonderly,
      websiteLink: "https://www.wonderlyskin.com/",
      desc: "WONDERLY is the only brand that provides A Luxurious beauty clinic-inspired brightening products that gives a long-term & noticeable healthy bright skin. Consisting of beauty clinic like face care and body care products with world class fragrance that gives an instant noticeable brighter, yet healthy and long lasting skin.",
    },
    {
      brand: "omg",
      logo: omg,
      websiteLink: "https://www.itsomgbeauty.com/",
      desc: "OMG was born for democratizing beauty for everyone. Beauty play important role in every girlboss. We fulfill consumers need to have high value beauty products, makeup or skincare, with affordable price. With BEAUTY NEVER FADE, OMG support every woman to achieve their dream and always ready to face anything in their life.",
    },
    {
      brand: "beyondly",
      logo: beyondly,
      websiteLink: "https://www.beyondlyid.com/",
      desc: "Beyondly gives a chance for every woman to build their own business, both to improve their financial and personal development, as well as to benefit their family and society. Beyondly's business is halal, safe, and easy, while providing optimal benefits. Beyondly's products support overall health and wellness, including Healthy Inside (Collagen Drink), Healthy Outside (Collagen Skincare), and Healthy In Mind (Essential Oil).",
    },
    {
      brand: "earthlovelife",
      logo: earthlovelife,
      websiteLink: "",
      desc: "The 1st in the world: Neuroscience-proven Mood Boosting Fragrance™️ body care, that provides a blooming skin, grounded mind, and greater earth.",
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Brand Paragon</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1 bg_color--1"
        revealFooter={false}
      >
        <Header />
        <Breadcrumb title={"BRANDS"} bgImage={"bg-image-brand"} />
        <BrandList />
      </LayoutDefault>

      {brandList.map((brand, index) => (
        <Fragment key={index}>
          <LayoutDefault
            className="template-color-4 template-font-1 bg_color--1"
            revealSection={true}
          >
            <BrandBannerItem brandName={brand.brand} brandImage={brand.logo} />
          </LayoutDefault>
          <BrandDetailItem
            brandWebsiteLink={brand.websiteLink}
            brandText={brand.desc}
          />
        </Fragment>
      ))}
      <LayoutDefault
        className="template-color-1 template-font-2"
        revealFooter={false}
      >
        <FooterParagon />
      </LayoutDefault>
    </Fragment>
  );
};

export default AboutBrandParagon;
