import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Thumbnail from "../../components/about-us/thumbnail/AboutThumbOne";
import errorImg from "../../assets/img/404_logo.png";
import Button from "../../components/button";

const Error404 = () => {
    return (
        <div className="error-not-found" style={{background:"white"}}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="error-inner text-center">
                            <Thumbnail is404={true} thumb={errorImg} className="mb--55 image text-center"/>
                            <h3 className="heading heading-h3" style={{color:"#215AA8"}}>Page not found</h3>
                            <div className="error-text mt--20">
                                <p style={{color:"#215AA8"}}>
                                    The page you requested was not found. You can either go back to the last page or go to homepage.
                                </p>

                                <div className="error-button-group mt--40">
                                    <Button
                                        type="button"
                                        btnText="Go Back"
                                        btnStyle="rounded"
                                        btnColor="#215AA8"
                                        size="sd"
                                        color="theme"
                                        onClick={()=> window.history.back()}
                                    />

                                    <Button
                                        type="link"
                                        btnText="Homepage"
                                        btnLink="/"
                                        btnBorder="solid 2px #215AA8"
                                        txtColor="#215AA8"
                                        btnStyle="rounded"
                                        size="sd"
                                        color="white"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Error404;
