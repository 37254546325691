import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon"
import AboutCareer from './AboutCareer';
import TeamParagon from './TeamParagon';
import CareerAction from "./CareerAction"
import banner from "../../assets/img/banner/career_website_banner.jpg"
import instagram from "../../assets/img/icons/ic_instagram.png"
import linkedin from "../../assets/img/icons/ic_linkedin.png"
import spotify from "../../assets/img/icons/ic_spotify.png"

const CareerPage = () => {
    const [mediaButton] = React.useState([
        {
            media: instagram,
            link: "https://www.instagram.com/growatparagon/",
            name: "instagram",
        },
        {
            media: linkedin,
            link: "https://www.linkedin.com/company/pt-paragon-technology-&-innovation",
            name: "linkedin",
        },
        {
            media: spotify,
            link: "https://open.spotify.com/show/0jo7iuCW7Z8xDULUXT2VXu?si=PiNwMV8KSsCr4jggR4_bqA",
            name: "spotify",
        }
    ])

    return (
        <Fragment>
            <Helmet>
                <title>Paragon - Career</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"CAREER"}
                    bgImage={"bg-image-career"}
                />
                <AboutCareer/>

                <CareerAction
                    bgColor={"#215AA8AA"}
                    textColor={"white"}
                    bgImage={banner}
                    text={"Be Part of Something Great"}
                    btnText={"VIEW ALL JOBS"}
                    type={"website"}
                    link={"https://career.paragon-innovation.com/"}
                    isLarge={false}/>
                <TeamParagon/>
                <CareerAction
                    bgColor={"white"}
                    textColor={"#215AA8"}
                    text={"Take a Peek at the Adventure Inside Paragon"}
                    type={"media"}
                    listMedia={mediaButton}
                    isLarge={false}/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default CareerPage;