import React from 'react';
import {Container} from "react-bootstrap";
import SliderSwiper from "../../components/swiper";
import {heroSliderConfig} from "../../helper/heroSliderConfig";
import sliderData from "../../data/slider/ParagonHomeSlider.json";
import PopupVideo from "../../components/paragon-popup-video";
import { useMediaQuery } from 'react-responsive';
//Used

const ParagonHomeSlider = () => {

    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const is1440 = useMediaQuery({query: '(max-width: 119em'})

    const settings = {
        ...heroSliderConfig,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: (index, className) => {
                return `<span style="font-size:28px; padding-bottom:10px" class="${className}">0${index + 1}</span>`
            }
        }
    }

    return (
        <div className="hero-slider hero-slider--paragon">
            <SliderSwiper settings={settings} style={{float:"left"}}>
                {sliderData.map(slide => (
                    <div className="swiper-slide" key={slide.id}>
                        <div
                            className="slide-inner slide-bg-image"
                            style={{backgroundImage: `url(${require('../../assets/img/' + slide.bgImg)})`}}
                        >
                            <Container fluid>
                                <div className={`slide-content slide--1`} style={isMobile||is1440 ? {width:"60%"} : {width:"40%"}}>
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h4 className={isMobile ? "mb--30" : is1440 ? "mb--30" : "mb--70"} style={{color:"white"}}>{slide.subHeading}</h4>
                                        <h2 style={{fontWeight:"700 !important", fontFamily:"Montserrat"}}>{slide.heading}</h2>
                                    </div>
                                </div>
                                <div className="slide-content-bottom d-none d-md-flex">
                                    <div>
                                        
                                    </div>
                                    <PopupVideo
                                        videoId={slide.videoID}
                                        iconStyleClass="third-icon secondary-color"
                                        color="white"
                                    />
                                </div>
                            </Container>
                        </div>
                    </div>
                ))}
            </SliderSwiper>
        </div>
    );
};


export default ParagonHomeSlider;
