import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import FooterParagon from "../../container/footer/FooterParagon";
import { useParams } from 'react-router';
import BeatLoader from "react-spinners/BeatLoader";
import { Col, Container, Row } from 'react-bootstrap';
import AboutThumbOne from '../../components/about-us/thumbnail/AboutThumbOne';
import dateFormat from 'dateformat';
import Error404 from '../../container/error/Error404';
import { Link, useLocation } from 'react-router-dom';
import { generate } from '@bramus/pagination-sequence';
import { useGetDataByID } from '../../hooks/api/useGetDataByID'
import { useGetDataNewsArticle } from '../../hooks/api/useGetDataNewsArticle'

const BlogDetailsNoSidebar = () => {
    const newsID = useParams().id
    const location = useLocation()
    const categoryPost = location.pathname.split('/').slice(2)[0]

    function NewsContentBe() {
        const newsID = useParams().id

        const { data, isLoading, isFetching, error } = useGetDataByID(newsID)

        if (isLoading || isFetching) {
            return (
                <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                    <div style={{ marginTop: "30vh" }} />
                    <BeatLoader color={"#215AA8"} loading={true} size={72} />
                </div>
            )
        }
        if (error) return (
            <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                <div style={{ marginTop: "30vh" }} />
                Unable to fetch data
            </div>
        )

        if (data.errorStatus) {
            return (
                <Error404 />
            )
        }

        const dataNewsDetail = data.data.news

        return (
            <>
                <Helmet>
                    <title>{dataNewsDetail.title} - {dataNewsDetail.type} - ParagonCorp</title>
                </Helmet>
                <div className="brook-blog-details-area bg_color--1 pt--90 pb--100">
                    <Container>
                        <Row>
                            <Col lg={8} className='mx-auto'>
                                <div className="blog-details-wrapper">
                                    <article className="blog-post standard-post">
                                        <header className="header mb--40 text-center">
                                            <h3 style={{ fontFamily: "Montserrat" }} className="heading heading-h3 font-32">
                                                {dataNewsDetail.title}
                                            </h3>
                                            <div className="post-meta mt--20">
                                                <div style={{ fontFamily: "Montserrat" }} className="post-date">
                                                    {
                                                        dateFormat(new Date(
                                                            parseInt(dataNewsDetail.createdAt.substring(0, 4)),
                                                            parseInt(dataNewsDetail.createdAt.substring(5, 7)) - 1,
                                                            parseInt(dataNewsDetail.createdAt.substring(8, 10)))
                                                            , "mmm dd, yyyy")
                                                    }
                                                </div>
                                            </div>
                                        </header>
                                        <AboutThumbOne thumb={dataNewsDetail.imageThumb} className="mb--10" />
                                        <p style={{ fontFamily: "Montserrat", fontSize: "12px", textAlign: "center" }} className="bk_pra wow move-up mb--60" dangerouslySetInnerHTML={{ __html: dataNewsDetail.imgCaption }} ></p>
                                        <section className="content basic-dark2-line-1px pb--50 mb--35">
                                            <div className="inner">
                                                <div className="desc mt--45 mb--50 news">
                                                    <p style={{ fontFamily: "Montserrat", fontSize: "18px" }} className="bk_pra wow move-up" dangerouslySetInnerHTML={{ __html: dataNewsDetail.body }} ></p>
                                                </div>
                                            </div>
                                        </section>
                                    </article>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }

    function NewsPagination() {

        const { data, isLoading, isFetching, error } = useGetDataNewsArticle(categoryPost)

        if (isLoading || isFetching) {
            return (
                <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                    <div style={{ marginTop: "30vh" }} />
                    <BeatLoader color={"#215AA8"} loading={true} size={72} />
                </div>
            )
        }
        if (error) return (
            <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                <div style={{ marginTop: "30vh" }} />
                Unable to fetch data
            </div>
        )

        let allNews = data.data.listNews

        allNews.map((data, index) => {
            return data.pageIndex = index + 1
        })

        //pagination from library
        const PaginationEntry = ({ value, isCurrent = false }) => {

            let objNews = allNews.find(o => o.pageIndex === value)

            if (value === '…') {
                return (
                    <li data-pagination-ellipsis><span>…</span></li>
                );
            }
            if (isCurrent) {
                return (
                    <li data-pagination-current={false} className='active'><Link to='#'>{value}</Link></li>
                );
            }
            return (
                <li>
                    <Link to={`${process.env.PUBLIC_URL}/post/${categoryPost}/${objNews.id}`}>
                        {value}
                    </Link>
                </li>
            );
        }

        function Pagination() {
            let objNews = allNews.find(o => o.id === newsID)

            if (!objNews) {
                return (
                    null
                )
            }

            const sequence = generate(objNews.pageIndex, allNews.length)

            const prevIndex = (allNews.map((post) => post.id).indexOf(newsID) - 1) + 1
            const indexIDPrev = allNews.find(o => o.pageIndex === prevIndex)

            const nextIndex = (allNews.map((post) => post.id).indexOf(newsID) + 1) + 1
            const indexIDNext = allNews.find(o => o.pageIndex === nextIndex)

            return (
                <div className="brook-blog-details-area pb--150">
                    <Container>
                        <Row>
                            <Col lg={8} className='mx-auto'>
                                <div className="brook-pagination-wrapper text-center">
                                    <ul className="brook-pagination">

                                        {
                                            !indexIDPrev ? null :
                                                <li data-pagination-prev>
                                                    <Link to={`${process.env.PUBLIC_URL}/post/${categoryPost}/${indexIDPrev?.id}`}>
                                                        <i className="fa fa-angle-left" />
                                                    </Link>
                                                </li>
                                        }

                                        {sequence.map(number =>
                                            <PaginationEntry key={number} value={number} isCurrent={number === objNews.pageIndex} />
                                        )}

                                        {
                                            !indexIDNext ? null :
                                                <li data-pagination-next>
                                                    <Link to={`${process.env.PUBLIC_URL}/post/${categoryPost}/${indexIDNext?.id}`}>
                                                        <i className="fa fa-angle-right" />
                                                    </Link>
                                                </li>
                                        }

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        return (
            <Pagination />
        );
    }

    return (
        <Fragment>
            <LayoutDefault className="template-color-1 template-font-1 bg_color--1" revealFooter={false}>
                <Header isBlock={true} />
                    <NewsContentBe />
                    <NewsPagination />
                <FooterParagon />
            </LayoutDefault>
        </Fragment>
    );
};

export default BlogDetailsNoSidebar;
