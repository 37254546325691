import React, {Component} from 'react';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import markerIcon from "../../assets/img/paragon_pinpoint.png";
import gmapMarkerBg from "../../assets/img/paragon_segitiga.png";

class MapItemParagon extends Component {
    state = {
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
        pinPoints: [
            {
                name: "Head Office",
                phone: "+62 21 584 9070",
                email:"Jl. Kp. Baru IV No.1, RT.5/RW.2, Ulujami, Kec. Pesanggrahan, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12250",
                position: {lat: -6.2261749, lng: 106.7607015}
            },
            {
                name: "Main Factory",
                phone: "+62 21 584 9070",
                email:"Kawasan Industri Jatake, Jalan Industri Raya IV Blok AG No. 8, Pasir Jaya, Jatiuwung, Bunder, Cikupa, Tangerang City, Banten 15136",
                position: {lat: -6.188806, lng: 106.5386412}
            },
            {
                name: "DC Aceh",
                phone: "+62 21 584 9070",
                email:"Jl. Bandara Sultan Iskandar Muda, Meunasah Baro, Kec. Ingin Jaya, Kabupaten Aceh Besar, Aceh 24414 (ex Rumah Makan Bineh Blang)",
                position: {lat: 5.5131907, lng: 95.3740529}
            },
            {
                name: "DC Bali",
                phone: "+62 21 584 9070",
                email:"Jalan Mudu Taki No. 20 Gatot Subroto Barat Denpasar Bali.",
                position: {lat: -8.6341615, lng: 115.1795954}
            },
            {
                name: "DC Bandung",
                phone: "+62 21 584 9070",
                email:"Kantor Operasional : Jl. Raya Derwati no 8. Kec rancasari, Bandung",
                position: {lat: -6.9537419, lng: 107.5897515}
            },
            {
                name: "DC Bangka",
                phone: "+62 21 584 9070",
                email:"Jalan Minfo Pergudangan Asun, Gang SMK 1, Kecamatan Pangkalan Baru, Kabupaten Bangka Tengah 33684",
                position: {lat: -2.180172, lng: 106.1450693}
            },
            {
                name: "DC Banjarmasin",
                phone: "+62 21 584 9070",
                email:"Jalan Ahmad Yani KM 14.8 Pergudangan Untung Square Blok D8-D10 Kabupaten Banjar Kalimantan Selatan",
                position: {lat: -3.4104688, lng: 114.668502}
            },
            {
                name: "DC Banten",
                phone: "+62 21 584 9070",
                email:"Modern Cikande Industrial Estate. No. 12-14, Jalan Raya Jakarta-Serang KM 68, Cikande, Nambo Ilir, Kec. Kibin. Serang, Banten 42186",
                position: {lat: -6.1855185, lng: 106.3357367}
            },
            {
                name: "DC Batam",
                phone: "+62 21 584 9070",
                email:"Kantor Operasional : Komplek Metro Industrial Park KM 7 Jalan Kijang Lama Blok D Nomor 11-12, Kelurahan Melayu Kota, Kec Tanjung Pinang Timur, Kota Tanjung Pinang",
                position: {lat: 0.9208817, lng: 104.4838241}
            },
            {
                name: "DC Bekasi",
                phone: "+62 21 584 9070",
                email:"Jalan KUD Setu-Serang RT 01, RW 01 Desa Telajung, Kec. Cikarang Barat Kab. Bekasi 17520",
                position: {lat: -6.3380025, lng: 107.0441461}
            },
            {
                name: "DC Bengkulu",
                phone: "+62 21 584 9070",
                email:"Jalan Semangka Raya No. 49 Panorama Singaran Pati Kota Bengkulu 38226",
                position: {lat: -3.8155389, lng: 102.2957192}
            },
            {
                name: "DC Bogor",
                phone: "+62 21 584 9070",
                email:"Komplek Pergudangan NAS Warehouse Gudang I, Jalan Raya Jakarta Bogor KM 41.2, Pabuaran, Cibinong Jawa Barat 16916",
                position: {lat: -6.4582866, lng: 106.855215}
            },
            {
                name: "DC Cirebon",
                phone: "+62 21 584 9070",
                email:"Jalan Sekar kemuning no 8, RT 01 RW 13 kel. karyamulya kec. kesambi, Cirebon",
                position: {lat: -6.7413316, lng: 108.5423008}
            },
            {
                name: "DC Jakarta",
                phone: "+62 21 584 9070",
                email:"Jalan Swadarma Raya No. 40-41 RT.010 RW.08 Kelurahan Ulujami Kecamatan Pesanggrahan Jakarta Selatan 12250",
                position: {lat: -6.2263151, lng: 106.759899}
            },
            {
                name: "DC Jambi",
                phone: "+62 21 584 9070",
                email:"Kompleks Jambi Trade Centre No 4, Jalan Lingkar Selatan 1, RT 26 Kel. Kenali Asam Bawah, Kec. Kota Baru Jambi - 36128",
                position: {lat: -1.6760074, lng: 103.6135709}
            },
            {
                name: "DC Jember",
                phone: "+62 21 584 9070",
                email:"Jalan Wolter Monginsidi No. 167 - 169 Kelurahan Kranjingan Kecamatan Sumbersari Kabupaten Jember Provinsi 68123",
                position: {lat: -8.2124822, lng: 113.7124513}
            },
            {
                name: "DC Jogja",
                phone: "+62 21 584 9070",
                email:"Jalan Parangtritis KM 5,6 Pandes ( depan Pyramid Cafe ) Panggungharjo, Sewon, Bantul Yogyakarta 55188",
                position: {lat: -7.8443331, lng: 110.3612184}
            },
            {
                name: "DC Kediri",
                phone: "+62 21 584 9070",
                email:"Jalan Raung no 40D, Gg Taman Kemuning, Bandar Kidul, Kediri Kota(Gudang no 3 samping SPBU Muning)",
                position: {lat: -7.8319269, lng: 111.9924592}
            },
            {
                name: "DC Kendari",
                phone: "+62 21 584 9070",
                email:"Jalan Malik Raya No. 45, Kelurahan Korumba, Kecamatan Mandonga, Kota Kendari 93111",
                position: {lat: -3.9726845, lng: 122.5224572}
            },
            {
                name: "DC Lampung",
                phone: "+62 21 584 9070",
                email:"Jalan Tembesu VI No. 2, Campang Raya, Kec. Tj. Karang Tim., Kota Bandar Lampung, Lampung 35122",
                position: {lat: -5.412384, lng: 105.2444312}
            },
            {
                name: "DC Makassar",
                phone: "+62 21 584 9070",
                email:"Jalan Kapasa Raya No. 18, Kel. Kapasa, Kec. Tamalanrea, Makassar.",
                position: {lat: -5.1057033, lng: 119.4937068}
            },
            {
                name: "DC Malang",
                phone: "+62 21 584 9070",
                email:"Jalan Raya Singosari No 123 Kelurahan Pagentan Kecamatan Singosari Kabupaten Malang",
                position: {lat: -7.9005127, lng: 112.6607058}
            },
            {
                name: "DC Manado",
                phone: "+62 21 584 9070",
                email:"Jalan Manado-Bitung, No 169, Kalawat, Minahasa Utara, Sulawesi Utara",
                position: {lat: 1.460532, lng: 124.932243}
            },
            {
                name: "DC Mataram",
                phone: "+62 21 584 9070",
                email:"Jalan TGH Lopan No. 8 Dasan Cermen, Kecamatan Sandubaya, Kota Mataram, Nusa Tenggara Barat",
                position: {lat: -8.6150661, lng: 116.1269834}
            },
            {
                name: "DC Medan",
                phone: "+62 21 584 9070",
                email:"Jalan Sisingamangaraja KM 10,8 Kompleks Amplas Trade Center (ATC) Blok Jati No. 9-10, Kelurahan Timbang Deli, Kecamatan Medan Amplas,Kota Medan, Sumatera Utara, 20148",
                position: {lat: 3.5352297, lng: 98.7321805}
            },
            {
                name: "DC Padang",
                phone: "+62 21 584 9070",
                email:"Jalan Rambun Bulan No.10, Kampung Lapai, Nanggalo, Padang City, West Sumatra 25173",
                position: {lat: -0.914734, lng: 100.3408382}
            },
            {
                name: "DC Palangkaraya",
                phone: "+62 21 584 9070",
                email:"Jl. RTA Milono Km 7.5 Kav D6-7, Kel. Kereng Bangkirai, Kec. Sabangau, Kota Palangkaraya, Kalimantan Tengah 74874 (Sebrang Vihara Yatha)",
                position: {lat: -2.275889, lng: 113.9156578}
            },
            {
                name: "DC Palembang",
                phone: "+62 21 584 9070",
                email:"Jl. Letkol Andriansz lorong serumpun Sukabangun 2 Kel. Sukajaya Kec. Sukarami Kota Palembang",
                position: {lat: -2.9333568, lng: 104.7333235}
            },
            {
                name: "DC Pekanbaru",
                phone: "+62 21 584 9070",
                email:"Jalan Air Hitam Komplek Pergudangan Platinum Blok A No. 5 Kelurahan Bina Widya Kecamatan Tampan Kota Pekanbaru Riau",
                position: {lat: 0.4990079, lng: 101.3627637}
            },
            {
                name: "DC Pontianak",
                phone: "+62 21 584 9070",
                email:"Komplek Pergudangan Ocean 88 Blok 8G02, Jalan Adisucipto km. 11, Desa Arang Limbung, Sungai Raya, Kab Kuburaya",
                position: {lat: -0.0999442, lng: 109.407965}
            },
            {
                name: "DC Purwokerto",
                phone: "+62 21 584 9070",
                email:"Jalan Sultang Agung RT 003 RW 002 No.28 Desa Karangrau Sokaraja Kecamatan Sokaraja Kabupaten Banyumas 53181",
                position: {lat: -7.449656, lng: 109.2584433}
            },
            {
                name: "DC Samarinda",
                phone: "+62 21 584 9070",
                email:"Jl. P. Suryanata, Komplek Samarinda Central Bizpark No. 2B-2C,  Kel. Bukit Pinang, Kec. Samarinda Ulu, Samarinda, Kalimantan Timur 75131",
                position: {lat: -0.45453, lng: 117.1098343}
            },
            {
                name: "DC Semarang",
                phone: "+62 21 584 9070",
                email:"kawasan industri candi, Jalan gatot subroto, tahap V blok A3 no 30",
                position: {lat: -7.0125086, lng: 110.3407975}
            },
            {
                name: "DC Solo",
                phone: "+62 21 584 9070",
                email:"Jalan Indronoto No. A3 Dusun II, Wirogunan, Kartasura, Kab. Sukoharjo 57178",
                position: {lat: -7.5401396, lng: 110.7290054}
            },
            {
                name: "DC Surabaya",
                phone: "+62 21 584 9070",
                email:"Jalan Rungkut Industri III No. 26, Kutisari, Tenggilis Mejoyo, Surabaya",
                position: {lat: -7.3348775, lng: 112.7505}
            },
            {
                name: "DC Tasikmalaya",
                phone: "+62 21 584 9070",
                email:"Jalan Ir. H. Juanda (By Pass) Km. 106 RT 003/ RW 015 Kel. Panglayungan Kec. Cipedes Kota Tasikmalaya (Depan kampus LP3I)",
                position: {lat: -7.3311004, lng: 108.2026583}
            },
            {
                name: "DC Tegal",
                phone: "+62 21 584 9070",
                email:"Jalan Rumkit No. 9, Tegal Wangi, RT 21/06, Talang, Tegal",
                position: {lat: -6.9054437, lng: 109.1329444}
            },
            {
                name: "DC Ternate",
                phone: "+62 21 584 9070",
                email:"Jalan Jati Lurus Kelurahan Jati Kecamatan Ternate Selatan Kota Ternate (Ruko Vellya warna Biru-Putih, Samping Gang TK/SD Vellya)",
                position: {lat: 0.7735396, lng: 127.3742596}
            },
            {
                name: "DC Ambon",
                phone: "+62 21 584 9070",
                email:"Kompleks Pergudangan Mars Blok D-3, Jl. Laksdya Leo Wattimena No.50, Negeri Lama, Desa passo, Kec Baguala, Kota Ambon, Maluku",
                position: {lat: -3.6252278, lng: 128.2438623}
            },
            {
                name: "DC Sukabumi",
                phone: "+62 21 584 9070",
                email:"Jl. Sejahtera, RT 002, RW 020. dayeuhluhur, Kec. Warudoyong, Sukabumi Regency, Jawa barat 43134",
                position: {lat: -6.9421395, lng: 106.9088277}
            },
            {
                name: "DC Rantau Prapat",
                phone: "+62 21 584 9070",
                email:"Jl. Adam Malik (By Pass) Lingkungan Aek Matio Pergudangan Kusuma Kel. Sirandorung, Kec. Rantau Utara, Labuhanbatu, Sumatra Utara 21414",
                position: {lat: 2.0868878, lng: 99.8243418}
            },
            {
                name: "DC Bukit Tinggi",
                phone: "+62 21 584 9070",
                email:"Jln.Raya Bukit Tinggi Medan KM 5 ,kenagarian Gaduik ,Kecamatan Tilatang Kamang, Kabupaten Agam, Bukit tinggi (depan Sanjai Fitri duo)",
                position: {lat: -0.5995449, lng: 100.0608692}
            },
        ]
    };

    onMarkerClick = (props, marker) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });
    }

    onInfoWindowClose = () => {
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });
    }

    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
    };

    render() {
        return (
            <Map
                className="map"
                google={this.props.google}
                onClick={this.onMapClicked}
                style={{height: '100%', position: 'relative', width: '100%'}}
                zoom={5}
                initialCenter={{
                    lat: -1.4181904,
                    lng: 108.3785394
                }}>

                {this.state.pinPoints.map(point => (
                    <Marker
                        name={point.name}
                        phone={point.phone}
                        email={point.email}
                        onClick={this.onMarkerClick}
                        position={point.position}
                        icon={{url: markerIcon}}
                    />
                ))}

                <InfoWindow
                    name="601 Lexinton Avenue, NY"
                    marker={this.state.activeMarker}
                    onClose={this.onInfoWindowClose}
                    visible={this.state.showingInfoWindow}>
                    <div className={'map-tooltip'}>
                        <img src={gmapMarkerBg} alt="GMAP" style={{height:"100px"}}/>
                        <h5 className="mt-3">{this.state.selectedPlace.name}</h5>
                        <h6 className='mb-0'>{this.state.selectedPlace.phone}</h6>
                        <h6>{this.state.selectedPlace.email}</h6>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyBZz9CKMF-nccXhnKeHXzM1VIxw4i_5gNk')
})(MapItemParagon);
