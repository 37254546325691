import React, {useState} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import ListItem from "../../components/list-core-value-paragon";
import circleOne from "../../assets/img/circle_one.png";
import circleTwo from "../../assets/img/circle_two.png";
import circleThree from "../../assets/img/circle_three.png";
import circleFour from "../../assets/img/circle_four.png";
import circleFive from "../../assets/img/circle_five.png";
import { useMediaQuery } from 'react-responsive'

const CoreValueDetailItem = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [listCoreValue] = useState([
        {
            listBullet: circleOne,
            title:"Faith in God",
            details:"Paragonian believes in the existence and power of God"
        },
        {
            listBullet: circleThree,
            title:"Humility",
            details:"Paragonian realizes that everyone has strengths and weaknesses"
        },
        {
            listBullet: circleFive,
            title:"Innovation",
            details:"Paragonian always develops new and better things to meet and exceed customer expectations"
        },
        {
            listBullet: circleTwo,
            title:"Care",
            details:"Paragonian upholds the value of togetherness and compassion"
        },
        {
            listBullet: circleFour,
            title:"Grit",
            details:"Paragonian lives a life with great joy and willpower"
        }
    ])
    const [listCoreValueMobile] = useState([
        {
            listBullet: circleOne,
            title:"Faith in God",
            details:"Paragonian believes in the existence and power of God"
        },
        {
            listBullet: circleTwo,
            title:"Care",
            details:"Paragonian upholds the value of togetherness and compassion"
        },
        {
            listBullet: circleThree,
            title:"Humility",
            details:"Paragonian realizes that everyone has strengths and weaknesses"
        },
        {
            listBullet: circleFour,
            title:"Grit",
            details:"Paragonian lives a life with great joy and willpower"
        },
        {
            listBullet: circleFive,
            title:"Innovation",
            details:"Paragonian always develops new and better things to meet and exceed customer expectations"
        }
    ])
    return (
        <div
            style={{backgroundColor:"#FFFFFF"}}
            className="section pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">

            <Container fluid={true} className="plr--180 plr_lg--100 plr_md--50 plr_sm--10 mt--70 mb--70" >
                <Row className="align-items-center" >
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-left align-items-center">
                            {isMobile ? null : <h3 style={{color:"#215AA8"}} className="mb--40">Our Core Values</h3> }
                            {isMobile ? 
                            <Row>
                                {listCoreValueMobile.map(coreValue => (
                                    <Col lg={4} className="mt--50">
                                        <div className="bk-list">
                                            <ListItem
                                                marker={'default'}
                                                bullet={coreValue.listBullet}
                                                text={coreValue.title}
                                                content={coreValue.details}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                
                            </Row>
                            : 
                            <Row>
                                {listCoreValue.map(coreValue => (
                                    <Col lg={4} className="mt--50">
                                        <div className="bk-list">
                                            <ListItem
                                                marker={'default'}
                                                bullet={coreValue.listBullet}
                                                text={coreValue.title}
                                                content={coreValue.details}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                
                            </Row>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CoreValueDetailItem;