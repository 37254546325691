import React, {Fragment, useEffect} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import { useParams } from 'react-router';
import ScholarshipDetailList from './ScholarshipDetailList';

const ScholarshipPage = () => {
    const [scholarshipType] = React.useState(useParams().scholarshipType)
    const [chosenScholarship, setChosenScholarship] = React.useState();
    const [listScholarship] = React.useState([
        {
            title: "Beasiswa Prestasi",
            qualification: [
                "Second year student from 9 main universities: UI, UGM, ITB, UNPAD, UB, UNDIP, IPB, Telkom University and ITS.",
                "GPA > 3.3",
                "Grantee are to be recommended by university according to their financial capacity",
                "Not receiving another scholarship",
                "Willing to be Paragon Agent (representative) to their respective university",
                "Committed to all Paragon Scholarship Program."
            ],
            details: [
                "Tuition fee until 4th year of Rp 6.250.000/semester.",
                "Development Program.",
                "Leadership Class.",
                "Social Project Challenge.",
                "Paragon Factory Visit (Grantee Prestasi)*",
                "Scholarship Camp (Grantee Prestasi)*",
                "Community Project Fund (maximum of Rp 50.000.000/university)*",
                "Paragon Close Recruitment"
            ]
        },
        {
            title: "Beasiswa Pemberdayaan",
            qualification: [
                "Second year student from 9 universities: UMJ, UNS, UNAIR, POLBAN, UINJAKARTA, ITERA, UNAND, UNTIRTA and UNSOED",
                "GPA > 3.3",
                "Grantee are to be recommended by university according to their financial capacity",
                "Not receiving another scholarship",
                "Willing to be Paragon Agent (representative) to their respective university",
                "Committed to all Paragon Scholarship Program."
            ],
            details: [
                "Tuition fee until 4th year of Rp 6.250.000/semester.",
                "Development Program.",
                "Leadership Class.",
                "Social Project Challenge.",
                "Paragon Factory Visit (Grantee Prestasi)*",
                "Scholarship Camp (Grantee Prestasi)*",
                "Community Project Fund (maximum of Rp 50.000.000/university)*",
                "Paragon Close Recruitment"
            ]
        },
        {
            title: "Beasiswa Tugas Akhir",
            qualification: [
                "4th year student from 9 main universities: UI, UGM, ITB, UNPAD, UB, UNDIP, IPB, Telkom University and ITS.",
                "GPA > 3",
                "Not receiving another scholarship",
                "Willing to be Paragon Agent (representative) to their respective university",
                "Committed to all Paragon Scholarship Program.",
                "Research proposal should be aligned with company's needs on selected themes",
                "Research should be concluded within 1 year (2 semesters)"
            ],
            details: [
                "Research grant up to Rp 5.000.000",
                "Paragon Close Recruitment",
                "Career Development and Guidance Program"
            ]
        }
    ])


    useEffect(() => {
        if(scholarshipType === 'Beasiswa Prestasi'){
            setChosenScholarship(listScholarship[0])
        } else if(scholarshipType === 'Beasiswa Pemberdayaan'){
            setChosenScholarship(listScholarship[1])
        } else if(scholarshipType === 'Beasiswa Tugas Akhir'){
            setChosenScholarship(listScholarship[2])
        }
    },[listScholarship, scholarshipType])

    return (
        <Fragment>
            <Helmet>
                <title>Scholarship Paragon</title>
            </Helmet>
            {chosenScholarship ? 
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={chosenScholarship.title}
                    bgImage={"bg-image-scholarship"}
                />
                
                <ScholarshipDetailList
                    title="Qualifications"
                    qualifications={chosenScholarship.qualification}/> 
                
                <ScholarshipDetailList
                    title="Details"
                    qualifications={chosenScholarship.details}/> 
                <FooterParagon/>
            </LayoutDefault>: null }
        </Fragment>
    );
}

export default ScholarshipPage