import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'
import ParagonCorp from "../../assets/img/paragon-corp.png"

const ParagonCorpLogo = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return (
        <div
            style={{backgroundColor:"#FFFFFF", minHeight:"45vh", display:"flex", alignItems:"center"}}
            className="section pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">

            <Container fluid={true} className="plr--180 plr_lg--100 plr_md--50 plr_sm--10" style={isBigScreen ? {height:"100%", width:"70%"} : isMobile ? {height:"100%", width:"100%", marginTop:"100px", marginBottom: "100px"} : {height:"100%", width:"80%", marginTop:"64px", marginBottom: "64px"}}>
                <Row className="align-items-center" style={{height:"100%"}}>
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-left align-items-center">
                            <img src={ParagonCorp} alt="Paragon Corp"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParagonCorpLogo;