import React from 'react';
import {Row, Col} from 'react-bootstrap';
import SlickSlider from "../../../components/slick";
import testimonialData from '../../../data/paraco-data';
import ParagonParacoItem from "../../../components/testimonial/ParagonParacoItem";
import Paraco from "../../../assets/img/mascot_paraco.png"
import { useMediaQuery } from 'react-responsive'

const ParagonParaco = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-right"/>
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-left"/>
            </button>
        );
    };

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        dots: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 756,
                settings: {
                    slidesToShow: 1,
                    centerMode: false
                }
            }
        ]
    };

    return (
        <div className="brook-testimonial-area" style={{backgroundColor:"#6A8AFC"}}>
            <Row className="row--0 align-items-center" style={isBigScreen || isMobile ? null : {maxHeight: "70vh"}}>
                <Col lg={4} className="ptb-md--80 ptb-sm--80 align-items-center" style={{textAlign:"center"}}>
                    <p className="text-white" style={isBigScreen ? {fontSize:"28px"}: {fontSize:"28px"}}>Let's meet <strong>ParaCo.</strong></p>
                    <div  style={{display: "flex", justifyContent: "center"}}>
                        <img src={Paraco} alt="paraco" style={isMobile ? {width:"60vw", height: "47vw"} : {width:"18vw", height: "14.1vw"}}/>
                    </div>
                </Col>
                <Col lg={8} className="ptb-md--80 ptb-sm--80">
                    <SlickSlider
                        settings={settings}
                        classes="brook-element-carousel slick-arrow-center slick-arrow-triangle slick-arrow-trianglestyle-2 testimonial-space-right testimonial-bg-red"
                    >
                        {testimonialData.map(testimonial => (
                            <ParagonParacoItem
                                key={testimonial.id}
                                data={testimonial}
                                classes={`hover-transparent space-large--topbottom ${testimonial.class}`}
                            />
                        ))}
                    </SlickSlider>
                </Col>
            </Row>
        </div>
    );
};

export default ParagonParaco;