import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import InnovationRedirectItem from "./InnovationRedirectItem";
import imageOrion from "../../assets/img/innovation-orion.jpg"
import imageRnd from "../../assets/img/bg/orion-image.jpg"


const InnovationRedirect = () => {
    return (
        <div className="bk-portfolio-area">

            <div className="portfolio-wrapper ptb--100 pl--150 pr--150 pl_lg--50 pr_lg--50 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
                <Container>
                    <Row className={"row--0 space_dec--110"}>

                        <Col lg={6}>
                            <InnovationRedirectItem
                                thumb={imageRnd}
                                float={'left'}
                                link={`${process.env.PUBLIC_URL + '/article'}`}
                                title={"Research & Development"}
                            />
                        </Col>

                        <Col lg={6}>
                            <InnovationRedirectItem
                                thumb={imageOrion}
                                link={"https://orion.paragon-innovation.com/orion/web/"}
                                float={'right'}
                                title={"Open Research & Innovation"}
                            />
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        </div>        
    );
};

export default InnovationRedirect;
