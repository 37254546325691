import React from "react";
import PropTypes from "prop-types";

const ServiceItemThree = ({ data }) => {
  return (
    <div
      className="architecture-service"
      data-image3={`${"img/slider/type/" + data.thumb}`}
    >
      <div className="architecture-inner">
        <div className="icon mb--25">
          <img
            src={require("../../assets/img/service/icon-box/" + data.icon)}
            alt={data.title}
            style={data.icon?.includes("emina") ? { marginLeft: "-24px" } : data.icon?.includes("earth") ? { marginLeft: "-32px" } : {}}
          />
        </div>
        <div className="content">
          <h4 className="heading heading-h4 text-white">{data.title}</h4>
          <div className="bk-separator--20" />
          <p className="bk_pra" style={{ minHeight: "25vh" }}>
            {data.description}
          </p>
          {data.website && (
            <div className="view-more-btn">
              <a href={data.website} target="_blank" rel="noopener noreferrer">
                <span>View More</span>{" "}
                <span
                  className="btn-arrow"
                  style={{ color: "white", width: "30px" }}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ServiceItemThree.propTypes = {
  data: PropTypes.object,
};

export default ServiceItemThree;
