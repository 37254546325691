import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import TermsAndConditionContent from './TermsAndConditionContent';
import {Container, Row, Col} from 'react-bootstrap';

const LegalInformationPage = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Terms &amp; Conditions - Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"TERMS & CONDITIONS"}
                    bgImage={"bg-image-brand"}
                />
                <div className="tabs-section section pt--80 pt-md--80 pt-sm--60 bg_color--1">
                    <Container>
                        <Row className="mb--50">
                            <Col xs={12}>
                                <TermsAndConditionContent/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default LegalInformationPage;