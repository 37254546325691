import React from 'react';
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap';
import PrivacyPolicyContent from './PrivacyPolicyContent';
import TermsAndConditionContent from './TermsAndConditionContent';

const LegalInformationTab = () => {
    return (
        <div className="tabs-section section pt--120 pt-md--80 pt-sm--60 bg_color--1">
            <Container>
                <Row className="mb--50">
                    <Col xs={12}>
                        <h3 className="mb--30 text-center" style={{fontFamily:"Montserrat"}}>PT Paragon Technology and Innovation Legal Information</h3>
                        <div className="tab" style={{padding: "0px"}}>
                            <Tab.Container id="pills-tab" defaultActiveKey="first">
                                <Nav variant="pills" className="mb-3" style={{justifyContent:"center"}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" style={{fontWeight:"bold"}}>Privacy Policy</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" style={{fontWeight:"bold"}}>Terms and Condition</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <PrivacyPolicyContent/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <TermsAndConditionContent/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default LegalInformationTab;
