import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";

import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import ParagonHomeSlider from "../../container/slider/ParagonHomeSlider";
import Brand from "../../container/service/ParagonBrand";
import BrandMobile from "../../container/service/ParagonBrandMobile";
import Footer from "../../container/footer/FooterParagon";
import About from "../../container/about-us/AboutParagon";
import ParagonPortofolio from "../../container/portfolio/homes/ParagonPortofolio";
import ParagonInNumber from "../../container/counter/ParagonInNumber";
import Testimonial from "../../container/testimonial/homes/ParagonTesti";
import Headline from "../../pages/news/NewsHeadline";
import { useMediaQuery } from 'react-responsive';

const HomeParagonWebsite = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <Fragment>
            <Helmet>
                <title>{`Paragon Technology and Innovation`}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2" revealFooter={false}>
                <Header/>
                <ParagonHomeSlider/>
                <About/>
                <div id="whitespace" style={{height:"128px"}}/>
                <ParagonInNumber/>
                <div id="whitespace" style={{height:"128px"}}/>
                {isMobile ? 
                    <BrandMobile/>
                :
                    <Brand/>
                }
                <div id="whitespace" style={{height:"128px"}}/>
                <ParagonPortofolio/>
                <div id="whitespace" style={{height:"128px"}}/>
                <Testimonial/>
                <Headline/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default HomeParagonWebsite;