import React, { useRef } from 'react';

const PrivacyPolicyContentID = () => {
    const mystyle = {
        color: 'blue'
    };

    const Ref1 = useRef()
    const Ref2 = useRef()
    const Ref3 = useRef()
    const Ref4 = useRef()
    const Ref5 = useRef()
    const Ref6 = useRef()
    const Ref7 = useRef()
    const Ref8 = useRef()
    const Ref9 = useRef()
    const Ref10 = useRef()
    const Ref11 = useRef()
    const Ref12 = useRef()
    const Ref13 = useRef()
    const Ref14 = useRef()
    const Ref15 = useRef()
    const Ref16 = useRef()
    const Ref17 = useRef()
    const Ref18 = useRef()
    const Ref19 = useRef()

    function handleClick1() {
        Ref1.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick2() {
        Ref2.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick3() {
        Ref3.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick4() {
        Ref4.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick5() {
        Ref5.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick6() {
        Ref6.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick7() {
        Ref7.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick8() {
        Ref8.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick9() {
        Ref9.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick10() {
        Ref10.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick11() {
        Ref11.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick12() {
        Ref12.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick13() {
        Ref13.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick14() {
        Ref14.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick15() {
        Ref15.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick16() {
        Ref16.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick17() {
        Ref17.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick18() {
        Ref18.current.scrollIntoView({ behavior: 'smooth' })
    }
    function handleClick19() {
        Ref19.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{ fontFamily: "Montserrat" }}>Kebijakan Privasi</h3>
            <p style={{ textAlign: "justify" }}>
                Selamat datang di situs resmi PT Paragon Technology
                And Innovation (yang selanjutnya disebut “Kami”).
                Kami berkomitmen untuk melindungi informasi pribadi
                Anda dan hak privasi Anda. Jika Anda memiliki
                pertanyaan, keluhan atau masalah tentang
                pemberitahuan privasi ini, atau pelaksanaan kami terkait
                dengan informasi pribadi Anda, silakan hubungi kami di
                customercare@paracorpgroup.com
                <br />
                <br />
                Ketika Anda mengunjungi situs web kami <a href='https://paragon-innovation.com/' target="_blank" rel="noopener noreferrer" style={mystyle}>https://www.paragoninnovation.com/</a> ("Situs Web"), dan secara lebih umum, menggunakan salah satu
                layanan kami ("Layanan", yang mencakup Situs Web),
                kami menghargai bahwa Anda mempercayai kami
                dengan informasi pribadi Anda. Kami menjaga privasi
                Anda dengan sangat serius. Dalam pemberitahuan
                privasi ini, kami berusaha menjelaskan kepada Anda
                dengan cara sejelas mungkin informasi apa yang kami
                kumpulkan, bagaimana kami menggunakannya, dan hak
                apa yang Anda miliki terkait dengannya. Kami harap
                Anda meluangkan waktu untuk membacanya dengan
                cermat, karena hal ini adalah penting. Jika ada
                persyaratan dalam pemberitahuan privasi ini yang tidak
                Anda setujui, anda bisa segera menghentikan
                penggunaan Layanan kami.
                <br />
                <br />
                Pemberitahuan privasi ini berlaku untuk semua
                informasi yang dikumpulkan melalui Layanan kami
                (yang, sebagaimana dijelaskan di atas, termasuk Situs
                Web kami), serta, setiap layanan, penjualan, pemasaran,
                atau acara terkait
                <br />
                <br />
            </p>
            <h4 style={{ fontFamily: "Montserrat" }}>
                RAUNG LINGKUP
            </h4>
            <p>Terakhir Diperbarui: 17 Januari 2023</p>
            <ul>
            <div>
                
            </div>
                <li onClick={handleClick1}>Informasi yang Kami kumpulkan langsung dari Anda</li>
                <li onClick={handleClick2}>Informasi tentang penggunaan Anda atas Layanan Kami</li>
                <li onClick={handleClick3}>Informasi yang Kami kumpulkan dari sumber lain</li>
                <li onClick={handleClick4}>Informasi yang Kami kumpulkan secara otomatis</li>
                <li onClick={handleClick5}>Jenis Cookie yang kami gunakan</li>
                <li onClick={handleClick6}>Informasi dan Tata cara pembayaran</li>
                <li onClick={handleClick7}>Layanan Pihak Ketiga</li>
                <li onClick={handleClick8}>Layanan Periklanan dan Analitik yang Disediakan oleh Pihak Ketiga</li>
                <li onClick={handleClick9}>Bagaimana kami menggunakan informasi Anda?</li>
                <li onClick={handleClick10}>Bagaimana kami dapat membagi informasi anda?</li>
                <li onClick={handleClick11}>Bagaimana Kami Melakukan Pemrosesan Terhadap Data Pribadi Anda</li>
                <li onClick={handleClick12}>Hak Anda</li>
                <li onClick={handleClick13}>Membatasi Cookie</li>
                <li onClick={handleClick14}>Anak-anak</li>
                <li onClick={handleClick15}>Tautan ke Situs Web Lain dan Konten Pihak Ketiga</li>
                <li onClick={handleClick16}>Bagaimana kami menyimpan data anda?</li>
                <li onClick={handleClick17}>Retensi Data</li>
                <li onClick={handleClick18}>Transfer Data Lintas Negara.</li>
                <li onClick={handleClick19}>Perubahan terhadap Kebijakan Privasi Kami</li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                Perlu diingat bagi Anda untuk sering melakukan
                pengecekan terhadap pembaharuan Kebijakan Privasi.
                Kami dapat melakukan pembaharuan Kebijakan Privasi
                ini dalam kurun waktu tertentu untuk
                mengimplementasikan perubahan terkait dengan
                Layanan atau perubahan pada peraturan perundangundangan yang berlaku.
                <br />
                <br />
            </p>
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref1}>
                Informasi yang Kami kumpulkan langsung dari Anda
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami mengumpulkan informasi yang Anda berikan
                langsung kepada Kami, seperti ketika Anda
                mendaftarkan akun di situs Web, situs Web perdagangan
                merek Kami, mendaftar ke buletin Kami, menyatakan
                minat pada voucher Kami, atau berinteraksi dengan
                Kami di media sosial. Jenis data pribadi yang dapat
                Kami kumpulkan langsung dari Anda meliputi:
            </p>
            <br />
            <p>Informasi Pribadi yang bersifat umum seperti:</p>
            <ul>
                <li>
                    Informasi kontak, seperti nama, jenis kelamin,
                    tanggal lahir, alamat email, nomor ponsel, akun
                    Instagram, dan profesi Anda;
                </li>
                <li>
                    Alamat, seperti nama jalan, area, kota, provinsi,
                    dan negara.
                </li>
            </ul>
            <br />
            <p>Informasi Pribadi yang bersifat khusus seperti:</p>
            <ul>
                <li>
                    informasi kulit, seperti jenis kulit, masalah kulit,
                    dan warna kulit Anda;
                </li>
                <li>
                    Informasi produk, seperti produk make-up yang
                    Anda miliki dan bagaimana Anda bisa tahu
                    produk Kami. Selain itu, kami juga
                    mengumpulkan data mengenai pembelian Anda
                    beserta <i>voucher</i> yang sering Anda gunakan
                    untuk membeli produk di <i>Brand Commerce</i> resmi kami; dan
                </li>
                <li>
                    Informasi lain yang Anda pilih untuk diberikan.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref2}>
                Informasi tentang penggunaan Anda atas Layanan Kami
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami mengumpulkan informasi tentang penggunaan
                Layanan yang Anda lakukan, seperti: membeli produk
                Kami di situs Web perdagangan merek resmi Kami, atau
                produk yang Anda minati. Kami juga mengumpulkan
                Informasi lain tentang penggunaan Anda atas Layanan,
                seperti aplikasi yang digunakan, situs Web yang
                dikunjungi, dan cara interaksi Anda dengan kontenkonten penawaran Kami melalui Layanan.
            </p>
            <p style={{ textAlign: "justify" }}>
                Jika Anda membeli salah satu produk kami dari <i>Brand Commerce</i> resmi Kami (
                <a href='http://wardahbeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}>Wardah</a>,
                <a href='https://www.makeoverforall.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Make Over</a>,
                <a href='https://www.eminacosmetics.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Emina</a>,
                <a href='https://ww.kahfeveryday.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Kahf</a>,
                <a href='https://crystallurebeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Crystallure</a>,
                <a href='https://shop.wardahbeauty.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Instaperfect</a>,
                <a href='https://www.tavi-world.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Tavi</a>,
                <a href='https://www.laboreskinexpert.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Labore</a>,
                <a href='https://biodef.co.id/' target="_blank" rel="noopener noreferrer" style={mystyle}> Biodef</a>,
                <a href='https://www.wonderlyskin.com/' target="_blank" rel="noopener noreferrer" style={mystyle}> Wonderly</a>
                ), Kami mengumpulkan data pesanan
                pembelian Anda, seperti: produk apa yang dibeli, jumlah
                produk, total harga pesanan, dan <i>voucher</i> yang Anda
                gunakan dalam transaksi; Kami juga dapat
                mengumpulkan data pribadi Anda seperti nama, email,
                dan nomor telepon Anda; dan alamat Anda sebagai
                informasi yang diperlukan untuk mengirimkan pesanan
                Anda.
            </p>
            <p style={{ textAlign: "justify" }}>
                Jika Anda menggunakan fitur berbagi kami untuk
                menginformasikan produk ke teman-teman Anda, kami
                akan bisa mengumpulkan informasi tersebut. Selain itu,
                Kami juga dapat mengumpulkan informasi lain tentang
                Anda seperti perangkat Anda atau penggunaan Anda
                atas Layanan dengan persetujuan Anda untuk
                mengirimkannya kepada Kami.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref3}>
                Informasi yang Kami kumpulkan dari sumber lain
            </h4>
            <p style={{ textAlign: "justify" }}>
                Saat Anda mengakses Layanan melalui jejaring sosial,
                Kami mengumpulkan informasi dari jejaring sosial
                sesuai dengan pengaturan Anda di jejaring sosial. Jika
                Anda berinteraksi dengan Kami di media sosial, Kami
                akan mengumpulkan informasi tentang interaksi
                tersebut. Informasi yang Kami kumpulkan meliputi
                nama Anda, gambar, pegangan media sosial atau nama
                pengguna, dan alamat email yang sesuai dengan media
                sosial. Kami juga dapat mengumpulkan informasi
                tentang Anda dari pihak ketiga, termasuk sumber yang
                tersedia untuk umum atau teman yang membagikan
                produk Kami di saluran berbagi mereka.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref4}>
                Informasi yang Kami kumpulkan secara otomatis
            </h4>
            <p style={{ textAlign: "justify" }}>
                Ketika Anda mengunjungi situs Kami, Kami
                mengumpulkan informasi tertentu secara otomatis.
                Untuk mengumpulkan informasi ini, Kami dapat
                menggunakan cookie dan teknologi serupa. “<i>Cookie</i>”
                adalah file teks yang dikirim situs Web ke komputer
                pengunjung atau perangkat lain yang terhubung ke
                internet untuk secara unik mengidentifikasi <i>browser</i>
                pengunjung atau untuk menyimpan informasi atau
                pengaturan di <i>browser</i>.
            </p>
            <p style={{ textAlign: "justify" }}>
                <i>Cookie</i> adalah string kecil berupa karakter yang
                digunakan oleh banyak situs Web untuk mengirimkan
                data ke perangkat Anda dan dalam kurun waktu tertentu
                dapat mengembalikan informasi ke situs Web tersebut.
                Kami juga dapat mengumpulkan informasi tentang
                aktivitas online Anda dari waktu ke waktu.
            </p>
            <ul>
                <li>Membuat Anda tetap masuk ke brandcommerce</li>
                <li>URL yang merujuk pengunjung ke situs Web Kami;</li>
                <li>Istilah pencarian yang digunakan untuk menjangkau situs Web Kami;</li>
                <li>Detail tentang perangkat yang digunakan untuk
                    mengakses situs Web Kami (seperti: alamat IP,
                    informasi <i>browser</i>, informasi perangkat, dan
                    informasi sistem operasi);</li>
                <li>Detail tentang interaksi Anda dengan situs Web
                    Kami (seperti: tanggal, waktu, lama mengakses
                    situs Kami, halaman spesifik yang diakses
                    selama kunjungan Anda ke situs Web Kami, dan
                    email pemasaran yang Anda buka); dan</li>
                <li>Informasi penggunaan (seperti: jumlah dan
                    frekuensi pengunjung ke situs Web Kami).</li>
            </ul>
            <p ref={Ref5}>Jenis cookie apa yang kami gunakan?</p>
            <p>Ada beberapa jenis cookie yang berbeda, namun, situs web kami menggunakan:</p>
            <p style={{ textAlign: "justify" }}><b style={{ fontSize: "15px" }}>Fungsionalitas</b> – Perusahaan kami menggunakan
                cookie ini sehingga kami mengenali Anda di
                situs web kami dan mengingat preferensi yang
                Anda pilih sebelumnya. Ini dapat mencakup
                bahasa apa yang Anda sukai dan lokasi Anda
                berada. Campuran cookie pihak pertama dan
                pihak ketiga digunakan.
            </p>
            <p style={{ textAlign: "justify" }}><b style={{ fontSize: "15px" }}>Periklanan</b> – Perusahaan kami menggunakan cookie
                ini untuk mengumpulkan informasi tentang
                kunjungan Anda ke situs web kami, konten yang
                Anda lihat, tautan yang Anda ikuti, dan
                informasi tentang browser, perangkat, dan
                alamat IP Anda. Perusahaan kami terkadang
                membagikan beberapa aspek terbatas dari data
                ini dengan pihak ketiga untuk tujuan periklanan.
                Kami juga dapat membagikan data online yang
                dikumpulkan melalui cookie dengan mitra
                periklanan kami. Ini berarti bahwa ketika Anda
                mengunjungi situs web lain, Anda mungkin
                diperlihatkan iklan berdasarkan pola
                penjelajahan Anda di situs web kami.
            </p>
            <p style={{ textAlign: "justify" }}>
                Kami dapat mengaitkan informasi ini dengan buletin
                Anda jika Anda berlangganan, perangkat yang Anda
                gunakan untuk terhubung ke Layanan Kami, dan email
                atau akun media sosial yang Anda gunakan untuk
                terhubung dengan Kami.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref6}>
                Informasi Pembayaran
            </h4>
            <p style={{ textAlign: "justify" }}>
                Jika Anda membeli produk Kami dari situs resmi <i>Brand
                    Commerce</i> Kami, maka untuk menyelesaikan pesanan,
                Anda harus melakukan pembayaran melalui metode
                pembayaran terintegrasi Kami. Kami bekerja sama
                dengan Xendit, penyedia <i>gateway</i> pembayaran, yang
                akan mengumpulkan informasi pembayaran Anda
                sesuai metode yang Anda pilih. Semua data Anda akan
                dienkripsi melalui Standar Keamanan Data Industri
                Kartu Pembayaran (PCI-DSS).
            </p>
            <p style={{ textAlign: "justify" }}>
                Semua <i>gateway</i> pembayaran langsung mematuhi
                standar yang ditetapkan oleh PCI-DSS yang dikelola
                oleh PCI Security Standards Council, yang merupakan
                upaya bersama dari merek-merek seperti Visa,
                MasterCard, American Express dan Discover. Dan
                Xendit sudah memenuhi standar ini. Persyaratan PCIDSS membantu
                memastikan penanganan aman informasi kartu kredit oleh toko <i>Brand
                    Commerce</i>
                resmi Kami dan penyedia layanannya. Untuk informasi lebih
                lanjut, Anda mungkin juga ingin membaca Ketentuan
                Layanan Xendit di sini atau Pernyataan Privasi di sini.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref7}>
                Layanan Pihak Ketiga
            </h4>
            <p style={{ textAlign: "justify" }}>
                Secara umum, penyedia pihak ketiga yang digunakan
                oleh Kami hanya akan mengumpulkan, menggunakan,
                dan mengungkapkan informasi Anda sejauh yang
                diperlukan untuk memungkinkan mereka melakukan
                layanan yang mereka berikan kepada Kami.
            </p>
            <p style={{ textAlign: "justify" }}>
                Namun, penyedia layanan pihak ketiga tertentu
                memiliki kebijakan privasi mereka sendiri sehubungan
                dengan informasi yang Kami harus berikan kepada
                mereka untuk transaksi terkait pembelian Anda. Untuk
                penyedia ini, Kami sarankan Anda membaca kebijakan
                privasi mereka sehingga Anda dapat memahami cara di
                mana informasi pribadi Anda akan ditangani oleh
                penyedia ini.
            </p>
            <p style={{ textAlign: "justify" }}>
                Kami juga bekerja sama dengan penyedia Pengiriman
                dan Mitra Logistik lainnya, yang memiliki kebijakan
                privasi mereka sendiri sehubungan dengan informasi
                yang kami perlukan untuk menyediakannya untuk
                transaksi terkait pengiriman Anda. Setelah Anda
                meninggalkan situs Web toko Kami atau diarahkan ke
                situs Web atau aplikasi pihak ketiga, Anda tidak lagi
                diatur oleh Kebijakan Privasi ini atau Ketentuan
                Layanan situs Web Kami.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref8}>
                Layanan Periklanan dan Analitik yang Disediakan oleh Pihak Ketiga
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami dapat mengizinkan orang lain untuk menyediakan
                layanan analitik dan menayangkan iklan atas nama
                Kami di internet dan dalam aplikasi seluler. Entitas ini
                dapat menggunakan cookie dan teknologi lainnya untuk
                mengumpulkan informasi tentang penggunaan Layanan
                oleh Anda dan situs Web dan aplikasi lainnya, misalnya:
                alamat IP, <i>browser</i> Web, informasi jaringan seluler,
                halaman yang dilihat, halaman, waktu yang dihabiskan
                pada halaman atau dalam aplikasi, dan tautan yang
                diakses. Informasi ini dapat digunakan oleh Kami; dan
                penggunaannya tetapi tidak terbatas pada menganalisis
                dan melacak data, tapi juga untuk menentukan
                popularitas produk atau konten tertentu, mengirimkan
                iklan dan konten yang ditargetkan untuk minat Anda di
                Layanan Kami dan situs Web lain, dan memiliki
                pemahaman yang lebih baik aktivitas online Anda.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref9}>
                Bagaimana Kami Menggunakan Informasi Anda?
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami dapat menggunakan informasi yang Kami
                kumpulkan untuk mengirimkan produk yang Anda beli
                di situs perdagangan merek resmi Kami, meningkatkan
                pengalaman Anda saat menjelajahi situs Web Kami,
                menyesuaikan buletin langganan Anda dan juga
                interaksi Kami dengan Anda, serta untuk menyediakan,
                memelihara, dan meningkatkan pelayanan Kami. Kami
                juga dapat menggunakan informasi yang Kami
                kumpulkan untuk:
            </p>
            <ul>
                <li>
                    Berkomunikasi dengan Anda tentang Layanan
                    Kami, termasuk untuk memberitahu Anda
                    tentang produk dan layanan yang mungkin
                    menarik bagi Anda;
                </li>
                <li>
                    Memfasilitasi keterlibatan Anda dengan
                    Layanan, termasuk memungkinkan Anda untuk
                    mengirim komentar dan ulasan produk;
                </li>
                <li>
                    Memberi tahu Anda tentang tanggal peluncuran
                    produk baru Kami, informasi produk baru, dan
                    informasi terkait merek;
                </li>
                <li>
                    Memberi penawaran promosi dan giveaway; dan
                </li>
                <li>
                    Melakukan atau mengelola survei dan riset pasar
                    lainnya.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref10}>
                Bagaimana Kami dapat Membagi Informasi Anda?
            </h4>
            <p>
                Kami dapat membagikan informasi yang Kami
                kumpulkan ke:
            </p>
            <ul>
                <li>
                    Dengan mitra terpilih sebagai bagian dari
                    promosi yang disponsori bersama;
                </li>
                <li>
                    Untuk mematuhi kewajiban hukum atau
                    peraturan, melindungi, dan membela hak atau
                    properti kami, melindungi keselamatan
                    pelanggan dan pengguna situs Web kami atau
                    publik, dan melindungi terhadap tanggung jawab
                    hukum;
                </li>
                <li>
                    Sehubungan dengan, atau selama proses
                    negosiasi, merger, penjualan aset perusahaan,
                    pembiayaan, atau akuisisi semua atau sebagian
                    dari bisnis Kami oleh perusahaan lain;
                </li>
                <li>
                    Antara dan di antara afiliasi Kami saat ini dan
                    yang akan datang, anak perusahaan, dan
                    perusahaan lain di bawah kendali dan
                    kepemilikan yang sama; dan
                </li>
                <li>
                    Saat Anda memberikan ulasan produk atau
                    konten lain, konten itu akan disortir oleh tim
                    Kami dan ulasan produk yang dipilih akan
                    diposkan ditampilkan secara publik. Pengunjung
                    situs Web lain mungkin dapat melihat nama
                    Anda atau informasi lain tentang Anda yang
                    Anda poskan posting, seperti akun media sosial
                    atau nama pengguna Anda.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref11}>
                Bagaimana Kami Melakukan Pemrosesan Terhadap Data Pribadi Anda
            </h4>
            <p>
                Untuk melindungi hak-hak Anda dalam rangkaian
                pemrosesan data pribadi, Kami memperhatikan
                ketentuan-ketentuan sebagai berikut demi kenyamanan
                Anda:
            </p>
            <ul>
                <li>
                    Kami akan meminta kepada Anda persetujuan
                    yang sah secara eksplisit untuk 1 (satu) atau
                    beberapa tujuan tertentu yang telah disampaikan
                    oleh Kami kepada Anda;
                </li>
                <li>
                    Begitu Anda memberikan persetujuan kepada
                    Kami, Kami akan menyampaikan kepada Anda
                    mengenai legalitas dari pemrosesan Data
                    Pribadi; tujuan pemrosesan Data Pribadi; jenis
                    dan relevansi Data Pribadi yang akan diproses;
                    jangka waktu retensi dokumen yang memuat
                    Data Pribadi; rincian mengenai informasi yang
                    dikumpulkan; jangka waktu pemrosesan Data
                    Pribadi; dan hak Anda sebagai subjek Data
                    Pribadi;
                </li>
                <li>
                    Kami akan melakukan pemrosesan Data Pribadi
                    Anda secara terbatas dan spesifik, sah secara
                    hukum, dan transparan;
                </li>
                <li>
                    Kami akan memastikan akurasi, kelengkapan,
                    dan konsistensi Data Pribadi Anda sesuai dengan
                    peraturan perundang-undangan yang berlaku;
                </li>
                <li>
                    Kami akan memberikan akses kepada Anda
                    terhadap Data Pribadi yang diproses serta rekam
                    jejak pemrosesan Data Pribadi sesuai dengan
                    jangka waktu penyimpanan Data Pribadi;
                </li>
                <li>
                    Dalam melakukan pemrosesan Data Pribadi
                    Anda, Kami menjamin kerahasiaan Data Pribadi
                    yang Anda sampaikan;
                </li>
                <li>
                    Kami akan melakukan pengawasan terhadap
                    setiap pihak yang terlibat dalam pemrosesan
                    Data Pribadi Anda di bawah kendali Kami;
                </li>
                <li>
                    Kami akan melakukan yang terbaik dan
                    semaksimal mungkin untuk memastikan Data
                    Pribadi Anda terhindar dari pemrosesan dan
                    pengaksesan yang tidak sah;
                </li>
                <li>
                    Kami bertanggungjawab atas pemrosesan Data
                    Pribadi dan menunjukkan pertanggungjawaban
                    dalam pemenuhan kewajiban pelaksanaan
                    prinsip perlindungan Data Pribadi;
                </li>
                <li>
                    Dalam melakukan pemrosesan Data Pribadi,
                    Kami akan menyusun dan menerapkan langkah
                    teknis operasional untuk melindungi Data
                    Pribadi dari gangguan pemrosesan Data Pribadi
                    yang bertentangan dengan peraturan perundangundangan yang berlaku; serta menentukan
                    tingkat keamanan Data Pribadi Anda dengan
                    memperhatikan sifat dan risiko dari Data Pribadi
                    yang harus dilindungi dalam pemrosesan Data
                    Pribadi, dan
                </li>
                <li>
                    Dalam hal terjadi sesuatu yang tidak Kami
                    inginkan seperti kegagalan perlindungan Data
                    Pribadi, Kami akan menyampaikan secara
                    tertulis kepada Anda dan lembaga dalam kurun
                    waktu paling lambat 3 x 24 (tiga kali dua puluh
                    empat) jam.
                </li>
            </ul>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref12}>
                HAK ANDA
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami mengikuti ketentuan hukum terkait dengan
                Perlindungan Data Pribadi di Indonesia, Perusahaan
                kami ingin memastikan bahwa Anda sepenuhnya
                mengetahui semua hak perlindungan data Anda. Setiap
                pengguna berhak atas hal-hal berikut:
            </p>
            <ol>
                <li>
                    Mendapatkan informasi tentang tujuan
                    pengumpulan/pengolahan data dan pengumpul data,
                    termasuk di dalamnya Pihak Ketiga yang terkait.
                </li>
                <li>
                    Untuk memperbarui, mengubah, dan menghapus data
                    pribadinya
                </li>
                <li>
                    Untuk mendapatkan akses dan salinan data pribadinya
                </li>
                <li>
                    Untuk mengakhiri pemrosesan, hapus dan/atau
                    musnahkan
                </li>
                <li>
                    Mencabut persetujuan yang telah diberikan
                </li>
                <li>
                    Untuk menolak pemrosesan data otomatis, termasuk
                    pembuatan profil
                </li>
                <li>
                    Untuk membatasi pemrosesan/profil
                </li>
                <li>
                    Menuntut dan mendapatkan ganti rugi atas
                    pelanggaran pengolahan data
                </li>
            </ol>
            <p style={{ textAlign: "justify" }}>
                Jika Anda mengajukan permintaan, kami memiliki
                waktu 3x24 jam untuk menanggapi dan memenuhi
                permintaan Anda. Jika Anda ingin menggunakan salah
                satu dari hak-hak ini, silakan hubungi kami di email
                kami:
            </p>
            <p>Hubungi kami di:</p>
            <p>PT Paragon Technology and Innovation<br></br>Jalan Swadharma Raya Kampung Baru IV no 1, Ulujami, Pesanggahan, Jakarta Selatan - 12250</p>
            <br />
            <p>Email : customercare@paracorpgroup.com<br></br>
                Phone : 08041401123<br></br>
                WA : 087701123000<br></br></p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref13}>
                Membatasi Cookie
            </h4>
            <p style={{ textAlign: "justify" }}>
                Sebagian besar <i>browser</i> Web diatur untuk menerima
                cookie secara default. Anda biasanya dapat memilih
                untuk mengatur <i>browser</i> Anda untuk menghapus atau
                menolak cookie <i>browser</i>. Harap diperhatikan bahwa jika
                Anda memilih untuk menghapus atau menolak cookie,
                hal ini dapat mempengaruhi ketersediaan dan
                fungsionalitas situs Web kami.
            </p>
            <p style={{ textAlign: "justify" }}>
                Anda dapat mengkustomisasi <i>browser</i> Web Anda untuk
                menerima atau menolak semua cookie, dan
                memberitahu Anda apabila terdapat cookie yang
                dikirim. Setiap <i>browser</i> Web berbeda dalam mengubah
                preferensi cookie, sehingga menonaktifkan cookie dapat
                mempengaruhi Anda dalam menggunakan layanan
                Kami baik sebagian atau seluruhnya.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref14}>
                Anak-anak
            </h4>
            <p style={{ textAlign: "justify" }}>
                Layanan Kami tidak dirancang untuk anak-anak dan
                tidak secara sengaja mengumpulkan Data Pribadi dari
                anak-anak (di bawah 12 tahun). Jika Anda memiliki
                alasan untuk meyakini bahwa seorang anak telah
                memberikan Data Pribadi kepada Kami, silahkan
                hubungi Kami di customercare@paracorpgroup.com
                dan Kami akan memeriksa kembali fakta-fakta dan
                menghapus informasi itu dari basis data Kami jika itu
                terbukti sebagai data anak-anak
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref15}>
                Tautan ke Situs Web Lain dan Konten Pihak Ketiga
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami dapat menyediakan tautan ke situs Web pihak
                ketiga, layanan, dan aplikasi, seperti Instagram dan situs
                Web perdagangan lainnya, yang tidak dioperasikan atau
                dikendalikan oleh Kami. Kebijakan Privasi ini tidak
                berlaku untuk layanan pihak ketiga, dan kami tidak
                dapat bertanggung jawab atas konten, kebijakan privasi,
                atau praktik layanan pihak ketiga. Kami mendorong
                Anda untuk meninjau kebijakan privasi layanan pihak
                ketiga mana pun sebelum memberikan informasi apapun
                kepada atau melalui mereka. Interaksi Anda dengan
                fitur-fitur dari pihak ketiga ini diatur oleh kebijakan
                privasi layanan pihak ketiga yang menyediakan fitur
                tersebut.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref16}>
                Penyimpanan Data
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami telah menerapkan langkah-langkah keamanan
                teknis dan organisasi yang sesuai yang dirancang untuk
                melindungi keamanan informasi pribadi apa pun yang
                kami proses. Namun, terlepas dari pengamanan dan
                upaya kami untuk mengamankan informasi Anda, tidak
                ada transmisi elektronik melalui Internet atau teknologi
                penyimpanan informasi yang dapat dijamin 100% aman,
                jadi kami tidak dapat menjanjikan atau menjamin bahwa
                peretas, penjahat dunia maya, atau pihak ketiga tidak sah
                lainnya tidak akan mampu mengalahkan keamanan
                kami, dan mengumpulkan, mengakses, mencuri, atau
                mengubah informasi Anda secara tidak semestinya.
                Meskipun kami akan melakukan yang terbaik untuk
                melindungi informasi pribadi Anda, transmisi informasi
                pribadi ke dan dari Situs Web kami adalah risiko Anda
                sendiri. Anda hanya boleh mengakses Situs Web dalam
                lingkungan yang aman.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref17}>
                Retensi Data
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami hanya akan menyimpan informasi pribadi Anda
                selama diperlukan untuk tujuan yang ditetapkan dalam
                pemberitahuan privasi ini, kecuali periode penyimpanan
                yang lebih lama diperlukan atau diizinkan oleh hukum
                (seperti pajak, akuntansi, atau persyaratan hukum
                lainnya). Tidak ada tujuan dalam pemberitahuan ini
                yang mengharuskan kami menyimpan informasi pribadi
                Anda selama lebih dari tiga (3) bulan setelah
                penghentian akun pengguna.
            </p>
            <p style={{ textAlign: "justify" }}>
                Ketika kami tidak memiliki kebutuhan bisnis yang sah
                untuk memproses informasi pribadi Anda, kami akan
                menghapus atau menganonimkan informasi tersebut,
                atau, jika hal ini tidak memungkinkan (misalnya, karena
                informasi pribadi Anda telah disimpan dalam arsip
                cadangan), maka kami akan dengan aman menyimpan
                informasi pribadi Anda dan mengisolasinya dari
                pemrosesan lebih lanjut hingga penghapusan dapat
                dilakukan
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref18}>
                Transfer Data Lintas Negara.
            </h4>
            <p>
                Kami juga beroperasi di negara lain.
            </p>
            <p style={{ textAlign: "justify" }}>
                Data Pribadi Anda juga dapat ditransfer ke entitas lain
                di luar Indonesia. Kami akan mematuhi hukum di luar
                Indonesia terkait perlindungan data pribadi.
            </p>
            <br />
            <br />
            <h4 style={{ fontFamily: "Montserrat" }} ref={Ref19}>
                Perubahan terhadap Kebijakan Privasi Kami
            </h4>
            <p style={{ textAlign: "justify" }}>
                Kami dapat mengubah Kebijakan Privasi ini dari waktu
                ke waktu. Jika Kami melakukannya, Kami akan
                memposting kebijakan yang diperbaharui di situs Kami
                dan akan menunjukkan kapan Kebijakan Privasi terakhir
                direvisi. Kami juga akan mengirimkan pemberitahuan
                email kepada Anda bahwa Kebijakan Privasi Kami
                baru-baru ini berubah. Anda harus secara berkala
                meninjau Kebijakan Privasi Kami saat ini untuk tetap
                mendapat informasi tentang praktik pemrosesan Data
                Pribadi oleh Kami.
            </p>
            <br />
            <br />
        </>
    )
}
export default PrivacyPolicyContentID;