import React, {useState, useRef, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.scss'
import testimonialData from "../../../data/history-paragon";
import ParagonHistoryYear from "../../../components/testimonial/ParagonHistoryYear";
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { useMediaQuery } from 'react-responsive';

const ParagonHistory = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [currentSlide, setCurrentSlide] = useState(0)

    const [thumbnailNav, setThumbnailNav] = useState(null);
    const [quoteNav, setQuoteNav] = useState(null);
    let thumbnailSlider = useRef(null);
    let quoteSlider = useRef(null);


    useEffect(() => {
       testimonialData[-1] = "" 
    },[])

    useEffect(() => {
        setThumbnailNav(thumbnailSlider);
        setQuoteNav(quoteSlider);
    }, [thumbnailSlider, quoteSlider]);

    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i style={{color:"#215AA8"}} className="fa fa-angle-right"/>
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i style={{color:"#215AA8"}} className="fa fa-angle-left"/>
            </button>
        );
    };

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        asNavFor: thumbnailNav
    };

    const thumbSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        speed:600,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        focusOnSelect: true,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '0px',
        asNavFor: quoteNav,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const afterChangeHandler = (currentSlide) => {
        setCurrentSlide(currentSlide)
    }

    const beforeChangeHandler = (lastSlide) => {
        setCurrentSlide(-1)
    }

    return (
        <div
            className="brook-testimonial-area ptb--150 ptb-md--80 ptb-sm--60 slick-arrow-hover"
            style={{backgroundColor: "white"}}
        >
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="testimonial-wrapper testimonial-active--single--arrow">
                            <div className="testimonial-container pb--60">
                                <div className="testimonial testimonial--creative">
                                    <h3 style={{textAlign: "center", marginBottom: "2em",color:"#215AA8", fontFamily:"Montserrat"}}>History</h3>
                                    <Slider
                                        {...thumbSettings}
                                        ref={slider => {
                                            thumbnailSlider = slider
                                        }}
                                        afterChange={afterChangeHandler}
                                        beforeChange={beforeChangeHandler}
                                        className={"brook-element-carousel slick-arrow-center nav-thumb paragon-history-fixed-width testimonial-nav-style testimonial-nav--2"}
                                    >
                                        {testimonialData.map(testimonial => (
                                            <ParagonHistoryYear
                                                key={testimonial.id}
                                                data={testimonial}
                                            />
                                        ))}
                                    </Slider>

                                    <ReactCSSTransitionReplace 
                                                transitionName="fade-wait"
                                                transitionEnterTimeout={600}
                                                transitionLeaveTimeout={600}>
                                                <div className="content" key={currentSlide}>
                                                    <div className="brand-info" style={{display:"flex", justifyContent:"center"}}>
                                                        <div className="brand" style={isMobile ? {width:"100%", position: "relative", minHeight:"200px", textAlign:"center", display:"flex", justifyContent:"center"} : {width:"50%", position: "relative", minHeight:"200px", textAlign:"center", display:"flex", justifyContent:"center"}}>
                                                            <p style={{color:"#215AA8", fontStyle:"unset", fontSize:"18px", margin: "0",position: "absolute", top: "50%",msTransform: "translateY(-50%)",transform: "translateY(-50%)"}}>{testimonialData[currentSlide].quote}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                    </ReactCSSTransitionReplace>

                                    <Slider
                                        {...settings}
                                        ref={slider => {
                                            quoteSlider = slider
                                        }}
                                        className={'brook-element-carousel nav-content slick-arrow-center slick-dots-bottom testimonial-nav-content'}
                                    >
                                        
                                        {testimonialData.map(testimonial => (
                                            <>
                                            
                                            </>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParagonHistory;