import React from 'react';
import {Row, Col} from 'react-bootstrap';
import SectionTitle from "../../../components/section-title/SectionTitleOne";
import SlickSlider from "../../../components/slick";
import testimonialData from '../../../data/csr-paragon';
import TestimonialItemOne from "../../../components/testimonial/ParagonTestiItem";
import { useMediaQuery } from 'react-responsive'

const ParagonTesti = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-right"/>
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`slick-btn ${className}`} onClick={onClick}>
                <i className="fa fa-angle-left"/>
            </button>
        );
    };

    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        dots: false,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        responsive: [
            {
                breakpoint: 756,
                settings: {
                    slidesToShow: 1,
                    centerMode: false
                }
            }
        ]
    };

    return (
        <div className="brook-testimonial-area" style={{backgroundColor:"#215AA8"}}>
            <Row className="row--0 align-items-center" style={isBigScreen || isMobile ? null : {maxHeight: "100vh"}}>
                <Col lg={4} className="ptb-md--80 ptb-sm--80">
                    <SectionTitle
                        heading={`What <br>People Say<br> About Us`}
                        headingColor={'#fff'}
                        wrapperClass={'d-flex justify-content-center'}
                    />
                </Col>

                <Col lg={8}>
                    <SlickSlider
                        settings={settings}
                        classes="brook-element-carousel slick-arrow-center slick-arrow-triangle slick-arrow-trianglestyle-2 testimonial-space-right paragon-testi-color-variation testimonial-bg-red"
                    >
                        {testimonialData.map(testimonial => (
                            <TestimonialItemOne
                                key={testimonial.id}
                                data={testimonial}
                                classes={"hover-transparent space-large--topbottom bg-dark"}
                            />
                        ))}
                    </SlickSlider>
                </Col>
            </Row>
        </div>
    );
};

export default ParagonTesti;