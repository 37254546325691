import React, {Fragment, useCallback, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon"
import Input from "../../components/input";
import {Row, Col, Container} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';

const SearchResult = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [searchFor, setSearchFor] = useState(new URLSearchParams(window.location.search).get('q'));
    const [fuzzyScore] = useState(75)
    const [listPage] = useState([
        {
            title:"About Us",
            desc:"Paragon is an Indonesian beauty company with the purpose of creating greater good for the society through innovation.",
            keywords:["pti","paragon", "about", "about us", "tentang", "paraco", "lokasi"],
            link: "/about-us"
        },
        {
            title:"Brand",
            desc:"Wardah is a pioneer of halal cosmetics in Indonesia that combines natural purity with a halal, modern, and reliable process. For Make Over, there is no limit in defining your beuaty. For Emina, every day is a new opportunity to be greeted with enthusiasm and passion. Kahf is a halal grooming brand for men and inspired by nature inspired by nature with natural, halal, and dermatologically tested ingredients.",
            keywords:["brand", "wardah", "emina", "make over", "kahf", "merk", "produk", "product"],
            link: "/brand"
        },
        {
            title:"Innovation",
            desc:"Some of us may think that innovation should be something that breaks through and changes the world instantly, and this mindset makes us reluctant to initiate or feel unable to innovate. For Paragon, every small action that aims to make a change for the better is an innovation, because every small step will make a big change if it is done consistently. Innovation starts from a mindset that is translated to action and will provide a greater good for our surrounding.",
            keywords:["innovation", "inovasi", "riset", "research", "rnd", "produk", "product", "wardah", "emina", "make over", "kahf"],
            link: "/innovation"
        },
        {
            title:"CSR",
            desc:"Education is the foundation for building a nation. Paragon is committed to making a better education in Indonesia. Health is the basic to build the welfare of society. Improving the quality of health is one of the commitments that Paragon continues to pursue. Women are basically powerful, Paragon is committed to developing the capacity and skills of women in Indonesia to be more empowered together. In carrying out its commitment to preserving the earth, Paragon collaborates with organizations in the environmental sector",
            keywords:["csr", "social", "sosial", "bantuan", "corporate", "bantuan"],
            link: "/csr"
        },
        {
            title:"Career",
            desc:"PT Paragon Technology and Innovation is built with the belief in the spirit of young people to open up to each other and to build every process. Paragon provides creative freedom by encouraging every element to give the best performance with full responsibility. Along with the soul of collaboration and exploration, Paragon is committed to continuously growing and providing benefits through the best works of Indonesia for Indonesia.",
            keywords:["karir", "career", "kerja", "pekerjaan","lowongan"],
            link: "/career"
        },
        {
            title:"Scholarship",
            desc:"PT Paragon Technology and Innovation support college students by breaking down barriers, opening doors, and offering access to college scholarships.",
            keywords:["scholarship", "kuliah", "kampus", "bantuan", "biaya", "sekolah", "beasiswa"],
            link: "/scholarship"
        }
    ])
    const [filteredPage, setFilteredPage] = useState([])

    useEffect(() => {
		const fuzz = require('fuzzball');
        var selectedPage = []
        if(searchFor.length === 0){
            selectedPage = listPage
        } else {
            listPage.map(page => {
                var i = 0;
                var found = false;
                while(i < page.keywords.length && !found){
                    var keyowrdFuzz = fuzz.partial_ratio(page.keywords[i].toLowerCase(), searchFor)
                    if(keyowrdFuzz >= fuzzyScore){
                        selectedPage.push(page)
                        found=true
                    } else {
                        i++
                    }
                }

                return selectedPage
            })
        }
        

        setFilteredPage(selectedPage)
    },[searchFor, fuzzyScore, listPage])

    const passQuery = useCallback (async(q) => {
        setSearchFor(q)
    },[])

    const keyPress = (e) => {
        if(e.key === "Enter"){
            submitQuery()
        }
    }

    const submitQuery = () => {
        setSearchFor(searchFor)
    }

    return (
        <Fragment>
            <Helmet>
                <title>Search result for '{searchFor}'</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"SEARCH RESULTS"}
                    bgImage={"bg-image-career"}
                />
                <div className="pt--50 pb--50" style={{textAlign:"center"}}>
                    <p>
                        Search result for '{searchFor}'
                    </p>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className="search-box" style={isMobile ? {width:"80%"} : {width:"40%"}}>
                            <div>
                                <label>
                                    <Input type="search" placeholder="Enter search keyword" required={true} value={searchFor} passQuery={passQuery} keyPress={keyPress}/>
                                </label>
                                <button className="search-submit" onClick={submitQuery}><i className="fa fa-search"/></button>
                            </div>
                        </div>
                    </div>

                    <div
                    style={{backgroundColor:"#FFFFFF", minHeight:"45vh"}}
                    className="section pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30 mt_sm--50 mb_sm--50">

                        <Container fluid={true} className="plr--180 plr_lg--100 plr_md--10 plr_sm--10">
                            <Row className="align-items-center">
                                {filteredPage.map(page => (
                                    <Col lg={12} className="mb--40 pb--40" style={{textAlign:"left", borderBottom:"1px solid #999999"}}>
                                        <a href={page.link}><h4 style={{color:"#215A88"}}>{page.title}</h4></a>
                                        <span>{page.desc}</span>
                                    </Col>
                                ))}

                                {filteredPage.length === 0 ?
                                    <div style={{width:"100%", marginTop: "10vh"}}>
                                        <h2 style={{textAlign: "center", color:"#AAAAAA", fontFamily: "Montserrat"}}>Result Not Found</h2>
                                        <p style={{textAlign: "center",color:"#AAAAAA", fontFamily: "Montserrat"}}>Please search with another keyword</p>
                                    </div>
                                    : null
                                }
                            </Row>
                        </Container>
                    </div>
                </div>

                <FooterParagon/>
            </LayoutDefault>

        </Fragment>
    );
};

export default SearchResult;