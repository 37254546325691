import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col} from "react-bootstrap";

const ScholarshipItem = ({title, desc, img, link}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    return (
        <div className="counter-style--paragon wow move-up counter-item" >
            {isMobile ? 
                <Row>
                    <Col xs={4}>
                        <div className="icon">
                            <img src={img} alt="icon"/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className="content">
                            <h4 style={{color: "#215AA8", fontFamily:"Montserrat", textAlign:"left"}}>{title}</h4>
                            <p style={{color: "#215AA8", fontFamily:"Montserrat", textAlign:"left"}}>{desc}</p>
                        </div>
                    </Col>
                </Row>
            :
                <>
                    <div className="icon" style={{textAlign:"left",marginBottom:"32px"}}>
                        <img src={img} alt="icon"/>
                    </div>
                    <div className="content" style={{width:"80%"}}>
                        <h4 style={{color: "#215AA8", fontFamily:"Montserrat", textAlign:"left"}}>{title}</h4>
                        <p style={{color: "#215AA8", fontFamily:"Montserrat", textAlign:"left"}}>{desc}</p>
                        <div className="brook-btn mt--50 text-left">
                            <a href={link}>
                                <span style={{color:"#215AA8",fontSize:"18px"}}><strong>See Details</strong></span>
                                <span className="btn-arrow" style={{color:"#215AA8", width:"30px"}}/>
                            </a>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default ScholarshipItem;
