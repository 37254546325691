import React, {useEffect, useState} from 'react';
import dateFormat from 'dateformat';
import {Link} from "react-router-dom";

const NewsHeadlineItem = ({data}) => {
    const [showedDate,setShowedDate] = useState(data.createdAt)

    useEffect(() => {
        var date = parseInt(data.createdAt.substring(8,10))
        var month = parseInt(data.createdAt.substring(5,7))
        var year = parseInt(data.createdAt.substring(0,4))
        setShowedDate(dateFormat(new Date(year, month-1, date), "mmm dd, yyyy"))
    }, [data.createdAt])
    return (
        <div className="blog blog-style--innovation creative--blog">
            <div className="thumb poss_relative">
                <Link to={`${process.env.PUBLIC_URL + '/post/news/'+data.id}`}>
                    <img src={data.imageThumb} alt={data.title}/>
                </Link>
            </div>

            <div className="blog-content" style={{position: "relative"}}>
                <div className="content" >
                    <ul className="meta">
                        <li>{showedDate}</li>
                    </ul>
                    <h3 className="the-title"><Link to={`${process.env.PUBLIC_URL + '/post/news/'+data.id}`}>{data.title}</Link></h3>
                    
                </div>
            </div>
        </div>
    );
};

export default NewsHeadlineItem;
