import React from 'react';

const PrivacyPolicyContent = () => {
    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{fontFamily:"Montserrat"}}>Privacy Policy</h3>
            <p>
                Kebijakan Privasi ini menjelaskan bagaimana PT Paragon Technology and Innovation menangani data pribadi yang kami kumpulkan secara <i>online</i> (misalnya melalui media sosial kami, situs web, atau situs web perdagangan merek kami) dan <i>offline</i> (misalnya pengambilan data di lokasi ritel dan kegiatan promosi langsung) yang selanjutnya akan disebut “Layanan”
                <br/>
                <br/>
                Kebijakan Privasi ini menjelaskan jenis data pribadi yang kami kumpulkan dan proses, bagaimana kami dapat menggunakan dan berbagi data tersebut, serta pilihan yang tersedia untuk Anda sehubungan dengan penanganan kami terhadap data Anda.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Apa Yang Kami Kumpulkan
            </h4>
            <h4 style={{fontFamily:"Montserrat"}}>
                Informasi yang kami kumpulkan langsung dari Anda
            </h4>
            <p>
                Kami mengumpulkan informasi yang Anda berikan langsung kepada kami, seperti ketika Anda mendaftarkan akun di situs web, situs web perdagangan merek kami, mendaftar ke buletin kami, menyatakan minat pada <i>voucher</i> kami, atau berinteraksi dengan kami di media sosial. Jenis data pribadi yang dapat kami kumpulkan langsung dari Anda meliputi:
            </p>
            <ul>
                <li>
                    Informasi kontak, seperti nama, tanggal lahir, alamat email, nomor ponsel, akun Instagram, dan profesi Anda;
                </li>
                <li>
                    Alamat, seperti nama jalan, area, kota, provinsi, dan negara.
                </li>
                <li>
                    Informasi kulit, seperti jenis kulit, masalah kulit, dan warna kulit Anda;
                </li>
                <li>
                    Informasi produk, seperti produk make-up yang Anda miliki dan bagaimana Anda bisa tahu produk kami;
                </li>
                <li>
                    Informasi lain yang Anda pilih untuk diberikan.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Informasi tentang penggunaan Anda atas Layanan kami
            </h4>
            <p>
                Kami mengumpulkan informasi tentang penggunaan Layanan yang Anda lakukan, seperti: membeli produk kami di situs web perdagangan merek resmi kami, atau produk yang Anda minati.
                <br/>
                <br/>
                Jika Anda membeli salah satu produk kami dari <i>Brand Commerce</i> resmi kami, kami mengumpulkan data pesanan pembelian Anda, seperti: produk apa yang dibeli, jumlah produk, total harga pesanan, dan voucher yang Anda gunakan dalam transaksi; kami juga dapat mengumpulkan data pribadi Anda seperti nama, email, dan nomor telepon Anda; dan alamat Anda sebagai informasi yang diperlukan untuk mengirimkan pesanan Anda.
                <br/>
                <br/>
                Jika Anda menggunakan fitur berbagi kami untuk menginformasikan produk ke teman-teman Anda, kami akan bisa mengumpulkan informasi tersebut.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Informasi yang kami kumpulkan dari sumber lain
            </h4>
            <p>
                Saat Anda mengakses Layanan melalui jejaring sosial, kami mengumpulkan informasi dari jejaring sosial sesuai dengan pengaturan Anda di jejaring sosial. Jika Anda berinteraksi dengan kami di media sosial, kami akan mengumpulkan informasi tentang interaksi tersebut. Informasi yang kami kumpulkan meliputi nama Anda, gambar, pegangan media sosial atau nama pengguna, dan alamat <i>email</i> yang sesuai dengan media sosial. Kami juga dapat mengumpulkan informasi tentang Anda dari pihak ketiga, termasuk sumber yang tersedia untuk umum atau teman yang membagikan produk kami di saluran berbagi mereka.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Informasi yang kami kumpulkan dengan cara otomatis
            </h4>
            <p>
                Ketika Anda mengunjungi situs kami, kami mengumpulkan informasi tertentu secara otomatis. Untuk mengumpulkan informasi ini, kami dapat menggunakan <i>cookie</i> dan teknologi serupa. “Cookie” adalah <i>file</i> teks yang dikirim situs web ke komputer pengunjung atau perangkat lain yang terhubung ke internet untuk secara unik mengidentifikasi <i>browser</i> pengunjung atau untuk menyimpan informasi atau pengaturan di <i>browser</i>. Kami juga dapat mengumpulkan informasi tentang aktivitas <i>online</i> Anda dari waktu ke waktu dan di situs web pihak ketiga. Informasi yang kami kumpulkan secara otomatis dapat mencakup:
                <br/>
                <br/>
                <ul>
                    <li>
                        URL yang merujuk pengunjung ke situs web kami;
                    </li>
                    <li>
                        Istilah pencarian yang digunakan untuk menjangkau situs web kami;
                    </li>
                    <li>
                        Detail tentang perangkat yang digunakan untuk mengakses situs web kami (seperti: alamat IP, informasi <i>browser</i>, informasi perangkat, dan informasi sistem operasi);
                    </li>
                    <li>
                        Detail tentang interaksi Anda dengan situs web kami (seperti: tanggal, waktu, lama mengakses situs kami, halaman spesifik yang diakses selama kunjungan Anda ke situs web kami, dan email pemasaran yang Anda buka); dan
                    </li>
                    <li>
                        Informasi penggunaan (seperti: jumlah dan frekuensi pengunjung ke situs web kami).
                    </li>
                </ul>
                <br/>
                Kami dapat mengaitkan informasi ini dengan buletin Anda jika Anda berlangganan, perangkat yang Anda gunakan untuk terhubung ke Layanan kami, dan email atau akun media sosial yang Anda gunakan untuk terhubung dengan kami.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Informasi Pembayaran
            </h4>
            <p>
                Jika Anda membeli produk kami dari situs resmi <i>Brand Commerce</i> kami, maka untuk menyelesaikan pesanan, Anda harus melakukan pembayaran melalui metode pembayaran terintegrasi kami. Kami bekerja sama dengan Xendit, penyedia <i>gateway</i> pembayaran, yang akan mengumpulkan informasi pembayaran Anda sesuai metode yang Anda pilih. Semua data Anda akan dienkripsi melalui Standar Keamanan Data Industri Kartu Pembayaran (PCI-DSS).
                <br/>
                <br/>
                Semua gateway pembayaran langsung mematuhi standar yang ditetapkan oleh PCI-DSS yang dikelola oleh PCI Security Standards Council, yang merupakan upaya bersama dari merek-merek seperti Visa, MasterCard, American Express dan Discover. Dan Xendit sudah memenuhi standar ini. Persyaratan PCI-DSS membantu memastikan penanganan aman informasi kartu kredit oleh toko <i>Brand Commerce</i> resmi kami dan penyedia layanannya. Untuk informasi lebih lanjut, Anda mungkin juga ingin membaca Ketentuan Layanan Xendit <a style={{color:"#215AA8"}} href="https://www.xendit.co/en/terms-and-conditions/" target="_blank" rel="noopener noreferrer">di sini</a> atau Pernyataan Privasi <a style={{color:"#215AA8"}} href="https://www.xendit.co/en/privacy-policy/" target="_blank" rel="noopener noreferrer">di sini</a>.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Layanan Pihak Ketiga
            </h4>
            <p>
                Secara umum, penyedia pihak ketiga yang digunakan oleh kami hanya akan mengumpulkan, menggunakan, dan mengungkapkan informasi Anda sejauh yang diperlukan untuk memungkinkan mereka melakukan layanan yang mereka berikan kepada kami.
                <br/>
                <br/>
                Namun, penyedia layanan pihak ketiga tertentu (misalnya Xendit) memiliki kebijakan privasi mereka sendiri sehubungan dengan informasi yang kami harus berikan kepada mereka untuk transaksi terkait pembelian Anda. Untuk penyedia ini, kami sarankan Anda membaca kebijakan privasi mereka sehingga Anda dapat memahami cara di mana informasi pribadi Anda akan ditangani oleh penyedia ini.
                <br/>
                <br/>
                Kami juga bekerja sama dengan penyedia Pengiriman dan Mitra Logistik lainnya, yang memiliki kebijakan privasi mereka sendiri sehubungan dengan informasi yang kami perlukan untuk menyediakannya untuk transaksi terkait pengiriman Anda. Setelah Anda meninggalkan situs web toko kami atau diarahkan ke situs web atau aplikasi pihak ketiga, Anda tidak lagi diatur oleh Kebijakan Privasi ini atau Ketentuan Layanan situs web kami.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Layanan Periklanan dan Analitik yang Disediakan oleh Pihak Ketiga
            </h4>
            <p>
                Kami dapat mengizinkan orang lain untuk menyediakan layanan analitik dan menayangkan iklan atas nama kami di internet dan dalam aplikasi seluler. Entitas ini dapat menggunakan <i>cookie</i> dan teknologi lainnya untuk mengumpulkan informasi tentang penggunaan Layanan oleh Anda dan situs web dan aplikasi lainnya, misalnya: alamat IP, <i>browser</i> web, informasi jaringan seluler, halaman yang dilihat, halaman, waktu yang dihabiskan pada halaman atau dalam aplikasi, dan tautan yang diakses. Informasi ini dapat digunakan oleh kami; dan penggunaannya tetapi tidak terbatas pada menganalisis dan melacak data, tapi juga untuk menentukan popularitas produk atau konten tertentu, mengirimkan iklan dan konten yang ditargetkan untuk minat Anda di Layanan kami dan situs web lain, dan memiliki pemahaman yang lebih baik aktivitas <i>online</i> Anda.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Layanan Periklanan dan Analitik yang Disediakan oleh Pihak Ketiga
            </h4>
            <p>
                Kami dapat menggunakan informasi yang kami kumpulkan untuk mengirimkan produk yang Anda beli di situs perdagangan merek resmi kami, meningkatkan pengalaman Anda saat menjelajahi situs web kami, menyesuaikan buletin langganan Anda dan juga interaksi kami dengan Anda, serta untuk menyediakan, memelihara, dan meningkatkan pelayanan kami. 
                <br/>
                <br/>
                Kami juga dapat menggunakan informasi yang kami kumpulkan untuk:
                <ul>
                    <li>
                        Berkomunikasi dengan Anda tentang Layanan kami, termasuk untuk memberitahu Anda tentang produk dan layanan yang mungkin menarik bagi Anda;
                    </li>
                    <li>
                        Memfasilitasi keterlibatan Anda dengan Layanan, termasuk memungkinkan Anda untuk mengirim komentar dan ulasan produk;
                    </li>
                    <li>
                        Memberi tahu Anda tentang tanggal peluncuran produk baru kami, informasi produk baru, dan informasi terkait merek;
                    </li>
                    <li>
                        Memberi penawaran promosi dan <i>giveaway</i>; dan
                    </li>
                    <li>
                        Melakukan atau mengelola survei dan riset pasar lainnya.
                    </li>
                </ul>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Bagaimana Kami dapat Membagi Informasi Anda
            </h4>
            <p>
                Kami dapat membagikan informasi yang kami kumpulkan ke:
                <br/>
                <br/>
                <ul>
                    <li>
                        Dengan mitra terpilih sebagai bagian dari promosi yang disponsori bersama;
                    </li>
                    <li>
                        Untuk mematuhi kewajiban hukum atau peraturan, melindungi, dan membela hak atau properti kami, melindungi keselamatan pelanggan dan pengguna situs web kami atau publik, dan melindungi terhadap tanggung jawab hukum;
                    </li>
                    <li>
                        Sehubungan dengan, atau selama proses negosiasi, merger, penjualan aset perusahaan, pembiayaan, atau akuisisi semua atau sebagian dari bisnis kami oleh perusahaan lain;
                    </li>
                    <li>
                        antara dan di antara afiliasi kami saat ini dan yang akan datang, anak perusahaan, dan perusahaan lain di bawah kendali dan kepemilikan yang sama; dan
                    </li>
                </ul>
                <br/>
                Saat Anda memberikan ulasan produk atau konten lain, konten itu akan disortir oleh tim kami dan ulasan produk yang dipilih akan diposkan secara publik. Pengunjung situs web lain mungkin dapat melihat nama Anda atau informasi lain tentang Anda yang Anda poskan, seperti akun media sosial atau nama pengguna Anda.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Hak Anda
            </h4>
            <h4 style={{fontFamily:"Montserrat"}}>
                Memilih Keluar dari Buletin
            </h4>
            <p>
                Anda dapat berhenti berlangganan buletin kami kapan saja dengan mengikuti instruksi yang termasuk dalam <i>email</i> tersebut. Jika Anda memilih untuk tidak menerima komunikasi semacam itu, perhatikan bahwa kami dapat terus mengirimi Anda <i>email</i> non-promosi (Seperti <i>email</i> tentang perubahan dalam ketentuan hukum atau kebijakan privasi kami).
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Membatasi Cookie
            </h4>
            <p>
                Sebagian besar <i>browser</i> web diatur untuk menerima <i>cookie</i> secara default. Anda biasanya dapat memilih untuk mengatur <i>browser</i> Anda untuk menghapus atau menolak <i>cookie browser</i>. Harap perhatikan bahwa jika Anda memilih untuk menghapus atau menolak <i>cookie</i>, ini dapat mempengaruhi ketersediaan dan fungsionalitas situs web kami.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Anak-anak
            </h4>
            <p>
                Layanan kami tidak dirancang untuk anak-anak dan tidak secara sengaja mengumpulkan data pribadi dari anak-anak (di bawah 12 tahun). Jika Anda memiliki alasan untuk meyakini bahwa seorang anak telah memberikan data pribadi kepada kami, silakan hubungi kami di customercare@paracorpgroup.com dan kami akan memeriksa kembali fakta-fakta dan menghapus informasi itu dari basis data kami jika itu terbukti sebagai data anak-anak.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Tautan ke Situs Web Lain dan Konten Pihak Ketiga
            </h4>
            <p>
                Kami dapat menyediakan tautan ke situs web pihak ketiga, layanan, dan aplikasi, seperti Instagram dan situs web perdagangan lainnya, yang tidak dioperasikan atau dikendalikan oleh kami. Kebijakan Privasi ini tidak berlaku untuk layanan pihak ketiga, dan kami tidak dapat bertanggung jawab atas konten, kebijakan privasi, atau praktik layanan pihak ketiga. Kami mendorong Anda untuk meninjau kebijakan privasi layanan pihak ketiga mana pun sebelum memberikan informasi apapun kepada atau melalui mereka. Interaksi Anda dengan fitur-fitur dari pihak ketiga ini diatur oleh kebijakan privasi layanan pihak ketiga yang menyediakan fitur tersebut.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Retensi Data
            </h4>
            <p>
                Periode penyimpanan kami untuk data pribadi didasarkan pada kebutuhan bisnis dan persyaratan hukum. Kami menyimpan data pribadi selama diperlukan untuk keperluan pemrosesan yang datanya dikumpulkan, dan tujuan terkait lainnya yang diizinkan.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Perubahan terhadap Kebijakan Privasi Kami
            </h4>
            <p>
                Kami dapat mengubah Kebijakan Privasi ini dari waktu ke waktu. Jika kami melakukannya, kami akan memposting kebijakan yang diperbarui di situs kami dan akan menunjukkan kapan Kebijakan Privasi terakhir direvisi. Kami juga akan mengirimkan pemberitahuan <i>email</i> kepada Anda bahwa Kebijakan Privasi kami baru-baru ini berubah. Anda harus secara berkala meninjau Kebijakan Privasi kami saat ini untuk tetap mendapat informasi tentang praktik data pribadi kami.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Menghubungi Kami
            </h4>
            <p>
                Jika Anda memiliki pertanyaan atau masalah mengenai Kebijakan Privasi ini, atau ingin memperbarui preferensi atau informasi kami tentang Anda, silakan hubungi kami di alamat yang disediakan di bawah ini. Kami akan mengambil langkah wajar dan segera untuk mengakomodasi permintaan Anda.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                PT Paragon Technology and Innovation
            </h4>
            <p>
            Jalan Swadarma Raya Kampung Baru IV No. 1 Jakarta - 12250 Indonesia
            <br/>
            <br/>
            E-mail: customercare@paracorpgroup.com
            <br/>
            <br/>
            Phone:
            08041401123
            <br/>
            <br/>
            Fax: +62 21 585 2439
            </p>
        </>
    )
}

export default PrivacyPolicyContent;