import React from 'react';
import {useMediaQuery} from 'react-responsive'

const CSRPilarItem = ({link,image,altText,title}) => {

    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className="align-items-center" style={isMobile ? {justifyContent:"center", textAlign:"center", width: "90%"} : {justifyContent:"center", textAlign:"center"}}>
            <a href={link}>
                <img src={require('../../assets/img/icons/'+image)} alt={altText}/>
            </a>
            <h4 className="text-center mt--50" style={isMobile ? {color:"#215AA8", fontFamily:"Montserrat", fontSize: "14px"} : {color:"#215AA8", fontFamily:"Montserrat"}}>{title}</h4>
        </div>
    )

}

export default CSRPilarItem;