import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import { Container, Row, Col } from 'react-bootstrap';
import NewsItemBe from './NewsItemBe';
import { useGetDataNewsArticle } from '../../hooks/api/useGetDataNewsArticle'

export default function NewsComp() {

  const { data, isLoading, isFetching, error } = useGetDataNewsArticle('news')
  
  if (isLoading || isFetching) {
    return (
      <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
        <div style={{ marginTop: "30vh" }} />
        <BeatLoader color={"#215AA8"} loading={true} size={72} />
      </div>
    )
  }
  if (error) return (
    <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
      <div style={{ marginTop: "30vh" }} />
      Unable to fetch data
    </div>
  )

  return (
    <>
      <div className="bk-blog-grid-area bg_color--5 section-ptb-150">
        <Container>
          <Row className="mt--n60">
            {data.data.listNews.map(blog => (
              <Col lg={4} sm={6} key={blog.id}>
                <NewsItemBe
                  data={blog}
                  className="mt--60 blog-theme-color"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  )
}
