import React from 'react';

const ParagonItemBrand = ({link,image,altText}) => {
    return (
        <div className={`brand mt--40 mb--40`}>
            <a href={link}>
                <img src={require('../../assets/img/'+image)} alt={altText}/>
            </a>
        </div>
    )

}

export default ParagonItemBrand;