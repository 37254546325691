import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import ScholarshipItem from "./ScholarshipItem";
import sch from "../../assets/img/icons/ic_scholarship.png"

const ScholarshipList = () => {

    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const [scholarshipList] = React.useState([
        {
            title:'Beasiswa Prestasi',
            img:sch,
            link:"/scholarship/type/Beasiswa Prestasi",
            desc:'A scholarship program to support high achieving students to continue learning and developing themselves'
        },
        {
            title:'Beasiswa Pemberdayaan',
            img:sch,
            link:"/scholarship/type/Beasiswa Pemberdayaan",
            desc:'Tuition assistance for prospective students with good academic potential to foster the spirit of learning'
        },
        {
            title:'Beasiswa Tugas Akhir',
            img:sch,
            link:"/scholarship/type/Beasiswa Tugas Akhir",
            desc:'Final assignment research grants for final-year students'
        }
    ])
    
    return (
        <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
            <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                <div className="number-speaks-wrapper">
                    <div className="content wow move-up">
                        <div className="title--creative text-center mb--40">
                            <h3 style={{color: "#215AA8", fontFamily: "Montserrat"}}>PARAGON SCHOLARSHIP PROGRAM</h3>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg={12} className={'mt_md--40 mt_sm--40'} style={{display: "flex", alignItems: "center"}}>
                        <div className="speakers-number-wrap item-grid-three" >
                            {scholarshipList.map((item) => (
                                <ScholarshipItem
                                    title={item.title}
                                    img={item.img}
                                    link={item.link}
                                    desc={item.desc}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ScholarshipList;