import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import ScholarshipTestimonialItem from './ScholarshipTestimonialItem'

const ScholarshipTestimonial = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const [testi] = React.useState([
        {
            id: 1,
            quote: "Paragon Scholarship has given us extraordinary experience as in its motto “Unleash Your Potential”.",
            name: "Nabiel Arzyan",
            origin: "Grantee Prestasi UGM 18/19",
            photo: "nabiel_arzyan.jpg"
        },
        {
            id: 2,
            quote:"I am very happy and grateful for the tuition assistance to complete my education. Thank you, Paragon Scholarship!",
            name: "Lutfia Triwahyuni",
            origin: "Grantee Pemberdayaan UI 18/19",
            photo: "lutfia_triwahyuni.jpg"
        },
        {
            id: 3,
            quote:"Paragon Scholarships provided excellent self-development programs and other assistance to support grantee’s potentials. This will help our nation to increase the competitiveness of its human resources.",
            name: "Ryan Adhitya",
            origin: "Grantee Pemberdayaan UNPAD 19/20",
            photo: "ryan_adhitya.jpg"
        }
    ])
    
    return (
        <div className="bk-testimonial-area section-ptb-100 bg_color--1 poss_relative pt_sm--0" style={{backgroundColor:"#215AA8"}}>
            <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                <Row>
                    {testi.map(item => (
                        <Col xs={12} md={6} lg={4} style={{display:"flex", justifyContent:"center", marginBottom:"32px"}}>
                            <ScholarshipTestimonialItem key={item.id} data={item} classes={'swiper-slide'}/>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ScholarshipTestimonial;
