import React from 'react';

const AboutThumbOne = ({className, thumb, is404}) => {
    return (
        <div className={`thumb text-left wow move-up ${className && className}`}>
            <img src={thumb} alt="thumb" style={is404 ? {width:"25%"} : {width:"100%"}}/>
        </div>
    );
};

export default AboutThumbOne;
