import React from 'react';
import {Modal} from 'react-bootstrap';

const TeamParagonItem = ({data}) => {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault()
        setShow(true);
    }
    return (
        <>
            <a href="#open" onClick={(e) => handleShow(e)}>
                <div className="team team__style--3">
                    <div className="thumb">
                        <img src={require('../../assets/img/team/team-paragon/'+data.thumb)} alt={data.name}/>
                    </div>
                    <div className="team-info text-center">
                        <div className="info">
                            <h5 style={{color:"#215aa8"}}>{data.name}</h5>
                        </div>
                    </div>
                </div>
            </a>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title style={{paddingLeft:"32px", paddingTop:"32px", color:"#215AA8"}}>{data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding:"32px"}}>{data.desc}</Modal.Body>
            </Modal>
        </>
    );
};

export default TeamParagonItem;