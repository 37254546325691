import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import FooterParagon from "../../container/footer/FooterParagon"
import ArticleComp from '../../components/article-from-be/ArticleComp';

const ArticlePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Paragon Article</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2">
                <Header />
                <Breadcrumb
                    title={"ARTICLE"}
                    bgImage={"bg-image-career"}
                />
                <ArticleComp />
                <FooterParagon />
            </LayoutDefault>
        </Fragment>
    );
};

export default ArticlePage;
