import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import LegalInformationTab from "./LegalInformationTab"

const LegalInformationPage = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Legal Information Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"LEGAL INFORMATION"}
                    bgImage={"bg-image-brand"}
                />
                
                <LegalInformationTab/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default LegalInformationPage;