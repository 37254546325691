import React, {useEffect, useState} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import ListItem from "../../components/list-item-paragon";
import { useMediaQuery } from 'react-responsive'

const MissionDetailItem = () => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    const [listMissionWebLeft, setListMissionWebLeft] = useState([])
    const [listMissionWebRight, setListMissionWebRight] = useState([])
    const [listMissionMobile] = useState([
        {
            title:"Developing Paragonians",
            details:"We continuously learn and develop competent Paragonian with competitive advantage",
            image: "developing_paragonian.png"
        },
        {
            title:"Creating Kindness for Consumers",
            details:"We listen to consumers needs and create products beyond consumers' expectation",
            image:"creating_kindness_for_cust.png"

        },
        {
            title:"Continuous Improvement",
            details:"We constantly innovate in every process to improve our product quality",
            image:"continuous_improvement.png"
        },
        {
            title:"Grow Together",
            details:"We work together with business partners for mutual benefit",
            image:"grow_together.png"
        },
        {
            title:"Preserving the Earth",
            details:"We try our best to protect the earth sustainably as it provides all we need to live.",
            image: "preserving_the_earth.png"
        },
        {
            title:"Supporting Education and Health of the Nation",
            details:"We support the development of new generations through development of human resources to be knowledgeable, experienced and healthy",
            image:"education_health.png"
        },
        {
            title:"Developing Business",
            details:"We expand our ranges of products, services through business expansion",
            image:"developing_business.png"
        },
    ])

    useEffect(() => {
        var listMission = [...listMissionMobile]
        setListMissionWebLeft(listMission.splice(0,4))
        setListMissionWebRight(listMission.splice(0,3))
    },[listMissionMobile])
    return (
        <div
            style={{backgroundColor:"#FFFFFF"}}
            className="section pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">

            <Container fluid={true} className="plr--180 plr_lg--100 plr_md--50 plr_sm--10 mt--70 mb--70" >
                <Row className="align-items-center" >
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-left align-items-center">
                            {isMobile ? null : <h3 style={{color:"#215AA8"}} className="mb--40">Our Mission</h3>}
                            {isMobile ? 
                            <Row>
                                {listMissionMobile.map(mission => (
                                    <Col lg={6} className="mt--50">
                                        <div className="bk-list">
                                            <ListItem
                                                marker={'default'}
                                                text={mission.title}
                                                content={mission.details}
                                                icon={mission.image}
                                            />
                                        </div>
                                    </Col>
                                ))}
                                
                            </Row>
                                :                             
                            <Row>
                                <Col lg={6} >
                                    {listMissionWebLeft.map(mission => (
                                            <div className="bk-list mt--50">
                                                <ListItem
                                                    marker={'default'}
                                                    text={mission.title}
                                                    content={mission.details}
                                                    icon={mission.image}
                                                />
                                            </div>
                                    ))}
                                </Col>
                                <Col lg={6} >
                                    {listMissionWebRight.map(mission => (
                                            <div className="bk-list mt--50">
                                                <ListItem
                                                    marker={'default'}
                                                    text={mission.title}
                                                    content={mission.details}
                                                    icon={mission.image}
                                                />
                                            </div>
                                    ))}
                                </Col>
                                
                            </Row>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MissionDetailItem;