import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import ScholarshipBenefitItem from './ScholarshipBenefitItem';
import scholarshipImage from "../../assets/img/scholarship.jpg";

const ScholarshipBenefitList = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return(
        <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
            <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                <h3 className="text-center mb--60" style={{color:"#215AA8", fontFamily: "Montserrat"}}>The Benefits Grantee Will Get</h3>
                <Row>
                    <Col lg={5} style={{position:"relative"}}>
                        <img src={scholarshipImage} alt="grantee" style={{position:"absolute", left:"50%", top:"50%", transform: "translate(-50%, -50%)"}}/>
                    </Col>
                    <Col lg={7} className={'mt_md--40 mt_sm--40'} style={{alignItems: "center"}}>
                        <ScholarshipBenefitItem
                            isFirst={true}
                            title="Financial Support"
                            desc="UKT funding assistance with nominal value IDR 6.250.000,- per semester until the final year."/>
                        
                        <ScholarshipBenefitItem
                            title="Sustainable Development Program"
                            desc="Self-development programs until final year with the experts of communication, presentation, leadership, etc."/>
                        
                        <ScholarshipBenefitItem
                            title="Networking"
                            desc="Expand the network among grantees from various campuses, with the community, and with our company."/>
                        
                        <ScholarshipBenefitItem
                            title="Social Impact"
                            desc="Organizing community project."/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ScholarshipBenefitList