import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ParagonItemBrand from "./ParagonItemBrand";

const ParagonFourBrand = () => {
  const brandList = [
    {
      link: "#wardah",
      image: "logo_wardah_colored.png",
      altText: "Wardah Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#makeover",
      image: "logo_makeover_colored.png",
      altText: "Make Over Logo",
      lg: 2,
      xs: 6,
    },
    {
      link: "#emina",
      image: "logo_emina_colored.png",
      altText: "Emina Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#kahf",
      image: "logo_kahf_temp.png",
      altText: "Kahf Logo",
      lg: 2,
      xs: 6,
    },
    {
      link: "#putri",
      image: "logo_putri_colored.png",
      altText: "Putri Logo",
      lg: 2,
      xs: 6,
    },
    
    {
      link: "#crystallure",
      image: "logo_crystallure_colored.png",
      altText: "Crystallure Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#instaperfect",
      image: "logo_instaperfect_colored.png",
      altText: "Instaperfect Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#labore",
      image: "logo_labore_colored.png",
      altText: "Labore Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#biodef",
      image: "logo_biodef_colored.png",
      altText: "Biodef Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#tavi",
      image: "logo_tavi_colored.png",
      altText: "Tavi Logo",
      lg: 2,
      xs: 6,
    },
    {
      link: "#wonderly",
      image: "logo_wonderly_colored.png",
      altText: "Wonderly Logo",
      lg: 3,
      xs: 6,
    },
    {
      link: "#omg",
      image: "logo_omg_colored.png",
      altText: "OMG Logo",
      lg: 2,
      xs: 6,
    },
    {
      link: "#beyondly",
      image: "logo_beyondly_colored.png",
      altText: "Beyondly Logo",
      lg: 2,
      xs: 12,
    },
    {
      link: "#earthlovelife",
      image: "logo_earthlovelife_colored.png",
      altText: "Earth Live Life Logo",
      lg: 3,
      xs: 12,
    },
  ];
  return (
    <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
      <Container>
        <Row>
          <Col
            lg={12}
            className={"mt_md--40 mt_sm--40"}
            style={{ display: "flex", alignItems: "center", minHeight: "30vh" }}
          >
            <Row>
              {brandList.map((brand, index) => (
                <Col lg={brand.lg} xs={brand.xs} key={index}>
                  <div className="speakers-number-wrap brand-default">
                    <ParagonItemBrand
                      link={brand.link}
                      image={brand.image}
                      altText={brand.altText}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ParagonFourBrand;
