import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import PageContainer from "../../container/page";
import Header from "../../components/header/HeaderParagon";
import PopupSearch from "../../components/popup/PopupSearch";
import PopupMobileMenu from "../../components/popup/PopupMobileMenu";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbWithDesc";
import VirtualOfficeTourSection from "../../components/breadcrumb/VirtualOfficeTourSection";
import ParagonBanner from "../../container/contact/ParagonBanner";
import VisionDetailItem from "../../container/contact/VisionDetailItem";
import MissionDetailItem from "../../container/contact/MissionDetailItem";
import CoreValueDetailItem from "../../container/contact/CoreValueDetailItem";
import LayoutDefault from "../../layouts/LayoutDefault";
import Paraco from "../../container/testimonial/homes/ParagonParaco";
import History from "../../container/testimonial/elements/ParagonHistory";
import FooterParagon from "../../container/footer/FooterParagon"
import MapParagon from "../../container/maps/elements/MapParagon";
import ParagonCorpLogo from './ParagonCorpLogo';

const AboutParagon = () => {
    return (
        <Fragment>
            <Helmet>
                <title>About Paragon</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-2" revealFooter={false}>
                <Header/>
                <PopupSearch/>
                <PopupMobileMenu/>
                <Breadcrumb
                    title={"About Paragon"}
                    image={"about-us"}
                    desc={"Paragon is an Indonesia beauty company with the purpose of creating a greater good for the society through innovation."}
                />
            </PageContainer>
            <ParagonCorpLogo/>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="vision"
                    sectionTitle="Our Vision"/>
            </LayoutDefault>
            <VisionDetailItem/>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="mission"
                    sectionTitle="Our Mission"/>
            </LayoutDefault>
            <MissionDetailItem/>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealSection={true}>
                <ParagonBanner
                    sectionName="corevalue"
                    sectionTitle="Our Core Values"/>
            </LayoutDefault>
            <CoreValueDetailItem/>
            <LayoutDefault className="template-color-1 template-font-2" revealFooter={false}>
                <div id="whitespace" style={{height:"128px"}}/>
                <Paraco/>
                <History/>
                <VirtualOfficeTourSection /> 
                <MapParagon/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default AboutParagon;