import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbWithDesc";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import ScholarshipBenefitList from './ScholarshipBenefitList';
import ScholarshipList from './ScholarshipList';
import ScholarshipTestimonial from './ScholarshipTestimonial'
import ScholarshipGallery from './ScholarshipGallery';
import CareerAction from "../career/CareerAction"
import instagram from "../../assets/img/icons/ic_instagram.png"

const ScholarshipPage = () => {
    const [mediaButton] = React.useState([
        {
            media: instagram,
            link: "https://www.instagram.com/paragonscholarship/",
            name: "instagram",
        }
    ])
    return (
        <Fragment>
            <Helmet>
                <title>Scholarship Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"Scholarship"}
                    image={"scholarship"}
                    desc={"We support college students by breaking down barriers, opening doors, and offering access to college scholarships."}
                />
                <ScholarshipList/>
                <ScholarshipBenefitList/>
                <ScholarshipTestimonial/>
                <ScholarshipGallery/>
                <CareerAction
                    bgColor={"white"}
                    textColor={"#215AA8"}
                    text={"Know More About Our Story"}
                    type={"media"}
                    listMedia={mediaButton}
                    isLarge={true}/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
}

export default ScholarshipPage