import React, { useState } from 'react';
import {Container, Row, Col} from "react-bootstrap";
import CounterItem from "../../components/counter/CounterItemParagon";
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { useMediaQuery } from 'react-responsive';
import employees from "../../assets/img/icons/ic_employee.png";
import dc from "../../assets/img/icons/ic_dc.png";
import sku from "../../assets/img/icons/ic_sku.png"
import csr from "../../assets/img/icons/ic_csr.png";

const ParagonInNumber = () => {
    const [index, setIndex] = useState(0)

    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const listTitle =[
        <div key="humble">
            <h2 style={{color: "#215AA8", fontFamily: "Montserrat"}}>Paragonians</h2>
            <p style={{color: "#215AA8"}}>With more than 10.000 employees that we call <strong>Paragonian</strong> all over Indonesia and Malaysia, we keep on innovating for better products, programs, and ways of working to create more goodness to the society.</p>                        
        </div>,
        <div key="dc">
            <h2 style={{color: "#215AA8", fontFamily: "Montserrat"}}>Distribution Centers</h2>
            <p style={{color: "#215AA8"}}>Supporting the penetration of our products to many areas in every region in Indonesia and our global expansion to ensure everyone can feel our goodness.</p>                
        </div>,
        <div key="csr">
            <h2 style={{color: "#215AA8", fontFamily: "Montserrat"}}>CSR Partners</h2>
            <p style={{color: "#215AA8"}}>Collaborating with local, national and global communities to create more comprehensive programs which best suit the condition of the society.</p>
        </div>,
        <div key="sku">
            <h2 style={{color: "#215AA8", fontFamily: "Montserrat"}}>SKU Products</h2>
            <p style={{color: "#215AA8"}}>Creating better and more products to meet the needs of a very diverse Indonesians. Our products are not merely things to be used, but are also part of education for the community to get a better quality of life by starting a better self-care.</p>
        </div>
    ]
    return (
        <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
            <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                <Row>
                    <Col lg={4}>
                        <div className="number-speaks-wrapper">
                            <div className="content wow move-up">
                                <div className="title--creative text-left">
                                    <h3 className="heading" style={{color: "#215AA8", marginBottom: "15%"}}>PARAGON AT A GLANCE</h3>
                                    <ReactCSSTransitionReplace 
                                        transitionName="fade-wait"
                                        transitionEnterTimeout={600}
                                        transitionLeaveTimeout={300}>
                                        {listTitle[index]}
                                    </ReactCSSTransitionReplace>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={8} className={'mt_md--40 mt_sm--40'} style={{display: "flex", alignItems: "center"}}>
                        <div className="speakers-number-wrap counter-grid-paragon" >
                            <CounterItem
                                index={0}
                                setIndex={setIndex}
                                count={10000}
                                countPrefix={">"}
                                title={'Paragonians'}
                                img={employees}
                            />

                            <CounterItem
                                index={1}
                                setIndex={setIndex}
                                count={41}
                                countPrefix={""}
                                title={'Distribution Centers'}
                                img={dc}
                            />

                            <CounterItem
                                index={2}
                                setIndex={setIndex}
                                count={200}
                                countPrefix={">"}
                                title={'CSR Partners'}
                                img={csr}
                            />

                            <CounterItem
                                index={3}
                                setIndex={setIndex}
                                count={1600}
                                countPrefix={">"}
                                title={'SKU Products'}
                                img={sku}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParagonInNumber;