import React from 'react';
import { useMediaQuery } from 'react-responsive';
import bgImage from "../../assets/img/banner/paragon-innovation-summit.jpeg"
import bgImageMobile from "../../assets/img/banner/paragon-innovation-summit-mobile.jpg"
import {Container, Row, Col} from 'react-bootstrap'

const ParagonInnovationSummit = ({link}) => {

    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    return (
        <div style={{position: "relative", marginTop: "148px"}}>
            <a target="_blank" rel="noreferrer noopener" href={link}>
                <div className="brook-call-to-action ptb--100">   
                    <img src={isMobile ? bgImageMobile : bgImage} style={{position: "absolute", bottom: "-5px"}} alt="Paragon Innovation Summit"/>        
                    <Container style={isMobile ? {maxWidth:"1591px",position: "absolute", bottom: "0px"} : {display: "none"}}>
                        <Row className="align-items-center">
                            <Col xs={12} lg={3} className={'wow move-up'} style={{zIndex:"10"}}>
                                <div className="call-btn text-center text-md-right mt-0 mt-md-0">
                                    <a target="_blank" rel="noreferrer noopener" className={`brook-btn bk-btn-white text-theme btn-sd-size btn-rounded`}
                                    href={link} style={{color:"#215AA8", fontSize: "12px", lineHeight:"24px",height:"24px"}}>
                                        Join Now!
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </a>
        </div>
    );
};

export default ParagonInnovationSummit;