import React from 'react';

const Button = ({type, btnText, btnLink, size, btnStyle, color, onClick, btnColor, btnBorder, txtColor}) => {
    return (
        type === 'link' ? (
            <a style={btnColor ? {backgroundColor: btnColor} : btnBorder ? {border: btnBorder, color:txtColor} : null} className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} space-between`} href={btnLink} onClick={onClick}>
                {btnText}
            </a>
        ) : (
            <button style={btnColor ? {backgroundColor: btnColor} : btnBorder ? {border: btnBorder, color:txtColor} : null} className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} space-between`} onClick={onClick}>
                {btnText}
            </button>
        )
    );
};

export default Button;
