import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon"
import AboutInnovation from './AboutInnovation';
import RedirectSection from './InnovationRedirect';
import ParagonInnovationSummit from './ParagonInnovationSummit';


const Innovation = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Paragon - Innovation</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-2 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"Innovation"}
                    bgImage={"bg-image-innovation"}
                />
                <AboutInnovation/>
                <RedirectSection/>
                <ParagonInnovationSummit
                    link={"https://summit.paragon-innovation.com/"}/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default Innovation;