import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

const ScholarshipGallery = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 768px'})
    const [gallery] = React.useState([
        {
            id: 1,
            img: "scholarship-1.jpg",
            alt: "Scholarship 1"
        },
        {
            id: 2,
            img: "scholarship-2.jpg",
            alt: "Scholarship 2"
        },
        {
            id: 3,
            img: "scholarship-3.jpg",
            alt: "Scholarship 3"
        },
        {
            id: 4,
            img: "scholarship-4.jpg",
            alt: "Scholarship 4"
        },
        {
            id: 5,
            img: "scholarship-5.jpg",
            alt: "Scholarship 5"
        },
        {
            id: 6,
            img: "scholarship-6.jpg",
            alt: "Scholarship 6"
        }
    ])
    
    return (
        <div className="bk-testimonial-area section-ptb-100 bg_color--1 poss_relative pt_sm--0">
            <Container style={isBigScreen ? {maxWidth:"1591px"} : null}>
                <Row>
                    {gallery.map(item => (
                        <Col xs={12} md={6} lg={4} style={{display:"flex", justifyContent:"center"}}>
                            <img src={require("../../assets/img/gallery/scholarship/"+item.img)} alt={item.alt} style={isMobile ? {paddingTop:"15px", paddingBottom:"15px"} : {paddingTop:"15px", paddingBottom:"15px", width:"24vw", height:"20.15vw"}}/>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ScholarshipGallery;
