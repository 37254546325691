import React from 'react';
import { useMediaQuery } from 'react-responsive';

const AboutThumbParagon = ({className, thumb}) => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return (
        <div className={`thumb text-left wow move-up ${className && className}`}>
            <img src={thumb} alt="thumb" style={isBigScreen ? {minWidth:"85%"} : null}/>
        </div>
    );
};

export default AboutThumbParagon;
