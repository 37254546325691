import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'

const VirtualOfficeItem = ({bgClass, link, title, buttonText, float, copy}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    
    return (
        <div
            style={isMobile ? {height:"70vh", width:"90%", borderRadius:"14px", margin:"5%"} : {height:"70vh", width:"70%", borderRadius:"14px", float: float, margin:"5%"}}
            className={"brook-breadcrumb-area breadcrumb-title-bar breadcrumb-title-white d-flex align-items-center pt_md--70 pt_sm--50 pb_sm--50 position-relative "+bgClass}>
            <div className="paragon-overlay-bold" style={{borderRadius:"14px"}}/>
            <Container fluid={true} className="plr--60 plr_lg--60 plr_md--30 plr_sm--30">
                <Row className="align-items-center" style={{justifyContent : "center"}}>
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-center align-items-center">
                            <h1 className="text-white" style={{fontSize: "calc(48px+2vw) !important", fontFamily:"Montserrat"}}>
                                {title}
                            </h1>
                            <div style={{width:"100%", display: "flex", justifyContent:"center"}}>
                                <p style={isMobile ? {color:"white", fontSize:"18px", lineHeight:"37px", width:"100%", fontFamily:"Montserrat"} : {color:"white", fontSize:"18px", lineHeight:"37px", width:"100%", fontFamily:"Montserrat"}} className="mt--50"> 
                                    {copy}
                                </p>
                            </div>
                            <Row style={{justifyContent:"center"}}>

                                <Col lg={isBigScreen ? 8 : 12}>
                                    <div className="brook-btn mt--50">
                                        <a href={link} target="_blank" rel="noopener noreferrer">
                                            <button
                                                style={{width:"100%"}}
                                                className="brook-btn bk-btn-border-paragon-transparent btn-sd-size btn-rounded">{buttonText}
                                            </button>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default VirtualOfficeItem;
