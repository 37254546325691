import React, { Fragment } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NewsHeadlineItem from "./NewsHeadlineItem";
import 'react-modal-video/scss/modal-video.scss';
import { useMediaQuery } from 'react-responsive'
import BeatLoader from "react-spinners/BeatLoader";
import { useGetDataNewsArticle } from '../../hooks/api/useGetDataNewsArticle'

function LatestNews() {

    const { data, isLoading, isFetching, error } = useGetDataNewsArticle('news')

    if (isLoading || isFetching) {
        return (
            <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                <div style={{ marginTop: "30vh" }} />
                <BeatLoader color={"#215AA8"} loading={true} size={72} />
            </div>
        )
    }
    if (error) return (
        <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
            <div style={{ marginTop: "30vh" }} />
            Unable to fetch data
        </div>
    )

    return (
        <>
            {data.data.listNews.slice(0, 4).map(blog => (
                <Fragment key={blog.id}>
                    <Col xs={12} sm={6} xl={3} className="wow move-up mt--30">
                        <NewsHeadlineItem data={blog} />
                    </Col>
                </Fragment>
            ))}
        </>
    )
}


const NewsHeadline = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })

    return (
        <div className="bk-blog-area section-ptb-120 clearfix">
            <Container style={isBigScreen ? { maxWidth: "1591px" } : null}>
                <Row>
                    <Col xs={12}>
                        <div className="section-title--between wow move-up">
                            <div className="title text-left" style={{ width: "100%" }}>
                                <h4 style={{ color: "#215aa8", fontFamily: "Montserrat" }}>LATEST NEWS</h4>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <LatestNews />
                </Row>
            </Container>
        </div>
    );
};

export default NewsHeadline;
