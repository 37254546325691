import React from 'react';
import TypedText from "../../../components/typed-text";
import { useMediaQuery } from 'react-responsive'

const AboutContentOne = ({title, heading, bottomText, bottomTextName, content, contentSecond, contentThird}) => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className="content pl_md--5 pl_sm--5" style={isBigScreen ? {paddingLeft: "80px"} : null}>
            <div className="title--paragon wow move-up">
                <h3 className="heading" style={{color:"#215AA8"}}>{title}</h3>
                <h2 style={{color:"#215AA8", fontSize:"32px"}}>
                    {heading}
                    <br/>
                    <span className="cd-words-wrapper theme-color" style={{color: "#6B89FF"}}>  <TypedText
                        content={['Since 1985']}
                        typeSpeed={100}
                        backSpeed={100}
                        loop={true}
                    />
                    </span>
                </h2>
            </div>
            <p className="wow move-up" style={isMobile ? {color:"#215AA8", fontWeight:"bold", lineHeight:"1.2", marginTop:"24px"} : {color:"#215AA8", fontWeight:"bold"}}>{content}</p>
            <p className="wow move-up" style={isMobile ? {color:"#215AA8", lineHeight:"1.2"} : {color:"#215AA8"}}>{contentSecond}</p>
            <p className="wow move-up" style={isMobile ? {color:"#215AA8", fontWeight:"bold", lineHeight:"1.2", marginTop:"24px"} : {color:"#215AA8", fontWeight:"bold"}}>{contentThird}</p>
            <p className="wow move-up" style={{color:"#215AA8", fontWeight:"bold", marginBottom:"24px"}}>{bottomText}</p>
            <p className="wow move-up" style={{color:"#215AA8", marginTop:"0px"}}>{bottomTextName}</p>
        </div>
    );
};

export default AboutContentOne;
