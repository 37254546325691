import React, { useCallback, useState } from 'react';
import Logo from "../header/elements/logo";
import Input from "../input";
import logoBlack from '../../assets/img/logo/paragon-corp-white.png';
import {useHistory} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

const PopupSearch = () => {
    let history = useHistory()
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [query, setQuery] = useState('')

    const closeSearchPopup = () => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('page-search-popup-opened');
        html.style.overflow = "auto"
    };

    const passQuery = useCallback((q) => {
        setQuery(q)
    },[])

    const keyPress = (e) => {
        if(e.key === "Enter"){
            submitQuery()
        }
    }

    const submitQuery = () => {
        history.push("result?q="+query)
        closeSearchPopup()
        window.location.reload()
        setQuery('')
    }

    return (
        <div className={isMobile ? "paragon-search-popup-mobile" : "paragon-search-popup"}>
            <div className="overlay" onClick={() => {
                closeSearchPopup();
            }}/>
            <div className="inner">
                <div className="search-header">
                    <Logo logo={logoBlack}/>
                    <button className="search-close" onClick={() => {
                        closeSearchPopup();
                    }}/>
                </div>
                <div className="search-content">
                    <div>
                        <label>
                            <Input type="search" placeholder="Enter search keyword" required={true} value={query} passQuery={passQuery} keyPress={keyPress}/>
                        </label>
                        <button className="search-submit" onClick={submitQuery}><i className="fa fa-search"/></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupSearch;