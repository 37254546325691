import React from 'react';

const PrivacyPolicyContent = () => {
    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{fontFamily:"Montserrat"}}>Terms and Conditions</h3>
            <h4 style={{fontFamily:"Montserrat"}}>
                Statement of Agreement
            </h4>
            <ul>
                <li>
                    The network of websites (collectively, the “PTI websites”) operated by PT Paragon Technology and Innovation (“PTI”), consists of various websites and web pages. Use of the PTI website is a form of your consent to all matters such as the terms, conditions and notices therein.
                </li>
                <li>
                    By accessing or using this site, you agree to the terms of use, conditions, and all applicable laws. If you do not agree to these terms, do not use this site or any other PTI websites.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Applicable Law
            </h4>
            <ul>
                <li>
                    Access and use of this site are subject to these Terms and Conditions and in accordance with the laws and regulations applicable in the Republic of Indonesia. Users who choose to access this site from locations outside Indonesia and do so on their own initiative are responsible for compliance with applicable local laws. 
                </li>
                <li>
                    The Law of the Republic of Indonesia applies to these Terms and Conditions and any disputes relating to these Terms and Conditions will be submitted to the South Jakarta District Court
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Intellectual Property Rights
            </h4>
            <ul>
                <li>
                    Any copyright and other intellectual property rights in the entire content included in this site, including but not limited to text, designs, graphics, interfaces, or code and the selection and arrangement of them are the property of PTI under the rules and regulations applicable in Indonesia.  
                </li>
                <li>
                    You waive any ownership rights to all comments, suggestions, graphics, ideas (including product ideas and advertising ideas), and other information or material that you submit to PTI through this site, and you acknowledge PTI's unrestricted right to use them (either materials or ideas similar to your input) in any media, now and in the future, without notice, compensation or liability whatsoever to you or to anyone else. This also means PTI has no obligation to keep your submissions confidential. 
                </li>
                <li>
                    You are allowed to browse this site and to reproduce its contents either by printing, downloading to your hard disk and / or distributing it to other people but, as long as it is for non-commercial purposes, providing information and for personal use only. No reproduction of any part of the site may be sold or distributed for commercial gain or modified or combined with other work, publication or website, whether in hard copy or electronic format, including posts on other sites. PTI does not grant a license or right to do so.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Prohibition
            </h4>
            <ul>
                <li>
                    Harassment in any way and form on this site or any of the PTI websites is strictly prohibited, including through e-mail and chat, or by the use of inappropriate or abusive language.  
                </li>
                <li>
                    Impersonation of any person, including employees, supervisors, PTI representatives, or other members and visitors to the site is prohibited.
                </li>
                <li>
                    You may not download, distribute, or publish content through sites that are defamatory, insulting, indecent, threatening, invading privacy or publicity rights, abusive, illegal, or inappropriate, or which may constitute or encourage a form of crime, as a form of violation of the law.
                </li>
                <li>
                    You cannot download commercial content on the site or use the site to ask other people to join or become a member of commercial online services or other organizations.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                User Submissions
            </h4>
            <ul>
                <li>
                    PTI does not and cannot review all submissions posted to or created by users accessing the Site and is not in any manner responsible for the content of these submissions You acknowledge that by providing you the ability to view and distribute content created by users of this site, PTI ismerely acting as a passive conduit for such distribution and is not undertaking any obligation or liability relating to any submissions or activities on the site. However, PTI reserves the right to block or remove submissions and/or users that it determines to be (a) abusive, defamatory, or obscene, (b) fraudulent, deceptive, or misleading, (c) in violation of a copyright, trademark or; other intellectual property right of another or (d) violates any law or regulation or (e) offensive or otherwise unacceptable to PTI at its sole discretion.   
                </li>
                <li>
                    Your personal information that is posted or transmitted will be treated by PTI in accordance with the Privacy Policy of this website.
                </li>
                <li>
                    Except for any personally identifiable information we may collect from you under the guidelines established in our Privacy Policy, any submission will be considered non-confidential and non-proprietary. PTI will have no obligations with respect to the submissions.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Disclaimers
            </h4>
            <ul>
                <li>
                    All information provided on this site is general in nature and cannot guarantee anything. PTI has the right to completely change the title and / or content of the information on this website. PTI cannot be sued or sued for any statements, errors, inaccuracies, and / or deficiencies in any information submitted on this site and does not promise that the site, content, or services from the site will be free from errors or disruptions.   
                </li>
                <li>
                    PTI makes no warranties about the accuracy or completeness of the content of this site or any other external sites. The information on this site has been included for general information purposes only. It should not be used as a reference for any particular purpose and no representation or warranty is given as to its accuracy or completeness.
                </li>
                <li>
                    PTI does not warrant that the functions contained on this site will be uninterrupted or error-free, that defects will be corrected, or that this site or server can render this site free of viruses or other harmful components.
                </li>
                <li>
                    The results obtained from using the products on this site may be different for each user. All customers who purchase products through this site are automatically deemed to have read and agreed to all disclaimers and are fully responsible for any results experienced by using the product. The customer hereby frees PTI from any claims for losses incurred due to product misuse, product modification, and / or the results of using the product. 
                </li>
                <li>
                    If any part of these Terms and Conditions is declared invalid or unenforceable, then that provision must be deemed removed from these Terms and Conditions and the other parts of the Terms and Conditions will remain in full effect.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Modifications of Websites
            </h4>
            <ul>
                <li>
                    PTI reserves the right to change the Terms and Conditions and notices held by the PTI website. You are responsible for regularly reviewing these Terms and Conditions due to its binding.   
                </li>
                <li>
                    Some of the provisions of these Terms and Conditions can be replaced by express legal notices based on the terms located on certain pages on this site.
                </li>
                <li>
                    PTI reserve the right to make changes and corrections to this site, when we consider it appropriate and without notice.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                PT Paragon Technology and Innovation
            </h4>
            <p>
            Jalan Swadarma Raya Kampung Baru IV No. 1 Jakarta - 12250 Indonesia
            <br/>
            <br/>
            E-mail: customercare@paracorpgroup.com
            <br/>
            <br/>
            Phone:
            <br/>
            08041401123
            <br/>
            <br/>
            Fax: +62 21 585 2439
            </p>
        </>
    )
}

export default PrivacyPolicyContent;