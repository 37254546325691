import React from 'react';
import {useMediaQuery} from 'react-responsive'

const ScholarshipTestimonialItem = ({data, classes}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return (
        <div className={`testimonial testimonial_style--1 testimonial_scholarship ${classes && classes}`} style={isMobile ? {minHeight:"35vh", width:"100%"} : isBigScreen ? {minHeight:"30vh", width:"80%"} : {minHeight:"42vh", width:"100%"} }>
            <div className="content" style={{position:"relative", height:"100%"}}>
                <p className="bk_pra heading-font" style={{color: "#215AA8"}}>
                    {data.quote}
                </p>
                <div className="testimonial-info" style={{position:"absolute", bottom:"0"}}>
                    <div className="post-thumbnail">
                        <img src={require('../../assets/img/testimonial/scholarship/' + data.photo)} alt={data.name}/>
                    </div>
                    <div className="client-info">
                        <h6 style={{color: "#215AA8"}}>{data.name}</h6>
                        <span style={{color: "#215AA8"}}>{data.origin}</span>
                    </div>
                </div>
                {isBigScreen ?
                <div className="testimonial-quote" style={{position:"absolute", bottom:"0", right:"0"}}>
                    <span style={{color: "#215AA8"}} className="fa fa-quote-right"/>
                </div> : null}
            </div>
        </div>
    );
};

export default ScholarshipTestimonialItem;