import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const ScholarshipQualifications = ({title, qualifications}) => {
    return (
        <div className={title === 'Qualifications' ? "bk-about-area-paragon section-ptb-80" : "bk-about-area-paragon pb--80"}>
            <Container>
                <Row className="about--paragon align-items-center">

                    <Col xs={12} lg={12} className="mt_md--40 mt_sm--40">
                        <div className="content pl_md--5 pl_sm--5">
                            <div className="title--paragon wow move-up text-left">
                                <h2 style={{color:"#215AA8", fontSize:"32px", width:"100%", fontFamily:"Montserrat"}}>
                                    {title}
                                </h2>
                            </div>
                            <div className="title--paragon wow move-up text-left">
                                {qualifications ?
                                <ul>
                                    {qualifications.map(item => (
                                        <li className="mt--10">
                                            {item}
                                        </li>
                                    ))}
                                </ul> : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};

export default ScholarshipQualifications;
