import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const BreadcrumbTitleOnly = ({title, bgImage}) => {
    return (
        <div
            className={"brook-breadcrumb-area halfscreen breadcrumb-title-bar breadcrumb-title-white d-flex align-items-center pt_md--70 pt_sm--100 pb_sm--50 position-relative "+bgImage}>
            <div className="overlay" style={{backgroundColor: "#000", opacity: 0.3}}/>
            <Container fluid={true} className="plr--180 plr_lg--100 plr_md--50 plr_sm--50">
                <Row className="align-items-center">
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-center align-items-center">
                            <h1 className="heading heading-h1 font-60 text-white" style={{fontFamily: "Montserrat"}}>
                                {title}
                            </h1>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BreadcrumbTitleOnly;
