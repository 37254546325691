import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import AboutThumbParagon from "../../components/about-us/thumbnail/AboutThumbParagon";
import aboutThumb from "../../assets/img/foto_bu_nur.png"
import AboutContentOne from "../../components/about-us/content/AboutContentParagon";
import { useMediaQuery } from 'react-responsive'

const AboutParagon = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className="bk-about-area-paragon section-ptb-150" style={isMobile ? null : {backgroundColor :"#215AA8"}}>
            <Container style={isBigScreen ? {backgroundColor:"white", padding:"48px", borderRadius:"10px", maxWidth:"1591px"} :  isMobile ? {padding:"0px"} : {backgroundColor:"white", padding:"48px", borderRadius:"10px"}}>
                <Row className="about--paragon align-items-center">

                    <Col xs={12} lg={7} className="mt_md--40 mt_sm--40">
                        <AboutContentOne
                            title={'About Us'}
                            heading={"Creating Goodness for Others is Our Belief"}
                            typingHeading ={"Since 1985"}
                            content={"Feel good, be good and do good are things that are connected within ourselves."}
                            contentSecond={"I am very grateful that Paragon has accomplished this much. This is all thanks to the hard work and innovation of all Paragonians and the support of the community, especially the Indonesian people, for our products and programs. I am very grateful and hope we can do greater and go further to achieve our dreams together."}
                            contentThird={"We dream of forming an innovative ecosystem where everyone can do good to one another and turn Indonesia into a better society for the next generations. "}
                            bottomText={"Nurhayati Subakat"}
                            bottomTextName={"Founder of Paragon"}
                        />
                    </Col>
                    <Col xs={0} lg={1}>
                    </Col>
                    <Col xs={12} lg={4}>
                        <AboutThumbParagon thumb={aboutThumb}/>
                    </Col>

                </Row>
            </Container>
        </div>
    );
};

export default AboutParagon;
