import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';
import { useMediaQuery } from 'react-responsive'

export default function NewsItemBe({ data, className }) {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [showedDate,setShowedDate] = useState(data.createdAt)

  useEffect(() => {
    var date = parseInt(data.createdAt.substring(8,10))
    var month = parseInt(data.createdAt.substring(5,7))
    var year = parseInt(data.createdAt.substring(0,4))
    setShowedDate(dateFormat(new Date(year, month-1, date), "mmm dd, yyyy"))
}, [data.createdAt])

  return (
    <div className={`blog-grid blog-grid--modern blog-standard ${className}`}>
      <div className="post-thumb">
        <Link to={`${process.env.PUBLIC_URL + '/post/news/' + data.id}`}>
          <img src={data.imageThumb} alt={data.title} />
        </Link>
      </div>
      <div className="post-content text-center">
        <div className="post-inner">
          <div className="post-meta mb--10">
            <div className="post-date" style={{ fontFamily: "Montserrat" }}>{showedDate}</div>
          </div>
          <h5 className="heading heading-h5 line-height-1-39" style={isMobile ? { fontFamily: "Montserrat" } : { fontFamily: "Montserrat", minHeight: "14vh" }}>
            <Link to={`${process.env.PUBLIC_URL + '/post/news/' + data.id}`}>{data.title}</Link>
          </h5>
          <Link to={`${process.env.PUBLIC_URL + '/post/news/' + data.id}`} className="post-read-more" style={{ color: "white" }}>&nbsp;</Link>
        </div>
      </div>
    </div>
  );
}
