import React from 'react';
import { useMediaQuery } from 'react-responsive'

const ParagonTestiItem = ({data, classes}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isNormalScreen = useMediaQuery({query: '(min-width: 1368px)'})
    return (
        <div className={`testimonial testimonial_style--1 ${classes && classes}`} style={isBigScreen || isMobile ? {backgroundColor:"#000000 !important", height:"60vh"} : isNormalScreen ? {backgroundColor:"#000000 !important", height:"70vh"} : {backgroundColor:"#000000 !important", height:"90vh"}}>
            <div className="content" style={{position:"relative", height:"100%"}}>
                <p className="bk_pra heading-font" style={isMobile ? {fontSize: "calc(12px + 0.8vw)", fontFamily:"Montserrat"} : {fontSize: "calc(12px + 0.3vw)", fontFamily:"Montserrat"}}>
                    “{data.quote}”
                </p>
                <div className="testimonial-info" style={{position:"absolute", width:"100%", bottom:"0"}}>
                    <div className="post-thumbnail" style={{backgroundColor:"white", borderRadius:"50%"}}>
                        <img src={require('../../assets/img/testimonial/csr/' + data.client.photo)} style={{width:"64px"}} alt={data.client.name}/>
                    </div>
                    <div className="client-info">
                        <h6>{data.client.name}</h6>
                        <span>{data.client.designation}</span>
                    </div>
                </div>
                {isNormalScreen ? 
                <div className="testimonial-quote" style={{position:"absolute", width:"100%", bottom:"3vh"}}>
                    <span className="fa fa-quote-right"/>
                </div> : null }
            </div>
        </div>
    );
};

export default ParagonTestiItem;