import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';

const CareerAction = ({bgColor, textColor, bgImage, text, btnText, type, listMedia, isLarge, link, subText}) => {

    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    return (
        <div style={{position: "relative"}}>
            <div className="brook-call-to-action ptb--100"
                style={{backgroundColor: bgColor, backgroundImage: `url(${bgImage})`}}
            >
                <Container style={isLarge ?  isBigScreen ? {maxWidth:"1591px"} : null : null}>
                    <Row className="align-items-center">
                        <Col xs={12} lg={7} className={'wow move-up pt--30'} style={{zIndex:"10"}}>
                            <div className="call-content text-center text-md-left">
                                {subText ? <p style={{color: textColor, fontFamily:"Montserrat", marginBottom:"0px"}}><strong>{subText}</strong></p> : null}
                                <h3 className="heading heading-h3" style={{color: textColor, fontFamily:"Montserrat"}}>{text}</h3>
                            </div>
                        </Col>
                        <Col xs={0} lg={2}>

                        </Col>
                        {type === "media" ? 
                            <Col xs={12} lg={3} className={'wow move-up pt--30'} style={{zIndex:"10"}}>
                                <Row>
                                    {listMedia.map((media, index) => (
                                        <Col xs={listMedia.length === 1 ? {span:4,offset:4} : 4} lg={index === 0 ? {span: 3, offset: 3} : {span: 3}}>
                                            <a target="_blank" rel="noreferrer noopener" href={media.link}><img src={media.media} alt={media.name}/></a>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        :
                        <Col xs={12} lg={3} className={'wow move-up'} style={{zIndex:"10"}}>
                            <div className="call-btn text-center text-md-right mt-4 mt-md-0">
                                <a target="_blank" rel="noreferrer noopener" className={`brook-btn bk-btn-white text-theme btn-sd-size btn-rounded`}
                                href={link} style={{color:"#215AA8"}}>
                                    {btnText}
                                </a>
                            </div>
                        </Col>}
                    </Row>
                </Container>
            </div>
            {bgImage ? <div className="career-overlay" style={{position:"absolute", top:"0"}}/> : null}
        </div>
    );
};

export default CareerAction;