import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import CSRPilarItem from './CSRPilarItem';

const CSRPilarList = () => {
    const [csrList] = React.useState([
        {
            link:"#education",
            image:"csr_education.png",
            altText:"pendidikan",
            title:"EDUCATION"
        },
        {
            link:"#health",
            image:"csr_health.png",
            altText:"kesehatan",
            title:"HEALTH"
        },
        {
            link:"#women-empowerment",
            image:"csr_woman.png",
            altText:"pemberdayaan_perempuan",
            title:"WOMEN EMPOWERMENT",
        },
        {
            link:"#environment",
            image:"csr_environment.png",
            altText:"lingkungan",
            title:"ENVIRONMENT"
        }
    ])
    return (
        <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
            <Container>
                <Row>
                    {csrList.map(item => (
                        <Col lg={3} xs={6} className={'mt_md--40 mt_sm--40'} style={{display: "flex", justifyContent: "center", minHeight:"30vh"}}>
                            <CSRPilarItem
                                link={item.link}
                                image={item.image}
                                altText={item.altText}
                                title={item.title}
                            />              
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default CSRPilarList;