import React from 'react';
import TypedText from "../../components/typed-text";
import { useMediaQuery } from 'react-responsive'
import {Container, Row, Col} from "react-bootstrap";

const AboutInnovation = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    return (
        <div className="bk-about-area-paragon section-ptb-150">
            <Container>
                <Row className="about--paragon align-items-center">

                    <Col xs={12} lg={12} className="mt_md--40 mt_sm--40">
                        <div className="content pl_md--5 pl_sm--5" style={isBigScreen ? {paddingLeft: "80px"} : null}>
                            <div className="title--paragon wow move-up">
                                <h2 style={{color:"#215AA8", fontSize:"32px"}}>
                                    What is Innovation?
                                    <br/>
                                    Innovation is our
                                    <span className="cd-words-wrapper theme-color" style={{color: "#6B89FF"}}>  <TypedText
                                        content={['Last Name']}
                                        typeSpeed={100}
                                        backSpeed={100}
                                        loop={true}
                                    />
                                    </span>
                                </h2>
                            </div>
                            <h3 className="wow move-up" style={isMobile ? {color:"#215AA8", fontWeight:"bold", fontSize:"28px", width:"100%", fontFamily:"Montserrat"} : {color:"#215AA8", fontWeight:"bold", fontSize:"28px", width:"70%", fontFamily:"Montserrat"}}>
                                We value every initiative and process that aims to provide added value and greater impact  
                            </h3>
                            <p className="wow move-up mt--50" style={{color:"#215AA8", fontFamily:"Montserrat"}}>
                                Some of us may think that innovation should be something that breaks through and changes the world instantly, and this mindset makes us reluctant to initiate or feel unable to innovate.
                                <br/> <br/>
                                Does innovation have to be like that?
                            </p>
                            <p className="wow move-up" style={{color:"#215AA8", fontFamily:"Montserrat"}}>
                                For Paragon, every small action that aims to make a change for the better is an innovation, because every small step will make a big change if it is done consistently.
                                <br/> <br/>
                                Innovation starts from a mindset that is translated to action and will provide a greater good for our surrounding.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
};

export default AboutInnovation;
