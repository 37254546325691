import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

const BreadcrumbAboutUs = ({title,desc,image}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div
            className={"brook-breadcrumb-area bg-image-"+image+" fullscreen breadcrumb-title-bar breadcrumb-title-white d-flex align-items-center pt_md--70 pt_sm--100 pb_sm--50 position-relative"}>
            <div className="overlay" style={{backgroundColor: "#000", opacity: 0.3}}/>
            <Container fluid={true} className="plr--180 plr_lg--100 plr_md--50 plr_sm--50">
                <Row className="align-items-center">
                    <Col lg={12}>
                        <div className="breadcrumb-inner text-center align-items-center">
                            <h1 className="heading heading-h1 font-60 text-white">
                                {title}
                            </h1>
                            <div style={{width:"100%", display: "flex", justifyContent:"center"}}>
                                <p style={isMobile ? {color:"white", fontSize:"18px", lineHeight:"25px", width:"100%", fontFamily:"Montserrat"} : {color:"white", fontSize:"28px", lineHeight:"37px", width:"70%", fontFamily:"Montserrat"}} className="mt--50"> 
                                    {desc}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BreadcrumbAboutUs;
