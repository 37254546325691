import React from 'react';
import PropTypes from 'prop-types';

const PortfolioItemSix = ({title,thumb,category, className, link}) => {
    return (
        <a href={`${process.env.PUBLIC_URL + '/post/article/'+link}`}>
            <div className={`portfolio-cation mt--30 ${className ? className : ''}`}>
                <div className="poss_relative full-height">
                    <div className="thumb full-height">
                        <img src={thumb} alt={title} style={{ objectFit: 'cover' }} className='full-height' />
                    </div>
                    <div className="port-overlay-info">
                        <div className="hover-action">
                            <h3 className="post-overlay-title">
                                <a href={`${process.env.PUBLIC_URL + '/post/article/'+link}`}>{title}</a>
                            </h3>
                            <div className="category">{category}</div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
};

PortfolioItemSix.propTypes = {
    className: PropTypes.string
}

export default PortfolioItemSix;
