import React, { Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon";
import { Container, Row, Col } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import PrivacyPolicyContentEnglish from './PrivacyPolicyContentEnglish';
import PrivacyPolicyContentID from './PrivacyPolicyContentID';

const LegalInformationPage = () => {
    const [selected, setSelected] = useState("GB");
    const [title, setTitle] = useState("PRIVACY POLICY");
    return (
        <Fragment>
            <Helmet>
                <title>Privacy Policy - Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2 bg_color--1" revealFooter={false} >
                <Header />
                <Breadcrumb
                    title={title}
                    bgImage={"bg-image-brand"}
                />
                <div className="tabs-section section pt--80 pt-md--80 pt-sm--60 bg_color--1">
                    <Container>
                        <Row>
                            <Col lg={9}>
                            </Col>
                            <Col lg={3}>
                                <ReactFlagsSelect
                                    selected={selected}
                                    onSelect={
                                        (code) => {
                                            if (code === 'ID') {
                                                setSelected(code)
                                                setTitle('KEBIJAKAN PRIVASI')
                                            } else {
                                                setSelected(code)
                                                setTitle('PRIVACY POLICY')
                                            }
                                        }
                                    }
                                    countries={["ID", "GB"]}
                                    customLabels={{ "GB": "English", "ID": "Bahasa Indonesia" }}
                                />
                            </Col>
                        </Row>
                        <Row className="mb--50">
                            <Col xs={12}>
                                {selected === 'ID' ? <PrivacyPolicyContentID /> : <PrivacyPolicyContentEnglish />}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <FooterParagon />
            </LayoutDefault>
        </Fragment>
    );
};

export default LegalInformationPage;