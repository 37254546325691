import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import PortfolioItem from "../../../components/portfolio/PortfolioItemSix";
import { useMediaQuery } from 'react-responsive'
import BeatLoader from "react-spinners/BeatLoader";
import { useGetDataNewsArticle } from '../../../hooks/api/useGetDataNewsArticle'

function LatestArticle() {

    const { data, isLoading, isFetching, error } = useGetDataNewsArticle('article')

    if (isLoading || isFetching) {
        return (
            <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
                <div style={{ marginTop: "30vh" }} />
                <BeatLoader color={"#215AA8"} loading={true} size={72} />
            </div>
        )
    }
    if (error) return (
        <div style={{ minHeight: "70vh", width: "100%", textAlign: "center" }}>
            <div style={{ marginTop: "30vh" }} />
            Unable to fetch data
        </div>
    )
    return (
        data.data.listNews.slice(0, 4).map((portfolio, index) => {
            return (
                <PortfolioItem
                    key={portfolio.id}
                    title={portfolio.title}
                    link={portfolio.id}
                    thumb={portfolio.imageThumb}
                    className={`col-md-${(index === 0 || index === 3) ? 6 : 3} ${index === 0 && 'full-height'}`}
                />
            )
        })
    )
}

const ParagonPortofolio = () => {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' })
    useEffect(() => {
        const grid = document.querySelector('.masonry-list');
        imagesLoaded(grid, () => {
            new Masonry(grid, {
                itemSelector: '.portfolio-cation',
                columnWidth: '.masonry-sizer'
            });
        });
    })

    return (
        <div className="brook-portfolio-area">
            <Container fluid={true} className="plr--100 plr_sm--30 plr_md--50" style={isBigScreen ? { maxWidth: "1591px" } : null}>
                <Row>
                    <Col xs={12}>
                        <div className="section-title mb--50">
                            <h3 style={{ color: "#215AA8", fontFamily: "Montserrat" }}>Innovation</h3>
                            <h2 style={{ color: "#215AA8", fontFamily: "Montserrat" }} >Innovation as Our DNA</h2>
                        </div>
                    </Col>
                </Row>

                <Row className="masonry-list mt--n30">
                    <div className="col-1 masonry-sizer" />
                    <LatestArticle />
                </Row>
            </Container>
        </div>
    );
};

export default ParagonPortofolio;
