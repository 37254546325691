import { useQuery } from '@tanstack/react-query';
import authAxios from '../../utils/interceptor'

export const useGetDataByID = (newsID) => {
  return useQuery(
    ['dataID'],
    async () => {
      const res = await authAxios({
        method: 'GET',
        url: `/corporate-news/${newsID}`
      });
      return res.data;
    },
    {
      enabled: Boolean(newsID),
    }
  );
};