import React, { useState, useEffect } from 'react';

const Input = ({type, name, id, placeholder, required, value, className, passQuery, keyPress}) => {
    const [query, setQuery] = useState(value)

    useEffect(() => {
        passQuery(query)
    }, [query,passQuery])
    return (
        type === 'textarea' ? (
            <textarea id={id} name={name} placeholder={placeholder} required={required} className={className}/>
        ) : (
            <input
                type={type}
                id={id} name={name}
                placeholder={placeholder}
                value={value && value}
                required={required}
                className={className}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => keyPress(e)}
            />
        )
    );
};

export default Input;